/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MoodRendererComponent, MoodRendererUnitType, MoodRenderFrame } from 'engage-common';
import { ChlFooterService } from '../services/chl-footer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EngageSerializableStoreService } from 'engage-utils';
import { ChlPatientService } from '../services/chl-patient.service';
import { TranslationService } from '../services/translation.service';
import { MessageService } from 'primeng/api';
import { MoodCaptchaComponent } from '../../../../engage-common/src/lib/mood/mood-captcha/mood-captcha.component';

@Component({
  selector: 'app-signup-confirm',
  templateUrl: './sign-up-confirm.component.html',
  styleUrls: ['./sign-up-confirm.component.scss'],
})
export class SignUpConfirmComponent implements OnInit, OnDestroy {
  VERIFICATION_KEY = 'verification';

  identifier: string;
  loading: boolean = false;

  initialButtons = [
    {
      text: () => this._translationService.translate(['buttons', 'back']),
      click: () => this._router.navigate(['sign-up-contact']),
      buttonClasses: ['mood-default'],
    },
    {
      text: () => this._translationService.translate(['buttons', 'submit']),
      click: () => this._submit(),
      buttonClasses: ['mood-primary'],
    },
  ];

  constructor(
    private _translationService: TranslationService,
    private _footerService: ChlFooterService,
    private _router: Router,
    private _store: EngageSerializableStoreService,
    private _patientService: ChlPatientService,
    private _primeNgMessageService: MessageService,
    private _activatedRoute: ActivatedRoute
  ) {}

  @ViewChild(MoodRendererComponent) renderer: MoodRendererComponent;
  @ViewChild(MoodCaptchaComponent) captcha: MoodCaptchaComponent;

  ngOnInit(): void {
    this._activatedRoute.queryParams.subscribe((params) => {
      this.identifier = params.i;

      // Automatically enter code from request params if given
      if (params.code) {
        this._store.store(this.VERIFICATION_KEY, { code: params.code });
        this._submit();
      }
    });

    this._footerService.visible.next(true);
    this._showButtons(true);
  }

  ngOnDestroy(): void {
    this._footerService.visible.next(false);
  }

  onUpdate(updateValues): void {
    this._showButtons(!this.renderer.form.valid);

    this._store.store(this.VERIFICATION_KEY, updateValues);
  }

  private _showButtons(submitDisabled = false): void {
    this._footerService.buttons.next([
      {
        buttons: [this.initialButtons[0], { ...this.initialButtons[1], disabled: submitDisabled }],
      },
    ]);
  }

  private _submit(): void {
    this.loading = true;
    const { code } = this._store.retrieve(this.VERIFICATION_KEY);
    this._showButtons(true);
    const success = (res) => {
      this._store.clear().store('t', res.access_token).store('patient', res.patient);
      this._primeNgMessageService.add({
        severity: 'success',
        summary: '',
        detail: this._translationService.translate(['pages', 'sign-up', 'successValidated']),
      });
      this._router.navigate(['test-kits']);
      this.loading = false;
    };

    const error = (e) => {
      const errorMessages = [];

      if (e.error?.errors?.email || e.error?.errors?.phone) {
        errorMessages.push(
          this._translationService.translate(['pages', 'sign-up', 'alreadyValidated'])
        );
      }

      if (errorMessages.length === 0) {
        errorMessages.push(
          this._translationService.translate(['pages', 'sign-up', 'genericValidationError'])
        );
      }

      errorMessages.forEach((message) => {
        this._primeNgMessageService.add({
          severity: 'error',
          summary: '',
          detail: message,
        });
      });
      this.loading = false;
    };

    this._patientService.verify(code, this.identifier).subscribe(success, error);
  }

  rendererFrame: MoodRenderFrame = [
    {
      type: MoodRendererUnitType.HEADER,
      label: () => this._translationService.translate(['pages', 'sign-up-confirm', 'header']),
      margin: ['0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () =>
        this._translationService.translate(['pages', 'sign-up-confirm', 'confirmationParagraph']),
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.INPUT,
      label: () =>
        this._translationService.translate(['pages', 'sign-up-confirm', 'accessCode', 'label']),
      formData: {
        placeholder: () =>
          this._translationService.translate([
            'pages',
            'sign-up-confirm',
            'accessCode',
            'placeholder',
          ]),
        controlName: 'code',
        valueType: 'text',
        validators: {
          required: true,
        },
      },
    },
  ];
}
