<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="temp-info-header" *ngIf="test">
  <strong
    >This is a test website. All content is subject to change. <strong>Do not</strong> enter any personal
    information.</strong
  >
</div>

<div [ngStyle]="appStyle">
  <mood-screen>
    <mood-screen-header>
      <div class="mhl-header">
        <img engage-asset [src]="headerLogoFilename" class="logo" />
        <div *ngIf="!hideLangChange" class="lang-buttons">
          <span
            tabindex="1"
            (keydown.enter)="$event.target.click()"
            [ngClass]="{ selected: currentLanguage === 'en' }"
            class="lang"
            (click)="loadLanguage('en')"
            >EN</span
          >
          |
          <span
            tabindex="1"
            (keydown.enter)="$event.target.click()"
            [ngClass]="{ selected: currentLanguage === 'fr' }"
            class="lang"
            (click)="loadLanguage('fr')"
            >FR</span
          >
        </div>
      </div>
    </mood-screen-header>
    <mood-screen-content>
      <div class="screen-content-header">
        <app-nav></app-nav>
      </div>
      <div class="screen-content-body">
        <router-outlet></router-outlet>
      </div>
      <div class="screen-content-footer" *ngIf="showContentFooter">
        <app-chl-footer></app-chl-footer>
      </div>
    </mood-screen-content>
    <mood-screen-footer layoutDirection="col" *ngIf="!hidePageFooter">
      <div class="footer-links">
        <a class="privacy-policy" *ngIf="!hideFooterText" [href]="privacyPolicyUrl">{{ privacyPolicyText }}</a>
        <span class="branding"
          ><span class="powered">{{ translationService.translate(['footer', 'powered']) + ' ' }}</span>
          <a href="https://verto.health" target="_blank">Verto Health</a></span
        >
        <a class="faq" [href]="footerUrl" *ngIf="!hideFooterText">
          {{ footerText }}
        </a>
      </div>
      <div class="verto-header">
        <img id="verto-logo" engage-asset [src]="footerLogo" />
        <span class="copyright">{{ translationService.translate(['footer', 'date']) }}</span>
      </div>
    </mood-screen-footer>
  </mood-screen>
  <generic-p-toast [position]="'bottom-center'"></generic-p-toast>
</div>
<div class="print-content" [innerHTML]="printContent | safeHtml"></div>
