/* Copyright 2023 (Unpublished) Verto Inc. */

/**
 * Type of cell to be rendered
 */
export enum MoodTableCellType {
  SINGLE_TEXT = 'single-text',
  MULTI_TEXT = 'multi-text',
  BUTTON = 'button',
}
