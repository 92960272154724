/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MoodRendererUnitType, MoodState } from 'engage-common';
import { ChlFooterService } from '../services/chl-footer.service';
import { TranslationService } from '../services/translation.service';

@Component({
  selector: 'app-enroll-in-research-study',
  templateUrl: './brown-labs.component.html',
})
export class EnrollInResearchStudyComponent implements OnInit {
  state: MoodState;

  constructor(
    private _translationService: TranslationService,
    private _footerService: ChlFooterService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.state = this._getState();
    this._footerService.visible.next(true);
    this._renderbuttons();
  }

  private _renderbuttons(): void {
    this._footerService.buttons.next([
      {
        buttons: [
          {
            text: () => this._translationService.translate(['buttons', 'back']),
            click: () => this._router.navigate(['registration-info']),
            buttonClasses: ['mood-transparent-bkg', 'blue-border'],
          },
        ],
      },
    ]);
  }

  private _getState(): MoodState {
    const translate = (...path: string[]) => {
      return this._translationService.translate([
        'customization',
        'brown-labs',
        'pages',
        'enrollment',
        ...path,
      ]);
    };

    return {
      rendererFrame: [
        {
          type: MoodRendererUnitType.HEADER,
          label: translate('header'),
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: translate('content'),
          margin: ['0', '0', '20px', '0'],
        },
      ],
    };
  }
}
