<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="dropdown-container">
  <div *ngIf="label" class="dropdown-label">
    <span class="label">{{ label }}</span>
    <span *ngIf="required" class="required">*</span>
  </div>
  <select [(ngModel)]="value" [id]="inputId" class="dropdown" [disabled]="disable">
    <option value="" disabled selected hidden>{{ placeholder }}</option>
    <option *ngFor="let option of options" [value]="option.value">
      {{ option.label }}
      <div *ngIf="option.subtext">
        <strong>{{ option.subtext }}</strong>
      </div>
    </option>
  </select>
</div>
