/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MoodRendererUnitType, MoodState } from 'engage-common';
import { ChlFooterService } from '../../services/chl-footer.service';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-ad-hoc-covid-test',
  styleUrls: ['./ad-hoc-covid-test.component.scss'],
  templateUrl: '../brown-labs.component.html',
})
export class AdHocCovidTestComponent implements OnInit {
  state: MoodState;
  registeredWithVerto: Boolean;

  constructor(
    private _translationService: TranslationService,
    private _footerService: ChlFooterService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.state = this._getState();
    this._footerService.visible.next(true);
    this._renderbuttons();
  }

  private _renderbuttons(): void {
    this._footerService.buttons.next([
      {
        buttons: [
          {
            text: () => this._translationService.translate(['buttons', 'back']),
            click: () => this._router.navigate(['registration-info']),
            buttonClasses: ['mood-default'],
          },
          {
            text: () => this._translationService.translate(['buttons', 'next']),
            click: () => {
              if (this.registeredWithVerto) {
                this._router.navigate(['sign-in']);
                return;
              }
              this._router.navigate(['create-an-account']);
            },
            buttonClasses: ['mood-primary'],
          },
        ],
      },
    ]);
  }

  private _getState(): MoodState {
    const translate = (...path: string[]) => {
      return this._translationService.translate([
        'customization',
        'brown-labs',
        'pages',
        'ad-hoc-covid-test',
        ...path,
      ]);
    };

    return {
      rendererFrame: [
        {
          type: MoodRendererUnitType.HEADER,
          label: translate('header'),
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: translate('subheader'),
          margin: ['0', '0', '20px', '0'],
        },
        {
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['ad-hoc-options'],
          formData: {
            controlName: 'registered',
            controlType: 'checkbox',
            options: [
              {
                label: this._translationService.translate(['options', 'yes']),
                value: '0',
              },
              {
                label: this._translationService.translate(['options', 'no']),
                value: '1',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
          change: ({ radioButton }) => {
            this.registeredWithVerto = radioButton.value === '0';
          },
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          header: translate('accordion', 'header'),
          expanded: true,
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: translate('accordion', 'paragraphs')[0],
              margin: ['0', '0', '20px', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: translate('accordion', 'paragraphs')[1],
              margin: ['0', '0', '20px', '0'],
            },
          ],
        },
      ],
    };
  }
}
