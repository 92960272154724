<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<p-toast [position]="position || 'bottom-left'">
  <ng-template let-message pTemplate="message">
    <svg
      *ngIf="message.severity === 'error'"
      class="p-toast-message-icon"
      width="35px"
      height="35px"
      viewBox="0 0 48 46"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group-18-Copy-12" transform="translate(-24.000000, -22.000000)" fill="#AD162A" fill-rule="nonzero">
        <g id="warning-(8)" transform="translate(24.000000, 22.000000)">
          <path
            d="M47.0258823,35.2488684 L30.0509114,3.4998944 C28.7697214,1.30867439 26.5069669,0 23.998212,0 C21.489457,0 19.2267026,1.30867439 17.9455125,3.4998944 C17.9358563,3.51665763 17.9277,3.53342085 17.9180437,3.55018407 L0.999510426,35.198674 C-0.309148401,37.4360881 -0.333898449,40.1230614 0.932104044,42.3856204 C2.20101279,44.6495128 4.48701729,46 7.05080359,46 L40.8518702,46 C43.4156565,46 45.7955986,44.6495128 47.0644136,42.3856204 C48.3304161,40.1231567 48.3057598,37.4359929 47.0258823,35.2488684 Z M22,14.8333176 C22,13.2684356 23.3431568,12 25,12 C26.6569431,12 28,13.26853 28,14.8333176 L28,26.1666824 C28,27.7313757 26.6568432,29 25,29 C23.3431568,29 22,27.7312813 22,26.1666824 L22,14.8333176 L22,14.8333176 Z M25.0000444,40 C22.7943635,40 21,38.2057253 21,35.9999556 C21,33.7942747 22.7942747,32 25.0000444,32 C27.2057253,32 29,33.7942747 29,35.9999556 C29.0000888,38.2056365 27.2058141,40 25.0000444,40 Z"
            id="Shape-Copy-2"
          ></path>
        </g>
      </g>
    </svg>

    <svg
      *ngIf="message.severity === 'success'"
      class="p-toast-message-icon"
      width="35px"
      height="35px"
      viewBox="0 0 46 46"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="Group-18-Copy-11" transform="translate(-24.000000, -22.000000)" fill="#016B36" fill-rule="nonzero">
        <path
          d="M47,22 C34.3182957,22 24,32.3182957 24,45 C24,57.6817043 34.3182957,68 47,68 C59.6817043,68 70,57.6817043 70,45 C70,32.3182957 59.6817043,22 47,22 Z M60.4219489,38.9163887 L45.5287132,53.5981179 C44.6526405,54.4685762 43.2509242,54.5266067 42.3164467,53.6561485 L34.4317926,46.5183908 C33.497315,45.6479325 33.4389102,44.1971688 34.256578,43.26868 C35.1326507,42.3401912 36.5927718,42.2821606 37.5272494,43.1526189 L43.7765679,48.8396128 L57.0928727,35.6086473 C58.0273502,34.6801585 59.4874713,34.6801585 60.4219489,35.6086473 C61.3564264,36.5371361 61.3564264,37.9878999 60.4219489,38.9163887 Z"
          id="Shape-Copy"
        ></path>
      </g>
    </svg>

    <svg
      *ngIf="message.severity === 'warn'"
      class="p-toast-message-icon"
      width="35px"
      height="35px"
      viewBox="0 0 46 46"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g id="Group-18-Copy-10" transform="translate(-24.000000, -22.000000)" fill="#D18509" fill-rule="nonzero">
        <path
          d="M47,22 C34.3182957,22 24,32.3182957 24,45 C24,57.6817043 34.3182957,68 47,68 C59.6817043,68 70,57.6817043 70,45 C70,32.3182957 59.6817043,22 47,22 Z M60.4219489,38.9163887 L45.5287132,53.5981179 C44.6526405,54.4685762 43.2509242,54.5266067 42.3164467,53.6561485 L34.4317926,46.5183908 C33.497315,45.6479325 33.4389102,44.1971688 34.256578,43.26868 C35.1326507,42.3401912 36.5927718,42.2821606 37.5272494,43.1526189 L43.7765679,48.8396128 L57.0928727,35.6086473 C58.0273502,34.6801585 59.4874713,34.6801585 60.4219489,35.6086473 C61.3564264,36.5371361 61.3564264,37.9878999 60.4219489,38.9163887 Z"
          id="Shape-Copy"
        ></path>
      </g>
    </svg>
    <div class="p-toast-message-text">
      <div class="p-toast-summary">{{ message.summary }}</div>
      <div class="p-toast-detail">{{ message.detail }}</div>
    </div>
  </ng-template>
</p-toast>
