<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div *ngIf="data && data.length > 0; else emptyTable">
  <div class="mood-table-header" *ngIf="tableSettings.columnHeadersShow">
    <div
      class="mood-table-header-item"
      *ngFor="let field of tableSettings.fields"
      [ngClass]="field.headerClasses"
      [ngStyle]="field.itemStyle"
    >
      {{ field.label }}
    </div>
  </div>
  <div class="mood-table-row" *ngFor="let dataItem of data">
    <div class="mood-table-row-item" *ngFor="let field of tableSettings.fields">
      <div
        *ngIf="field.type === cellTypes.SINGLE_TEXT"
        class="mood-table-row-item"
        [ngStyle]="field.itemStyle"
        [ngClass]="field.itemClasses"
      >
        {{ dataItem[field.name] }}
      </div>

      <div
        *ngIf="field.type === cellTypes.MULTI_TEXT"
        [ngStyle]="field.itemStyle"
        class="mood-table-row-item multi-text"
        [ngClass]="field.itemClasses"
      >
        <div class="text-normal">{{ dataItem[field.names[0]] }}</div>
        <div class="text-small">{{ dataItem[field.names[1]] }}</div>
      </div>
      <div
        *ngIf="field.type === cellTypes.BUTTON"
        class="mood-table-row-item"
        [ngStyle]="field.itemStyle"
        [ngClass]="field.itemClasses"
      >
        <mood-button
          [buttonClasses]="
            field.moodButton.buttonClasses ? field.moodButton.buttonClasses.concat(dataItem.button.className) : []
          "
          [disabled]="field.moodButton.disabled"
          (onClick)="dataItem.button.click(dataItem[field.name])"
        >
          <img
            *ngIf="dataItem.button.icon"
            engage-asset
            [ngStyle]="field.moodButton.icon.style"
            src="{{ '/assets/icons/' + dataItem.button.icon + '.svg' }}"
          />
          <span [ngStyle]="field.moodButton.text.style">{{ dataItem.button.text }}</span>
        </mood-button>
      </div>
    </div>
  </div>
</div>

<ng-template #emptyTable>
  <div class="mood-table-empty">
    <p>{{ tableSettings.messages.empty }}</p>
  </div>
</ng-template>
