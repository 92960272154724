/* Copyright 2023 (Unpublished) Verto Inc. */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChlHeaderService {
  visible = new BehaviorSubject<boolean>(false);
}
