/* Copyright 2023 (Unpublished) Verto Inc. */

import { TokenGuard } from '../services/token.guard';
import { AddNewKitComponent } from '../test-kit/add-new-kit/add-new-kit.component';
import { TestKitDetailsComponent } from '../test-kit/test-kit-details/test-kit-details.component';
import { TestKitListComponent } from '../test-kit/test-kit-list/test-kit-list.component';

export const testKitRoutes = [
  {
    path: 'test-kits',
    component: TestKitListComponent,
    canActivate: [TokenGuard],
  },
  {
    path: 'test-kit-details/:id',
    component: TestKitDetailsComponent,
    canActivate: [TokenGuard],
  },
  {
    path: 'add-new-kit',
    component: AddNewKitComponent,
    canActivate: [TokenGuard],
  },
];
