<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="questionnaires-container">
  <div class="questionnaires-header">
    <div class="header">
      <mood-header label="{{ this.header }}"></mood-header>
    </div>
  </div>
  <div class="questionnaires-table">
    <mood-table
      *ngIf="!loading; else spinner"
      [tableSettings]="questionnaireListSettings"
      [data]="questionnaireData"
    ></mood-table>
    <ng-template #spinner>
      <mood-spinner></mood-spinner>
    </ng-template>
  </div>
</div>
