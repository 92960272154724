<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="study-links-container">
  <div class="row mt-4">
    <div class="col">
      <mood-renderer [moodFrame]="rendererFrame"></mood-renderer>
      <a
        *ngFor="let studyLink of this.translationService.translate(['pages', 'studyLinks'])"
        #studyLink
        class="hidden"
        [href]="studyLink.value"
        download
      ></a>
    </div>
  </div>
</div>
