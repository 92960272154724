/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { TranslationService } from '../services/translation.service';
import { environment } from '../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { QuestionnaireConfig } from '../services/configuration/questionnaire-config';
import { ExtraFieldConfig } from '../services/configuration/field-config';
import { ChlFooterService } from '../services/chl-footer.service';
import { MoodRendererUnit } from 'engage-common';
import { MessageService } from 'primeng/api';
import { ChlPatientService } from '../services/chl-patient.service';
import { MoodFactoryService } from '../services/mood-factory.service';
import { ChlHeaderService } from '../services/chl-header.service';

@Component({
  selector: 'answer-questionnaire',
  templateUrl: './answer-questionnaire.component.html',
  styleUrls: ['./answer-questionnaire.component.scss'],
})
export class AnswerQuestionnaireComponent implements OnInit {
  questionnaire: QuestionnaireConfig;
  attach_to: string = 'Patient';
  questionnaireTitle: string = '';
  questionnaireKey: string = '';
  page: number = 0;
  submitted: boolean = false;
  pageFrames: MoodRendererUnit[][] = [];
  language: string = 'en';
  allowedLanguages: string[] = ['en', 'fr'];

  formValues: {} = {};

  constructor(
    private configurationService: ConfigurationService,
    private translationService: TranslationService,
    private router: Router,
    private route: ActivatedRoute,
    private _footerService: ChlFooterService,
    private _moodFactoryService: MoodFactoryService,
    private _primeNgMessageService: MessageService,
    private _patientService: ChlPatientService,
    private _headerService: ChlHeaderService
  ) {}

  ngOnInit(): void {
    this._headerService.visible.next(false);
    this._footerService.visible.next(true);
    this._footerService.chlBrandingMode.next(false);

    this.configurationService.getConfig().subscribe((app) => {
      if (app.config.content.questionnaire_attach_to) {
        this.attach_to = app.config.content.questionnaire_attach_to;
      }
    });

    this.route.queryParams.subscribe((params) => {
      this.questionnaireKey = params.q;

      if (params.lang && this.allowedLanguages.includes(params.lang)) {
        this.language = params.lang;
      }
      if (!this.questionnaireKey) {
        this._primeNgMessageService.add({
          severity: 'error',
          summary: '',
          detail: this.translationService.translate(['formMessages', 'submissionFailed']),
        });
        setTimeout(() => this.router.navigate(['questionnaires']), 1000);
        return;
      }
      this.retrieveQuestionnaireTemplate(this.questionnaireKey, this.language);

      this._footerService.buttons.next([
        {
          buttons: [
            {
              text: () => this.translationService.translate(['buttons', 'back']),
              click: () => this.router.navigate(['questionnaires']),
              buttonClasses: ['mood-default'],
            },
            {
              text: () =>
                this.translationService.translate([
                  'buttons',
                  this.page === this.pageFrames.length ? 'submit' : 'next',
                ]),
              click: () =>
                this.page === this.pageFrames.length ? this._submit() : this._nextPage(),
              buttonClasses: ['mood-primary'],
              disabled: () => this.submitted,
            },
          ],
        },
      ]);
    });
  }

  _nextPage(): void {
    let filled = this._pageFilled();

    if (filled) {
      this.page++;
      this._footerService.buttons.next([
        {
          buttons: [
            {
              text: () => this.translationService.translate(['buttons', 'back']),
              click: () =>
                this.page === 0 ? this.router.navigate(['questionnaires']) : this.page--,
              buttonClasses: ['mood-default'],
            },
            {
              text: () =>
                this.translationService.translate([
                  'buttons',
                  this.page + 1 === this.pageFrames.length ? 'submit' : 'next',
                ]),
              click: () =>
                this.page + 1 === this.pageFrames.length ? this._submit() : this._nextPage(),
              buttonClasses: ['mood-primary'],
            },
          ],
        },
      ]);
    } else {
      this._primeNgMessageService.add({
        severity: 'error',
        summary: '',
        detail: this.translationService.translate(['formMessages', 'missingRequired']),
      });
    }
  }

  _pageFilled(): boolean {
    let filled: boolean = true;
    this.questionnaire.fields
      .filter((q) => {
        for (let question of this.pageFrames[this.page]) {
          if (question['formData'] && question['formData']['controlName'] === q.key) return true;
        }
        return false;
      })
      .forEach((field) => {
        if (
          field.required &&
          ((field.reveal && this.formValues[field.reveal.key] === field.reveal.value) ||
            !field.reveal)
        ) {
          filled = filled && !!this.formValues[field.key];
        }
      });
    return filled;
  }

  ngOnDestroy(): void {
    this._headerService.visible.next(true);
    this._footerService.visible.next(false);
  }

  onUpdate(updatedValues: {}): void {
    this.formValues = { ...this.formValues, ...updatedValues };
  }

  _submit(): void {
    if (!this.submitted) {
      this.submitted = true;
      let filled = this._pageFilled();

      if (filled) {
        this.formValues['questionnaireKey'] = this.questionnaireKey;
        this._patientService
          .submitQuestionnaire({
            responses: this.formValues,
            type: this.questionnaire.type,
            key: this.questionnaireKey,
            attach_to: this.attach_to,
          })
          .subscribe(
            () => this.router.navigate(['questionnaires']),
            (err) => {
              console.log(err);
              this.submitted = false;
              this._primeNgMessageService.add({
                severity: 'error',
                summary: '',
                detail: this.translationService.translate(['formMessages', 'submissionFailed']),
              });
            }
          );
      } else {
        this._primeNgMessageService.add({
          severity: 'error',
          summary: '',
          detail: this.translationService.translate(['formMessages', 'missingRequired']),
        });
      }
    }
  }

  retrieveQuestionnaireTemplate(questionnaireKey: string, lang: string): void {
    this.configurationService.getConfig().subscribe((app) => {
      const questionnaireConfig = app.config.content.questionnaire_config;

      this.questionnaire =
        questionnaireConfig?.[lang]?.[questionnaireKey] || questionnaireConfig[questionnaireKey];
      this.questionnaireTitle = this.translationService.translate([
        'customization',
        environment.client,
        'questionnaires',
        questionnaireKey,
      ]);

      let extraFieldConfig: ExtraFieldConfig = {
        questions_starting_page: this.questionnaire.questions_starting_page || 0,
      };

      this.questionnaire.fields.forEach((field) => {
        let hideCondition: () => boolean = null;
        if (field.reveal) {
          hideCondition = () => {
            let value = this.formValues[field.reveal.key];
            if (Array.isArray(value)) {
              return !value.includes(field.reveal.value);
            } else {
              return this.formValues[field.reveal.key] !== field.reveal.value;
            }
          };
        }
        if (this.pageFrames[field.page]) {
          this.pageFrames[field.page].push(
            this._moodFactoryService.buildMoodUnit(field, hideCondition, [], extraFieldConfig)
          );
        } else {
          this.pageFrames[field.page] = [
            this._moodFactoryService.buildMoodUnit(field, hideCondition, [], extraFieldConfig),
          ];
        }
      });
    });
  }
}
