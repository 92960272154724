/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MoodState, MoodRendererUnitType } from 'engage-common';
import { ChlFooterService } from '../services/chl-footer.service';
import { TranslationService } from '../services/translation.service';

@Component({
  selector: 'app-registration-introduction',
  templateUrl: './brown-labs.component.html',
})
export class RegistrationIntroductionComponent implements OnInit {
  state: MoodState;
  consentsValues: string[] = [];
  nextDisabled = true;

  constructor(
    private _translationService: TranslationService,
    private _footerService: ChlFooterService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.state = this._getState();
    this._footerService.visible.next(true);
    this._renderbuttons();
  }

  private _renderbuttons(): void {
    this._footerService.buttons.next([
      {
        buttons: [
          {
            text: () => this._translationService.translate(['buttons', 'back']),
            click: () => this._router.navigate(['register-with-verto']),
            buttonClasses: ['mood-default'],
          },
          {
            text: () => this._translationService.translate(['buttons', 'register']),
            click: () => this._router.navigate(['sign-up']),
            buttonClasses: ['mood-primary'],
            disabled: this.nextDisabled,
          },
        ],
      },
    ]);
  }

  private _checkForConsents(): void {
    const allValues = [
      'signed-spit-protocol',
      'submitted-sample-prior-to-study',
      'received-and-read-information-emailed-about-verto',
    ];
    this.nextDisabled = !allValues.every((value) => new Set(this.consentsValues).has(value));
    this._renderbuttons();
  }

  private _getState(): MoodState {
    const translate = (...path: string[]) => {
      return this._translationService.translate([
        'customization',
        'brown-labs',
        'pages',
        'register-with-verto',
        ...path,
      ]);
    };

    return {
      rendererFrame: [
        {
          type: MoodRendererUnitType.HEADER,
          label: translate('header'),
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          header: translate('study-overview', 'header'),
          expanded: true,
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: translate('study-overview', 'content')[0],
              margin: ['0', '0', '20px', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: translate('study-overview', 'content')[1],
              margin: ['0', '0', '20px', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: translate('study-overview', 'content')[2],
              margin: ['0', '0', '20px', '0'],
            },
          ],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: translate('consent', 'content'),
          margin: ['0', '0', '20px', '0'],
        },
        {
          type: MoodRendererUnitType.MULTI_CHECKBOX,
          change: ({ currentSelectedValues }: { currentSelectedValues: any[] }) => {
            this.consentsValues = currentSelectedValues ?? [];
            this._checkForConsents();
          },
          formData: {
            controlName: 'register-with-verto-consents',
            controlType: 'checkbox',
            options: [
              {
                label: translate('consent', 'checkboxes')[0],
                value: 'signed-spit-protocol',
              },
              {
                label: translate('consent', 'checkboxes')[1],
                value: 'submitted-sample-prior-to-study',
              },
              {
                label: translate('consent', 'checkboxes')[2],
                value: 'received-and-read-information-emailed-about-verto',
              },
            ],
          },
        },
      ].concat(
        translate('consent', 'links').map((link) => ({
          type: MoodRendererUnitType.PARAGRAPH,
          html: link,
        }))
      ),
    };
  }
}
