/* Copyright 2023 (Unpublished) Verto Inc. */

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { APP_BASE_HREF, PlatformLocation, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ReactiveFormsModule } from '@angular/forms';

import { MenuModule } from 'primeng/menu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputMaskModule } from 'primeng/inputmask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { EngageUtilsModule } from 'engage-utils';
import { AppSharedModule } from './app.shared.module';
import { ChlFooterComponent } from './utility-components/chl-footer/chl-footer.component';
import { StudyLinksComponent } from './study-links/study-links.component';
import { LandingComponent } from './landing/landing.component';
import { LinkSentComponent } from './link-sent/link-sent.component';
import { AddNewKitComponent } from './test-kit/add-new-kit/add-new-kit.component';
import { TokenRedirectComponent } from './token-redirect/token-redirect.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqComponent } from './faq/faq.component';
import { RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { InformedConsentComponent } from './informed-consent/informed-consent.component';
import { SafeHtmlPipe } from './services/safe-html.pipe';
import { NavComponent } from './utility-components/nav/nav.component';
import { SignUpConfirmComponent } from './sign-up-confirm/sign-up-confirm.component';
import { SignUpContactComponent } from './sign-up-contact/sign-up-contact.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { UserPreferencesComponent } from './user-preferences/user-preferences.component';
import { UserPreferencesConfirmComponent } from './user-preferences-confirm/user-preferences-confirm.component';
import { QuestionnairesComponent } from './questionnaires/questionnaires.component';
import { AnswerQuestionnaireComponent } from './answer-questionnaire/answer-questionnaire.component';
import { GtaaLandingComponent } from './landing/overrides/gtaa-landing/gtaa-landing.component';
import { BirchHillLandingComponent } from './landing/overrides/birch-hill-landing/birch-hill-landing.component';
import { MhlReassuranceLandingComponent } from './landing/overrides/mhl-reassurance-landing/mhl-reassurance-landing.component';
import { ReassuranceDemoLandingComponent } from './landing/overrides/reassurance-demo-landing/reassurance-demo-landing.component';
import { CpxYourwellnessLandingComponent } from './landing/overrides/cpx-yourwellness-landing/cpx-yourwellness-landing.component';
import { VectorHealthLandingComponent } from './landing/overrides/vector-health-landing/vector-health-landing.component';
import { InformedConsentFormComponent } from './informed-consent-form/informed-consent-form.component';
import { RegisteredWithVertoComponent } from './brown-labs/registered-with-verto.component';
import { RegistrationInfoComponent } from './brown-labs/registration-info.component';
import { EnrollInResearchStudyComponent } from './brown-labs/enroll-in-research-study.component';
import { AdHocCovidTestComponent } from './brown-labs/ad-hoc-covid-test/ad-hoc-covid-test.component';
import { RegistrationIntroductionComponent } from './brown-labs/registration-introduction.component';
import { CreateAnAccountComponent } from './brown-labs/create-an-account.component';
import { AdHocSignUpComponent } from './brown-labs/ad-hoc-covid-test/ad-hoc-sign-up.component';
import { LinkSentViewComponent } from './link-sent/link-sent-view.component';
import { LinkSentComponent as BrownLabsLinkSentComponent } from './brown-labs/link-sent.component';
import { ResultComponent } from './result/result.component';
import { ResultDocumentComponent } from './result-document/result-document.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    SignUpComponent,
    ChlFooterComponent,
    SignInComponent,
    LandingComponent,
    LinkSentViewComponent,
    LinkSentComponent,
    AddNewKitComponent,
    TokenRedirectComponent,
    PrivacyPolicyComponent,
    FaqComponent,
    InformedConsentComponent,
    StudyLinksComponent,
    SafeHtmlPipe,
    NavComponent,
    SignUpConfirmComponent,
    SignUpContactComponent,
    UserPreferencesComponent,
    UserPreferencesConfirmComponent,
    QuestionnairesComponent,
    AnswerQuestionnaireComponent,
    GtaaLandingComponent,
    BirchHillLandingComponent,
    MhlReassuranceLandingComponent,
    ReassuranceDemoLandingComponent,
    VectorHealthLandingComponent,
    CpxYourwellnessLandingComponent,
    InformedConsentFormComponent,

    // Note: custom for brown labs
    // todo(aleksanderbodurrui): remove these
    RegisteredWithVertoComponent,
    RegistrationInfoComponent,
    EnrollInResearchStudyComponent,
    AdHocCovidTestComponent,
    RegistrationIntroductionComponent,
    CreateAnAccountComponent,
    AdHocSignUpComponent,
    BrownLabsLinkSentComponent,
    ResultComponent,
    ResultDocumentComponent,
  ],
  imports: [
    EngageUtilsModule.init({
      store: {
        storage: window.sessionStorage,
        serializer: JSON.stringify,
        deserializer: JSON.parse,
      },
      apiInterceptor: {
        errorRedirects: {
          401: {
            redirectTo: ['sign-in'],
            exclude: ['sign-up-confirm'],
          },
        },
      },
    }),
    BrowserModule,
    AppRoutingModule,
    AppSharedModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    MenuModule,
    ReactiveFormsModule,
    InputMaskModule,
    NgxExtendedPdfViewerModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: localStorage.getItem('preferredLanguage') ?? 'en', // use French language
    },
    {
      // provide APP_BASE_HREF here to ensure custom engage directives can properly retrieve base url
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    DatePipe,
    SafeHtmlPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
