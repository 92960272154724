/* Copyright 2023 (Unpublished) Verto Inc. */

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  MoodRegexValidator,
  MoodRendererComponent,
  MoodRendererUnitType,
  MoodRenderFrame,
} from 'engage-common';
import { ChlFooterService } from '../services/chl-footer.service';
import { Router } from '@angular/router';
import { EngageSerializableStoreService } from 'engage-utils';
import {
  ChlPatientService,
  CONSENT_KEY,
  CONTACT_KEY,
  DEMOGRAPHICS_KEY,
  DEPENDENT_KEY,
  Patient,
} from '../services/chl-patient.service';
import { TranslationService } from '../services/translation.service';
import { MessageService } from 'primeng/api';
import { environment } from '../../environments/environment';
import { Validators } from '@angular/forms';
import { MoodCaptchaComponent } from '../../../../engage-common/src/lib/mood/mood-captcha/mood-captcha.component';
import { ConfigurationService } from '../services/configuration.service';
import { camelToSnakeCase } from '../utils/patient-utilities';
import { FieldConfig } from '../services/configuration/field-config';

@Component({
  selector: 'app-contact',
  templateUrl: './sign-up-contact.component.html',
  styleUrls: ['./sign-up-contact.component.scss'],
})
export class SignUpContactComponent implements OnInit, OnDestroy, AfterViewInit {
  signUpType: 'sms' | 'email';
  allowedSignUpType?: 'sms' | 'email';
  recaptcha_key: string;

  initialButtons = [
    {
      text: () => this._translationService.translate(['buttons', 'back']),
      click: () => this._router.navigate(['sign-up']),
      buttonClasses: ['mood-default'],
    },
    {
      text: () => this._translationService.translate(['buttons', 'submit']),
      click: () => this._submit(),
      buttonClasses: ['mood-primary'],
    },
  ];

  constructor(
    private _translationService: TranslationService,
    private _footerService: ChlFooterService,
    private _router: Router,
    private _store: EngageSerializableStoreService,
    private _patientService: ChlPatientService,
    private _primeNgMessageService: MessageService,
    private _configurationService: ConfigurationService
  ) {}

  @ViewChild(MoodRendererComponent) renderer: MoodRendererComponent;
  @ViewChild(MoodCaptchaComponent) captcha: MoodCaptchaComponent;

  countryCodes: FieldConfig;

  ngOnInit(): void {
    this._footerService.visible.next(true);
    this._showButtons(true);

    let captchaIframe;
    this._translationService.langObservable.subscribe((lang) => {
      captchaIframe =
        captchaIframe || document.querySelector('mood-captcha re-captcha div div iframe');
      if (captchaIframe) {
        captchaIframe.src = captchaIframe.src.replace(/hl=(.*?)&/, 'hl=' + lang + '&');
      }
    });

    this._configurationService.getConfig().subscribe((res) => {
      this.countryCodes = res.config.content.country_codes;
      if (res.config.content.hasOwnProperty('allowed_registration_type')) {
        this.allowedSignUpType = res.config.content.allowed_registration_type;
        this.signUpType = res.config.content.allowed_registration_type;
      }
      this.populateFrame();
      this.loaded = true;
    });
  }
  loaded = false;

  ngOnDestroy(): void {
    this._footerService.visible.next(false);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const restored = this._store.retrieve(CONTACT_KEY);

      if (restored) {
        this.renderer.form.setValue(restored);
      }

      this.renderer.form.markAllAsTouched();
    });
  }

  onUpdate(updateValues): void {
    // If signUpType was updated, clear form information
    // It is fine not to update the stored value at this point (eg to remove email if
    // signUpType is sms) since this is handled later on in the _submit function
    if (!this.allowedSignUpType && updateValues.signUpWith !== this.signUpType) {
      this.signUpType = updateValues.signUpWith;
      this.renderer.form.patchValue({ email: '', cellPhone: '' });
    }

    // Default country to first code entry (Usually Canada)
    if (updateValues.country_code === '') {
      this.renderer.form.patchValue({ country_code: this.countryCodes.options[0].value });
    }

    this._validateEmailAndPhone();

    this._showButtons(!this.renderer.form.valid);

    this._store.store(CONTACT_KEY, updateValues);
  }

  private _showButtons(submitDisabled = false): void {
    this._footerService.buttons.next([
      {
        buttons: [this.initialButtons[0], { ...this.initialButtons[1], disabled: submitDisabled }],
      },
    ]);
  }

  private _submit(): void {
    this._showButtons(true);
    const { cellPhone, email, country_code } = this._store.retrieve(CONTACT_KEY);
    const { firstName, lastName, healthCard } = this._store.retrieve(DEMOGRAPHICS_KEY);
    const preferredLanguage = localStorage.getItem('preferredLanguage');

    const patient: Patient = this._preparePatientPayload();

    const queryParams = { i: null };

    if (this.signUpType === 'email') {
      patient.email = email;
      patient.cell_phone_number = null;
      queryParams.i = email;
    }

    if (this.signUpType === 'sms') {
      patient.cell_phone_number = cellPhone;
      patient.email = null;
      patient.country_code = country_code;
    }

    const success = (response) => {
      if (this.signUpType === 'sms') {
        queryParams.i = response.cell_phone_number;
      }
      this._router.navigate(['sign-up-confirm'], { queryParams, queryParamsHandling: 'merge' });
    };

    const error = (e) => {
      this._resetCaptcha();
      this._showButtons(false);

      const errorMessages = [];
      if (e.error?.errors?.email) {
        if (e.status === 400) {
          errorMessages.push(
            this._translationService.translate(['pages', 'sign-up', 'errorRegisteringEmail'])
          );
        } else if (e.status === 422) {
          errorMessages.push(
            this._translationService.translate(['pages', 'sign-up', 'errorInvalidEmail'])
          );
        }
      }

      if (e.error?.errors?.healthcard) {
        if (e.status === 400 || e.status === 422) {
          errorMessages.push(
            this._translationService.translate(['pages', 'sign-up', 'errorInvalidHealthcard'])
          );
        }
      }

      if (e.error?.errors?.cell_phone_number) {
        if (e.status === 400) {
          errorMessages.push(
            this._translationService.translate(['pages', 'sign-up', 'errorRegisteringPhone'])
          );
        } else if (e.status === 422) {
          errorMessages.push(
            this._translationService.translate(['pages', 'sign-up', 'errorInvalidPhone'])
          );
        }
      }

      if (errorMessages.length === 0) {
        errorMessages.push(
          this._translationService.translate(['pages', 'sign-up', 'genericRegisterError'])
        );
      }

      errorMessages.forEach((message) => {
        this._primeNgMessageService.add({
          severity: 'error',
          summary: '',
          detail: message,
          life: 7000,
        });
      });
    };

    this._patientService.register(patient).subscribe(success, error);
  }

  private _captchaResolved(captcha: string): void {
    this.recaptcha_key = captcha;
  }

  private _resetCaptcha(): void {
    const captchaIframe = document.querySelector('mood-captcha re-captcha div div iframe') as any;
    this.recaptcha_key = null;
    if (captchaIframe) {
      captchaIframe.src = captchaIframe.src;
    }
  }

  private _retrieveConsentSignature(): string {
    const { signature } = this._store.retrieve(CONSENT_KEY) || {};
    return signature || '';
  }

  private _preparePatientPayload(): Patient {
    // TODO(post-migration): Remove `workSite`
    const { firstName, lastName, workSite, unitExternalIdentifier } =
      this._store.retrieve(DEMOGRAPHICS_KEY);
    const preferredLanguage = localStorage.getItem('preferredLanguage');
    const patient: Patient = {
      recaptcha_key: this.recaptcha_key,
      first_name: firstName,
      last_name: lastName,
      preferred_language: preferredLanguage,
      work_site: workSite, // TODO(post-migration): Remove
      unit_external_identifier: unitExternalIdentifier,
      informed_consent_signature: this._retrieveConsentSignature(),
    };

    // Since we may collect various data depending on the client, dynamically append information to Patient object
    const demographicData = this._store.retrieve(DEMOGRAPHICS_KEY);
    Object.keys(demographicData).forEach((o) => {
      patient[camelToSnakeCase(o)] = demographicData[o];
    });

    let dependentsIndex = 0;
    let getNextDependent = true;
    const dependentList = [];
    while (getNextDependent) {
      const dependentData = this._store.retrieve(`${DEPENDENT_KEY}_${dependentsIndex}`);
      if (dependentData === undefined) {
        getNextDependent = false;
      } else {
        const dependentObj = {};
        Object.keys(dependentData).forEach((o) => {
          dependentObj[camelToSnakeCase(o)] = dependentData[o];
        });
        dependentObj['dob'] = this._convertDate(dependentObj['dob']);
        dependentList.push(dependentObj);
        dependentsIndex++;
      }
    }
    patient['dependents'] = dependentList;

    // match date formats to backend from YYYYmmdd to YYYY-mm-dd
    patient['dob'] = this._convertDate(patient['dob']);

    if (!!patient['date_left_canada']) {
      patient['date_left_canada'] = this._convertDate(patient['date_left_canada']);
    }

    if (!!patient['arrival_date']) {
      patient['arrival_date'] = this._convertDate(patient['arrival_date']);
    }

    return patient;
  }

  private _convertDate(dateString) {
    // converts from YYYYmmdd to YYYY-mm-dd
    return (
      dateString.substring(0, 4) + '-' + dateString.substring(4, 6) + '-' + dateString.substring(6)
    );
  }

  private _validateEmailAndPhone(): void {
    const emailControl = this.renderer.form.controls['email'];
    const phoneControl = this.renderer.form.controls['cellPhone'];

    const emailIsEmpty = emailControl.value.length === 0;
    const phoneIsEmpty = phoneControl.value.length === 0;

    if (emailIsEmpty && phoneIsEmpty) {
      phoneControl.markAsDirty();
      phoneControl.markAllAsTouched();
      phoneControl.setErrors({ incorrect: true });

      emailControl.markAsDirty();
      emailControl.markAllAsTouched();
      emailControl.setErrors({ incorrect: true });
      return;
    }

    if (!emailIsEmpty && phoneIsEmpty) {
      if (!MoodRegexValidator.email().test(emailControl.value)) {
        emailControl.markAsDirty();
        emailControl.markAllAsTouched();
        emailControl.setErrors({ incorrect: true });
      }
      phoneControl.setErrors(null);
    }

    if (emailIsEmpty && !phoneIsEmpty) {
      if (!MoodRegexValidator.phoneNumber().test(phoneControl.value)) {
        phoneControl.markAsDirty();
        phoneControl.markAllAsTouched();
        phoneControl.setErrors({ incorrect: true });
      }
      emailControl.setErrors(null);
    }
  }

  populateFrame(): void {
    this.rendererFrame.push(
      {
        type: MoodRendererUnitType.INPUT,
        margin: ['6px', '0', '6px', '0'],
        hidden: () => this.signUpType !== 'sms',
        label: () =>
          this._translationService.translate(['pages', 'sign-up', 'fields', 'phone']).label,
        formData: {
          placeholder: () =>
            this._translationService.translate(['pages', 'sign-up', 'fields', 'phone']).placeholder,
          controlName: 'cellPhone',
          controlType: 'text',
          valueType: 'text',
          validators: {
            pattern: MoodRegexValidator.phoneNumber(),
            validatorFunctions: [Validators.required],
          },
        },
        helpText: () =>
          this._translationService.translate(['pages', 'sign-up', 'fields', 'phone']).helpText,
      },
      {
        type: MoodRendererUnitType.DROPDOWN,
        hidden: () => this.signUpType !== 'sms',
        formData: {
          placeholder: this._translationService.translate([
            'pages',
            'sign-up',
            'fields',
            this.countryCodes.key,
          ]).placeholder,
          controlName: 'country_code', // this.countryCodes.key,
          controlType: 'select',
          options: this.countryCodes.options,
          validators: {
            required: this.countryCodes.required,
          },
        },
      },
      {
        type: MoodRendererUnitType.INPUT,
        margin: ['6px', '0', '6px', '0'],
        hidden: () => this.signUpType !== 'email',
        label: () =>
          this._translationService.translate(['pages', 'sign-up', 'fields', 'email']).label,
        formData: {
          placeholder: () =>
            this._translationService.translate(['pages', 'sign-up', 'fields', 'email']).placeholder,
          controlName: 'email',
          controlType: 'email',
          valueType: 'text',
          validators: {
            validatorFunctions: [Validators.required, Validators.email],
          },
        },
      },
      {
        type: MoodRendererUnitType.PARAGRAPH,
        hidden: () => this.signUpType !== 'sms',
        html: () => this._translationService.translate(['pages', 'sign-up', 'phoneParagraph']),
        margin: ['0.5rem', '0', '0', '0'],
      },
      {
        type: MoodRendererUnitType.PARAGRAPH,
        hidden: () => this.signUpType !== 'email',
        html: () => this._translationService.translate(['pages', 'sign-up', 'emailParagraph']),
        margin: ['0.5rem', '0', '0', '0'],
      },
      {
        type: MoodRendererUnitType.CAPTCHA,
        siteKey: environment.recaptchaSiteKey,
        hidden: () => !(this.signUpType === 'sms' || this.signUpType === 'email'),
        margin: ['0', '0', '1rem', '0'],
        onResolved: ($event: string) => this._captchaResolved($event),
        gridClass: 'col-12 custom-captcha',
      }
    );
  }

  rendererFrame: MoodRenderFrame = [
    {
      type: MoodRendererUnitType.HEADER,
      label: () => this._translationService.translate(['pages', 'sign-up', 'header']),
      margin: ['0', '0', '12px', '0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () => this._translationService.translate(['pages', 'sign-up', 'contactParagraph']),
      margin: ['0.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.RADIO_GROUP,
      margin: ['0'],
      hidden: () => this.allowedSignUpType === 'sms' || this.allowedSignUpType === 'email',
      formData: {
        controlName: 'signUpWith',
        controlType: 'checkbox',
        options: [
          {
            label: () =>
              this._translationService.translate(['pages', 'sign-up', 'fields', 'phoneCheck'])
                .radioLabel,
            value: 'sms',
          },
          {
            label: () =>
              this._translationService.translate(['pages', 'sign-up', 'fields', 'emailCheck'])
                .radioLabel,
            value: 'email',
          },
        ],
        validators: {},
      },
    },
  ];
}
