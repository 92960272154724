<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div *ngIf="loaded; else spinner">
  <div *ngIf="pageError">
    <mood-renderer [moodFrame]="errorFrame"> </mood-renderer>
  </div>
  <div *ngIf="!pageError">
    <div class="row mb-4 flex-wrap">
      <div [class]="this.translationService.translate(['pages', 'addNewKit', 'cardImage']) ? 'col-md-4' : 'col-md-12'">
        <div class="header">
          <mood-header label="{{ getHeader() }}"></mood-header>
        </div>
        <div class="kit-message">
          <mood-paragraph
            content="{{ this.translationService.translate(['pages', 'addNewKit', 'instructions']) }}"
          ></mood-paragraph>
        </div>
      </div>
      <img
        engage-asset
        class="kit-card"
        [src]="this.translationService.translate(['pages', 'addNewKit', 'cardImage'])"
      />
    </div>
    <div class="row">
      <div class="kit-input col-md-6">
        <mood-renderer [moodFrame]="buttonFrame" (formUpdated)="updateForm($event)"> </mood-renderer>
      </div>
    </div>
    <div class="row">
      <div class="submit-kit-btn col-md-6">
        <mood-button
          [buttonClasses]="textButton.buttonClasses"
          [disabled]="textButton.disabled"
          (onClick)="textButton.click()"
        >
          <span *ngIf="!textButton.disabled; else buttonSpinner" [ngStyle]="textButton.text.style">{{
            textButton.text.label
          }}</span>
          <ng-template #buttonSpinner>
            <mood-spinner [styles]="textButton.spinner.styles" [light]="textButton.spinner.light"></mood-spinner>
          </ng-template>
        </mood-button>
      </div>
    </div>
  </div>
</div>

<ng-template #spinner>
  <mood-spinner></mood-spinner>
</ng-template>
