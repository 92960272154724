<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="confirm-container">
  <mood-renderer
    *ngIf="!loading; else spinner"
    [moodFrame]="rendererFrame"
    [wrapperClasses]="['row', 'no-gutter']"
    (formUpdated)="onUpdate($event)"
  ></mood-renderer>
</div>
<ng-template #spinner>
  <mood-spinner></mood-spinner>
</ng-template>
