/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MoodButtonGroupRenderUnit,
  MoodButtonRenderUnit,
  MoodRendererUnitType,
} from 'engage-common';
import { ChlFooterService } from '../../services/chl-footer.service';
import { Subscription } from 'rxjs';

export interface FooterButtonGroup {
  buttons: Omit<MoodButtonRenderUnit, 'type'>[];
}

@Component({
  selector: 'app-chl-footer',
  templateUrl: './chl-footer.component.html',
  styleUrls: ['./chl-footer.component.scss'],
})
export class ChlFooterComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];
  buttonRenderFrame: MoodButtonGroupRenderUnit[] = [];
  chlBrandingMode = false;

  constructor(private _footerService: ChlFooterService) {}

  ngOnInit(): void {
    this._subscriptions = [
      this._footerService.buttons.subscribe((buttons) => (this.buttons = buttons)),
      this._footerService.chlBrandingMode.subscribe((enabled) => (this.chlBrandingMode = enabled)),
    ];
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  set buttons(buttonGroups: FooterButtonGroup[]) {
    this.buttonRenderFrame = buttonGroups.map((buttonGroupUnit) => {
      return {
        ...buttonGroupUnit,
        type: MoodRendererUnitType.BUTTON_GROUP,
        margin: ['0'],
        buttons: buttonGroupUnit.buttons.map((buttonUnit) => {
          return {
            ...buttonUnit,
            type: MoodRendererUnitType.BUTTON,
            buttonClasses: [...buttonUnit.buttonClasses, 'mhl-footer-button'],
          };
        }),
      };
    });
  }
}
