/* Copyright 2023 (Unpublished) Verto Inc. */

export enum LandingPage {
  gtaa = 'gtaa',
  default = 'default',
  birch_hill = 'birch_hill',
  mhl_reassurance = 'mhl_reassurance',
  mhl_mccovid = 'mhl_mccovid',
  mhl_general = 'mhl_general',
  reassurance_demo = 'reassurance_demo',
  brown_labs = 'brown_labs',
  vector_health = 'vector_health',
  cpx_yourwellness = 'cpx_yourwellness',
}
