/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, Input, EventEmitter, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'test-kit-accordion',
  templateUrl: './test-kit-accordion.component.html',
  styleUrls: ['./test-kit-accordion.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'closed',
        style({
          'max-height': '0',
          'padding-top': 0,
          'padding-bottom': 0,
        })
      ),
      state(
        'opened',
        style({
          'max-height': '1000px',
        })
      ),
      transition('void => *', [animate('0s')]),
      transition('opened <=> closed', [animate('0.15s ease-in-out')]),
    ]),
  ],
})
export class TestKitAccordionComponent {
  @Input() headerTitle: string = '';
  @Input() headerSubtitle: string = '';
  @Input() expanded: boolean = false;
  @Output() onExpand = new EventEmitter<null>();
  icon: string = 'arrow-down';

  toggleExpand() {
    this.expanded = !this.expanded;
    this.icon = this.expanded ? 'arrow-up' : 'arrow-down';
    this.onExpand.emit();
  }
}
