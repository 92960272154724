/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { TranslationService } from '../services/translation.service';
import { environment } from '../../environments/environment';
import { MoodTableSettingsModel } from '../../../../engage-common/src/lib/mood/mood-table/mood-table-settings.models';
import { MoodTableCellType } from '../../../../engage-common/src/lib/mood/mood-table/mood-table-cell-type.enum';
import { MoodButtonDataModel } from '../../../../engage-common/src/lib/mood/mood-table/mood-table-button-data.model';
import { ChlPatientService, Questionnaire } from '../services/chl-patient.service';
import { map, mergeMap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { EngageSerializableStoreService } from 'engage-utils';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-questionnaires',
  templateUrl: './questionnaires.component.html',
  styleUrls: ['./questionnaires.component.scss'],
})
export class QuestionnairesComponent implements OnInit {
  header: string;
  loading: boolean;
  questionnaires: Questionnaire[];
  attach_to: string = 'Patient';
  showQuestionnaires: String[];
  questionnaireData: any[];
  questionnaireListSettings: MoodTableSettingsModel = {
    columnHeadersShow: true,
    messages: {
      empty: 'There are no questionnaires available at the moment',
    },
    fields: [
      {
        name: 'questionnaire',
        label:
          this.translationService.translate([
            'customization',
            environment.client,
            'pages',
            'questionnaires',
            'name',
          ]) || 'Name',
        type: MoodTableCellType.SINGLE_TEXT,
        headerClasses: ['center'],
        itemClasses: ['text-large', 'pd-tb-10', 'center'],
        itemStyle: {
          'min-width': '190px',
        },
      },
      {
        names: ['status', 'timestamp'],
        label:
          this.translationService.translate([
            'customization',
            environment.client,
            'pages',
            'questionnaires',
            'status',
          ]) || 'Status',
        type: MoodTableCellType.MULTI_TEXT,
        headerClasses: ['center'],
        itemClasses: ['center'],
        itemStyle: {
          'min-width': '230px',
        },
      },
      {
        name: 'id',
        label:
          this.translationService.translate([
            'customization',
            environment.client,
            'pages',
            'questionnaires',
            'action',
          ]) || 'Action',
        type: MoodTableCellType.BUTTON,
        headerClasses: ['center'],
        itemClasses: ['center'],
        itemStyle: {
          'min-width': '190px',
        },
        moodButton: {
          buttonClasses: [],
          disabled: false,
          text: {
            style: {
              'font-size': '18px',
            },
          },
          icon: {
            style: {
              'margin-right': '10px',
              'vertical-align': 'text-top',
              width: '18px',
              height: '18px',
            },
          },
        },
      },
    ],
  };

  constructor(
    private configurationService: ConfigurationService,
    private translationService: TranslationService,
    private patientService: ChlPatientService,
    private datePipe: DatePipe,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private _store: EngageSerializableStoreService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.header = this.translationService.translate([
      'customization',
      environment.client,
      'pages',
      'questionnaires',
      'header',
    ]);

    if (this._store.retrieve('filter')) {
      this.showQuestionnaires = this._store.retrieve('filter').split(',');
    }

    this.configurationService.getConfig().subscribe((app) => {
      if (app.config.content.questionnaire_attach_to) {
        this.attach_to = app.config.content.questionnaire_attach_to;
      }

      this.patientService
        .retrieveCompletedQuestionnaires(this.attach_to)
        .pipe(
          map((res) => {
            this.questionnaires = res.questionnaires;
          }),
          mergeMap(() => this.configurationService.getConfig())
        )
        .subscribe((app) => {
          this.questionnaireData = app.config.content.questionnaires
            .filter((qIn) =>
              this.showQuestionnaires ? this.showQuestionnaires.includes(qIn) : true
            )
            .map((q) => this.mapQuestionnaireToData(q));
          this.loading = false;
        });
    });
  }

  private startQuestionnaire(questionnaireKey: string) {
    this.router.navigate(['answer-questionnaire'], {
      queryParams: { q: questionnaireKey },
      queryParamsHandling: 'merge',
    });
  }

  private mapQuestionnaireToData(questionnaireKey: string): any {
    let questionnaireCompleted = false;
    let timestamp = null;
    for (const q of this.questionnaires) {
      if (q.responses['questionnaireKey'] === questionnaireKey) {
        questionnaireCompleted = true;
        timestamp = this.datePipe.transform(
          new Date(q.responses['completed_date']),
          'yyyy-MM-dd h:mmaa'
        );
        break;
      }
    }
    const done =
      this.translationService.translate([
        'customization',
        environment.client,
        'pages',
        'questionnaires',
        'done',
      ]) || 'Done';
    const notStarted =
      this.translationService.translate([
        'customization',
        environment.client,
        'pages',
        'questionnaires',
        'not-started',
      ]) || 'Not Started';
    const completed =
      this.translationService.translate([
        'customization',
        environment.client,
        'pages',
        'questionnaires',
        'completed',
      ]) || 'Completed';
    const start =
      this.translationService.translate([
        'customization',
        environment.client,
        'pages',
        'questionnaires',
        'start',
      ]) || 'Start';

    return {
      questionnaire: this.translationService.translate([
        'customization',
        environment.client,
        'questionnaires',
        questionnaireKey,
      ]),
      status: questionnaireCompleted ? done : notStarted,
      timestamp,
      id: questionnaireKey,
      button: {
        text: questionnaireCompleted ? completed : start,
        icon: questionnaireCompleted ? 'check' : null,
        click: () => (questionnaireCompleted ? {} : this.startQuestionnaire(questionnaireKey)),
        className: questionnaireCompleted ? 'mood-success-wide' : 'mood-primary-wide',
      } as MoodButtonDataModel,
    };
  }
}
