/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MoodRendererUnitType, MoodRenderFrame, MoodRendererComponent } from 'engage-common';
import { TranslationService } from '../../../services/translation.service';
import { Router } from '@angular/router';
import { ConfigurationService } from '../../../services/configuration.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-cpx-yourwellness-landing',
  templateUrl: './cpx-yourwellness-landing.component.html',
  styleUrls: ['./cpx-yourwellness-landing.component.scss'],
})
export class CpxYourwellnessLandingComponent implements OnInit {
  constructor(public _translationService: TranslationService) {}

  ngOnInit(): void {}
}
