/* Copyright 2023 (Unpublished) Verto Inc. */

import { InformedConsentComponent } from '../../informed-consent.component';
export const mhlInformedConsentForm = (thisArg: InformedConsentComponent) => {
  return `<html>
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <meta name="Generator" content="Microsoft Word 15 (filtered)">
    <style lang="scss" scoped>
    body .consent-form-exportable {
        max-width: 1000px;

        * {
            font-family: "Raleway", sans-serif;
            max-width: 1000px;
        }
        /* Font Definitions */
        @font-face {
            font-family: Wingdings;
            panose-1: 5 0 0 0 0 0 0 0 0 0;
        }
        @font-face {
            font-family: SimSun;
            panose-1: 2 1 6 0 3 1 1 1 1 1;
        }
        @font-face {
            font-family: "Cambria Math";
            panose-1: 2 4 5 3 5 4 6 3 2 4;
        }
        @font-face {
            font-family: Calibri;
            panose-1: 2 15 5 2 2 2 4 3 2 4;
        }
        @font-face {
            font-family: "Calibri Light";
            panose-1: 2 15 3 2 2 2 4 3 2 4;
        }
        @font-face {
            font-family: "Segoe UI";
            panose-1: 2 11 5 2 4 2 4 2 2 3;
        }
        @font-face {
            font-family: "KBAQA C+ Bliss";
            panose-1: 2 11 6 4 2 2 2 2 2 4;
        }
        @font-face {
            font-family: "\@SimSun";
            panose-1: 2 1 6 0 3 1 1 1 1 1;
        }
        /* Style Definitions */
        p.MsoNormal,
        li.MsoNormal,
        div.MsoNormal {
            margin: 0cm;
            font-size: 12.0pt;
            font-family: "Times New Roman", serif;
        }
        h1 {
            mso-style-link: "Heading 1 Char";
            margin-top: 12.0pt;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 0cm;
            line-height: 107%;
            page-break-after: avoid;
            font-size: 16.0pt;
            font-family: "Calibri Light", sans-serif;
            color: #2F5496;
            font-weight: normal;
        }
        p.MsoCommentText,
        li.MsoCommentText,
        div.MsoCommentText {
            mso-style-link: "Comment Text Char";
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 8.0pt;
            margin-left: 0cm;
            font-size: 10.0pt;
            font-family: "Calibri", sans-serif;
        }
        p.MsoHeader,
        li.MsoHeader,
        div.MsoHeader {
            mso-style-link: "Header Char";
            margin: 0cm;
            font-size: 12.0pt;
            font-family: "Times New Roman", serif;
        }
        p.MsoFooter,
        li.MsoFooter,
        div.MsoFooter {
            mso-style-link: "Footer Char";
            margin: 0cm;
            font-size: 12.0pt;
            font-family: "Times New Roman", serif;
        }
        p.MsoBodyText,
        li.MsoBodyText,
        div.MsoBodyText {
            mso-style-link: "Body Text Char";
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 11.0pt;
            text-autospace: none;
            font-size: 12.0pt;
            font-family: "Calibri", sans-serif;
        }
        a:link,
        span.MsoHyperlink {
            color: #0563C1;
            text-decoration: underline;
        }
        p {
            margin-right: 0cm;
            margin-left: 0cm;
            font-size: 12.0pt;
            font-family: "Times New Roman", serif;
        }
        p.MsoListParagraph,
        li.MsoListParagraph,
        div.MsoListParagraph {
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 36.0pt;
            font-size: 12.0pt;
            font-family: "Times New Roman", serif;
        }
        p.MsoListParagraphCxSpFirst,
        li.MsoListParagraphCxSpFirst,
        div.MsoListParagraphCxSpFirst {
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 36.0pt;
            font-size: 12.0pt;
            font-family: "Times New Roman", serif;
        }
        p.MsoListParagraphCxSpMiddle,
        li.MsoListParagraphCxSpMiddle,
        div.MsoListParagraphCxSpMiddle {
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 36.0pt;
            font-size: 12.0pt;
            font-family: "Times New Roman", serif;
        }
        p.MsoListParagraphCxSpLast,
        li.MsoListParagraphCxSpLast,
        div.MsoListParagraphCxSpLast {
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 0cm;
            margin-left: 36.0pt;
            font-size: 12.0pt;
            font-family: "Times New Roman", serif;
        }
        span.Heading1Char {
            mso-style-name: "Heading 1 Char";
            mso-style-link: "Heading 1";
            font-family: "Calibri Light", sans-serif;
            color: #2F5496;
        }
        span.CommentTextChar {
            mso-style-name: "Comment Text Char";
            mso-style-link: "Comment Text";
        }
        span.BodyTextChar {
            mso-style-name: "Body Text Char";
            mso-style-link: "Body Text";
            font-family: "Calibri", sans-serif;
        }
        span.BodyText12Char {
            mso-style-name: "Body Text 12 Char";
            mso-style-link: "Body Text 12";
        }
        p.BodyText12,
        li.BodyText12,
        div.BodyText12 {
            mso-style-name: "Body Text 12";
            mso-style-link: "Body Text 12 Char";
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 12.0pt;
            margin-left: 0cm;
            text-align: justify;
            line-height: 110%;
            font-size: 12.0pt;
            font-family: "Calibri", sans-serif;
        }
        p.Basic12,
        li.Basic12,
        div.Basic12 {
            mso-style-name: "Basic 12";
            margin: 0cm;
            font-size: 12.0pt;
            font-family: "Times New Roman", serif;
        }
        p.Bullet12-1,
        li.Bullet12-1,
        div.Bullet12-1 {
            mso-style-name: "Bullet 12-1";
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 6.0pt;
            margin-left: 21.6pt;
            text-align: justify;
            text-indent: -21.6pt;
            font-size: 12.0pt;
            font-family: "Times New Roman", serif;
        }
        p.SubheadingBold12,
        li.SubheadingBold12,
        div.SubheadingBold12 {
            mso-style-name: "Subheading Bold 12";
            margin-top: 12.0pt;
            margin-right: 0cm;
            margin-bottom: 6.0pt;
            margin-left: 0cm;
            page-break-after: avoid;
            font-size: 12.0pt;
            font-family: "Times New Roman", serif;
            font-weight: bold;
        }
        p.ICFBodyText,
        li.ICFBodyText,
        div.ICFBodyText {
            mso-style-name: "ICF BodyText";
            margin-top: 0cm;
            margin-right: 0cm;
            margin-bottom: 10.0pt;
            margin-left: 0cm;
            font-size: 11.0pt;
            font-family: "Arial", sans-serif;
        }
        span.A1 {
            mso-style-name: A1;
            font-family: "KBAQA C+ Bliss", sans-serif;
            color: black;
        }
        span.HeaderChar {
            mso-style-name: "Header Char";
            mso-style-link: Header;
            font-family: "Times New Roman", serif;
        }
        span.FooterChar {
            mso-style-name: "Footer Char";
            mso-style-link: Footer;
            font-family: "Times New Roman", serif;
        }
        .MsoChpDefault {
            font-family: "Calibri", sans-serif;
        }
        /* Page Definitions */
        @page WordSection1 {
            size: 612.0pt 792.0pt;
            margin: 72.0pt 72.0pt 72.0pt 72.0pt;
        }
        div.WordSection1 {
            page: WordSection1;
        }
        @page WordSection2 {
            size: 612.0pt 792.0pt;
            margin: 72.0pt 72.0pt 72.0pt 72.0pt;
        }
        div.WordSection2 {
            page: WordSection2;
        }
        /* List Definitions */
        ol {
            margin-bottom: 0cm;
        }
        ul {
            margin-bottom: 0cm;
        }
    }
    }
    </style>
  </head>
  <body class="consent-form-exportable">
    <div class="WordSection1">
      <p class="MsoNormal" align="center" style="text-align:center">
        <img alt="" src="/assets/images/mhl-print-header.png" style="width: 194px; height: 43px; margin-left: 0px; margin-top: 0px; transform: rotate(0rad) translateZ(0px); -webkit-transform: rotate(0rad) translateZ(0px);" title="" />
  &nbsp;
      </p>
      <p class="ICFBodyText" align="center" style="text-align:center"><b><span
            style="font-size:14.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></b></p>
      <p class="ICFBodyText" align="center" style="text-align:center"><b><span
            style="font-size:14.0pt;font-family:'Calibri',sans-serif">Participant
            Information and Consent Form</span></b></p>
      <table class="MsoNormalTable" border="0" cellspacing="0" cellpadding="0" width="100%"
        style="width: 90%;margin: 0 auto;border-collapse:collapse">
        <tbody>
          <tr>
            <td width="30%" valign="top" style="width:30.48%;padding:0cm 5.75pt 0cm 5.75pt">
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif">Sponsors:</span></b></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></b></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></b></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></b></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></b></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif">Protocol Title</span></b></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></b></p>
            </td>
            <td width="68%" colspan="2" valign="top" style="width:68.58%;padding:0cm 5.75pt 0cm 5.75pt">
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><span
                  style="font-size:12.0pt;font-family:'Calibri',sans-serif">McMaster HealthLabs
                  (MHL) </span></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><span
                  style="font-size:12.0pt;font-family:'Calibri',sans-serif">175 Longwood Road
                  South, Suite 305</span></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><span
                  style="font-size:12.0pt;font-family:'Calibri',sans-serif">Hamilton, Ontario |
                  L8P0A1</span></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><span
                  style="font-size:12.0pt;font-family:'Calibri',sans-serif">1-833-292-5856</span></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><span
                  style="font-size:12.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-family:'Calibri',sans-serif">COVID-19 Workplace Surveillance
                    under a Virtual Clinical Service Delivery Model</span></b></p>
            </td>
          </tr>
          <tr>
            <td width="30%" valign="top" style="width:30.48%;padding:0cm 5.75pt 0cm 5.75pt">
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif">Protocol Number:</span></b></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></b></p>
            </td>
            <td width="68%" colspan="2" valign="top" style="width:68.58%;padding:0cm 5.75pt 0cm 5.75pt">
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif">Pro00049441</span></b></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif;color:#C00000">&nbsp;</span></b>
              </p>
            </td>
          </tr>
          <tr>
            <td width="30%" valign="top" style="width:30.48%;padding:0cm 5.75pt 0cm 5.75pt">
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif">Principal
                    Investigator(s):</span></b></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></b></p>
              <p class="MsoNormal" style="text-align:justify"><b><span style="font-family:
    'Calibri',sans-serif">&nbsp;</span></b></p>
              <p class="MsoNormal" style="text-align:justify"><b><span style="font-family:
    'Calibri',sans-serif">Co-Investigator: </span></b></p>
              <p class="MsoNormal" style="text-align:justify"><b><span style="font-family:
    'Calibri',sans-serif">&nbsp;</span></b></p>
              <p class="MsoNormal" style="text-align:justify"><b><span style="font-family:
    'Calibri',sans-serif">Collaborator: </span></b></p>
              <p class="MsoNormal" style="text-align:justify"><b><span style="font-family:
    'Calibri',sans-serif">&nbsp;</span></b></p>
              <p class="MsoNormal" style="text-align:justify"><b><span style="font-family:
    'Calibri',sans-serif">&nbsp;</span></b></p>
              <p class="MsoNormal" style="text-align:justify"><b><span style="font-family:
    'Calibri',sans-serif">Collaborators:</span></b></p>
              <p class="MsoNormal" style="text-align:justify"><b><span style="font-family:
    'Calibri',sans-serif">&nbsp;</span></b></p>
            </td>
            <td width="68%" colspan="2" valign="top" style="width:68.58%;padding:0cm 5.75pt 0cm 5.75pt">
              <p class="MsoNormal" style="text-align:justify"><a name="_Hlk57952557"><b><span
                      style="font-family:'Calibri',sans-serif">Dr. David Bulir –</span></b></a><b><span
                    style="font-family:'Calibri',sans-serif"> Research Director, MHL</span></b></p>
              <p class="MsoNormal" style="text-align:justify"><b><span style="font-family:
    'Calibri',sans-serif">Affiliate Research Scientist, Research St. Joseph’s –
                    Hamilton</span></b></p>
              <p class="MsoNormal" style="text-align:justify">&nbsp;</p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif">Dr. Marek Smieja -
                    Scientific Director, McMaster</span></b><b><span style="font-size:12.0pt;
    font-family:'Calibri',sans-serif"> HealthLabs</span></b></p>
              <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><b><span
                    style="font-size:12.0pt;font-family:'Calibri',sans-serif">&nbsp;</span></b></p>
              <p class="MsoNormal"><b><span style="font-family:'Calibri',sans-serif">Dr.
                    Daniela Leto - Medical Microbiologist | Hamilton Regional Laboratory Medicine
                    Program</span></b></p>
              <p class="MsoNormal" style="text-align:justify"><b><span style="font-family:
    'Calibri',sans-serif">&nbsp;</span></b></p>
              <p class="MsoNormal" style="text-align:justify"><b><span style="font-family:
    'Calibri',sans-serif">Research St. Joe’s – Hamilton </span></b></p>
              <p class="MsoNormal" style="text-align:justify"><b><span style="font-family:
    'Calibri',sans-serif;color:#2E74B5">&nbsp;</span></b></p>
            </td>
          </tr>
          <tr>
            <td width="30%" valign="top" style="width:30.48%;padding:0cm 5.75pt 0cm 5.75pt"></td>
            <td width="68%" colspan="2" valign="top" style="width:68.58%;padding:0cm 5.75pt 0cm 5.75pt"></td>
          </tr>
          <tr>
            <td width="97%" colspan="3" valign="top" style="width:97.14%;border:solid windowtext 1.0pt;
    /* border-top:none; */background:#CCFFFF;padding:0cm 5.4pt 0cm 5.4pt">
              <p class="BodyText12" align="center" style="margin-bottom:0cm;text-align:center;
    line-height:normal"><b><span style="color:black">Please read this document
                    carefully</span></b></p>
              <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">&nbsp;</p>
              <p class="BodyText12" style="margin-bottom:0cm;line-height:normal"><span style="color:black">You are invited
                  to participate in this COVID-19 research
                  study (“<b>Study</b>“) because your employer has decided to offer an on-site
                  COVID-19 surveillance program.</span><span style="color:#C00000"> </span><span style="color:black">Your
                  taking part in this Study is voluntary. Before you
                  decide, we would like you to know why this Study is being done, what it
                  involves, and the risks and benefits to participation.&nbsp;<b>Please read
                    this form carefully and take your time to decide</b>.&nbsp;You can ask the
                  research team any questions you may have.</span><span style="color:black">&nbsp;
                </span></p>
            </td>
            <td style="border:none;padding:0cm 0cm 0cm 0cm" width="2%">
              <p class="MsoNormal">&nbsp;</p>
            </td>
          </tr>
          <tr height="0">
            <td width="290" style="border:none"></td>
            <td width="626" style="border:none"></td>
            <td width="27" style="border:none"></td>
          </tr>
        </tbody>
      </table>
      <p class="Basic12" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">&nbsp;</span>
      </p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">Why is this study
            being done?</span></b><b><span style="font-family:'Calibri',sans-serif;
  color:windowtext"> </span></b></h1>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">You
          are being invited to participate in this research Study to help provide
          information about Canada’s response to the COVID-19 pandemic, and to help
          respond better to future outbreaks. <span class="A1"><span style="font-family:
  'Calibri',sans-serif;color:windowtext">The McMaster HealthLabs (</span></span>MHL)
          research team is also exploring innovative ways to implement workplace surveillance
          COVID-19 testing programs. </span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><span
          style="font-size:12.0pt;font-family:'Calibri',sans-serif">Your participation
          will start when you sign and date this informed consent document. </span></p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal"><span style="color:#C00000">&nbsp;</span></p>
      <p class="MsoBodyText" style="margin-left:0cm;text-align:justify">Your
        participation in this study is completely voluntary. <span lang="EN-US"
          style="color:black;background:white">Regardless of your decision to
          participate, you will still need to review your employer's COVID-19 safety
          policy to assess whether you meet your employer’s requirements to work on-site
          at their physical premises</span><span lang="EN-US" style="font-family:'Segoe UI',sans-serif;
  color:black;background:white">.</span></p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">&nbsp;</p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">McMaster
        HealthLabs (MHL) is an independent, not-for-profit organization. MHL is working
        with a team of researchers who are affiliated with Research St. Joseph’s –
        Hamilton to conduct the study. They have contracted with your employer<span style="color:#C00000"> </span>to
        initiate the study. MHL is responsible for
        managing all aspects of the study. </p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">&nbsp;</p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">&nbsp;</p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">What happens during
            the Study?</span></b></h1>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">You
          will be asked to self-collect an oral-nasal specimen which will be sent to a
          laboratory for COVID-19 analysis. Oral-nasal specimens are self-administered
          swabs that involve moistening the swab on the tip of your tongue, followed by
          inserting the swab between the lower gums and cheek and turning it three times.
          Then, the swab is inserted about one inch into each nostril, and turned three
          times. The swab is then placed into McMaster Molecular Medium (MMM), which
          inactivates the virus. </span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">The
          Study will also involve answering two online questionnaires, one at
          registration and one at the conclusion of the study. In addition, individuals
          who test ‘preliminary positive’ for COVID will answer a separate questionnaire.
          The questionnaires will take approximately<span style="color:#2E74B5"> </span>10-15
          minutes. We will ask you questions about your history of COVID-19 testing, your
          perceived COVID-19 risk, and motivations for participating in the study. All
          study questionnaires and questions within the platform<span style="color:#C00000">
          </span>are completely voluntary. You may skip any question you do not wish to
          answer. These surveys are online, and information is not stored until submitted
          so before you begin each survey, we ask you to please decide if you will have
          enough time to complete the survey. </span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><span
          style="font-size:12.0pt;font-family:'Calibri',sans-serif;color:windowtext">Helpdesk
          will be available 7 days a week to support participants for the full duration
          of the study. Participants may reach out to the Helpdesk at any time via phone
          or email. </span></h1>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">Study Schedule</span></b><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext"> </span></b></h1>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">When
          the study begins, trained<span style="color:#C00000"> </span>staff will be
          available virtually at your place of work to provide information about the study
          and answer any questions you may have. You will read and sign the Informed Consent,
          register online and complete the first questionnaire. Following this you then
          will collect a self-administered oral-nasal swab sample.&nbsp; &nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">At
          baseline you will be provided with information by trained staff on where to
          pick-up your test kit, how to collect your specimens, and where to drop off
          your specimens. In each subsequent week, you will receive a notification via
          SMS or email when it is time to collect your sample. Sample collection will
          take place in a dedicated location under the virtual supervision of a MHL
          research team member who will advise you on the proper collection technique and
          answer any questions you may have. You will place your specimen into an on-site
          drop-box. Specimens will be couriered to the virology research lab of Research
          St. Joseph’s – Hamilton on a scheduled day.&nbsp; </span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">If
          you require further assistance at any point during the study, you may contact
          the study HelpDesk by phone or by email at any time. They will answer any
          questions you may have regarding test kit registration, specimen collection,
          study questionnaires, or anything else you may require assistance with. You can
          expect an initial response from HelpDesk within 24-28 hours after you have
          contacted them with your request. &nbsp;If the HelpDesk does not respond after 24<span style="color:#C00000">
          </span>hours, you may contact them again or contact the
          Research Coordinator.&nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">You
          will be provided with results (‘negative’, ‘invalid’, or ‘preliminary positive’’)
          within 48 hours of your specimen arriving at the laboratory. The availability
          of results will be communicated with you via SMS or email, and you may view
          them on a password protected website with your personal password as well. You
          may also receive a ‘defective’ result if the research lab was unable to process
          your specimen; this may happen for example when a sample has leaked or spilled,
          or if the sample has been damaged. </span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">Regardless
          of your test results, you are required to adhere to local laws and regional
          public health guidelines relating to COVID-19 and otherwise. </span><span
          style="font-family:'Calibri',sans-serif">If you receive a ‘preliminary positive’
          test result</span><span style="font-size:11.0pt;font-family:'Calibri',sans-serif">
        </span><span style="font-family:'Calibri',sans-serif">in the research
          laboratory, a delegated and trained study staff member will contact you using
          the contact information you provided during study enrolment to inform you of
          your result. They will advise you on the precautions you need to take and
          inform you that your sample will be sent to the clinical virology laboratory of
          the Hamilton Regional Laboratory Medicine Program (HRLMP) for confirmatory
          testing. The study staff member may request your address and health card number
          for the purpose of registering your specimen within the HRLMP clinical
          laboratory and sharing your result with Public Health. Your personal
          information will be shared with HRLMP as well as Public Health whether you
          provide your health card number or not. Clinical test results will also be
          shared with the study team. Once your specimen is processed by HRLMP, a
          delegated and trained study staff member will contact you a second time using
          the contact information you provided during study enrolment to inform you of
          the result. MHL and HRLMP medical microbiologists will be available to answer
          any questions you may have about your test results. If your test result is
          confirmed as positive, you will also be contacted by your local public health authorities
          to advise you of your responsibilities and initiate contact tracing. A results
          reporting pathway flowchart is available under the ‘quick links’ tab of the
          study website (www.mhltest.com). </span></p>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif;
  color:#C00000">&nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">Participants
          may also be subject to their company guidelines for employees who test positive
          for COVID-19. Participants should review these guidelines before choosing to
          participate in the study.</span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><span
          style="font-size:12.0pt;font-family:'Calibri',sans-serif;color:windowtext">HelpDesk
          support will be also available 7 days a week to assist participants for the
          full duration of the study. Participants may reach out to the HelpDesk at any
          time via phone or email. </span></h1>
      <p class="MsoNormal" style="text-align:justify">&nbsp;</p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">Study Duration</span></b><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">:</span></b></h1>
      <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><span
          style="font-size:12.0pt;font-family:'Calibri',sans-serif">You will be in the
          Study for minimum of 4<span style="color:#C00000"> </span>weeks. Your employer
          may choose to extend the duration of the surveillance program at your site.
          Samples will be collected at study enrollment, and you will self-collect
          samples on a weekly basis. </span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><b><span style="font-family:'Calibri',sans-serif">Special
            Information Collection</span></b></p>
      <p class="MsoBodyText" style="margin-left:0cm;text-align:justify">MHL has
        partnered with a data management organization called VERTO. Your personal
        contact information (for example, name, date of birth, telephone number, and
        e-mail address) will be collected at your baseline visit today. This will allow
        the research team to contact you if needed regarding your test results. Your
        personal contact information will be kept confidential and stored on an
        encrypted, firewall-secured Cloud platform at VERTO. </p>
      <p class="MsoBodyText" style="margin-left:0cm;text-align:justify">&nbsp;</p>
      <p class="MsoBodyText" style="margin-left:0cm;text-align:justify">Your swab
        samples will be labelled with a unique Study code, so that no one can identify
        you. Only the unique code is available to researchers. The unique sample code
        can only be linked back to your personal information by accessing the VERTO
        database which is restricted to delegated study members. Your personal contact
        information will be kept confidential and stored in a separate database managed
        by VERTO. </p>
      <p class="MsoBodyText" style="margin-left:0cm;text-align:justify">&nbsp;</p>
      <p class="MsoBodyText" style="margin-left:0cm;text-align:justify">The
        questionnaire data that you complete will be linked to the same study code and
        not to any personally identifying information.&nbsp; </p>
      <p class="MsoBodyText" style="margin-left:0cm;text-align:justify">&nbsp;</p>
      <p class="MsoBodyText" style="margin-left:0cm;text-align:justify">None of the
        entities that have provided private funding will have access to your personal
        information. </p>
      <p class="MsoBodyText" style="margin-left:0cm;text-align:justify"><span
          style="font-family:'Calibri Light',sans-serif">&nbsp;</span></p>
      <p class="MsoBodyText" style="margin-left:0cm;text-align:justify">&nbsp;</p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">What are the possible
            risks and inconveniences of being in this observational Study?</span></b></h1>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">There
          is the risk that your COVID-19 test will return a false ‘preliminary positive’
          result. This is unfortunately an element of the current testing protocols, and
          not particular to this Study. You may experience stress or anxiety upon receipt
          of a “preliminary positive” test result. If this is a concern, please consider
          your best interests in whether or not to participate. There is also the risk of
          a false “negative” result. Please be advised that receiving a negative result
          does not guarantee that you do not have COVID-19. MHL makes no representations
          or warranties regarding the accuracy of any COVID-19 test. </span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></p>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">If
          you receive a ‘preliminary positive’ result, or a ‘negative’ result, but have
          COVID-19-like symptoms, you need to take precautions. We recommend you complete
          the Ontario Ministry of Health online self-assessment found here: </span><a
          href="https://covid-19.ontario.ca/self-assessment/"><span style="font-family:
  'Calibri',sans-serif">https://covid-19.ontario.ca/self-assessment/</span></a><span
          style="font-family:'Calibri',sans-serif"> and visit an assessment centre if
          recommended.</span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">There
          is a risk of viewing the consent document on a personal electronic device
          (PED), especially if that PED is shared with other users, is lost, hacked or
          subject to a search warrant or subpoena.&nbsp; Since you signed this consent using a
          PED, you will be able to download an electronic copy of the signed and dated
          document.</span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">There
          is the risk that you will find the testing technique uncomfortable. There are
          no other significant risks to you when participating in this Study. <a name="_Hlk3294784">There may be other
            risks or side effects that are
            unforeseeable or not listed above.</a> </span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">There
          is no additional reward available for any risks or inconveniences of
          participating in the Study.</span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">Alternatives to
            participation </span></b></h1>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">The
          only alternative is to not participate in this study. You may seek COVID-19
          testing at an assessment centre if you meet the guidelines for clinical
          testing. </span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">New findings </span></b></h1>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">Any
          new important information that is discovered during the study and which may
          influence your willingness to continue participation in the study will be
          provided to you.</span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">What are the benefits
            of being in the Study?</span></b></h1>
      <p class="MsoBodyText" style="margin-top:0cm;margin-right:9.9pt;margin-bottom:
  0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify"><span lang="EN-US">There
          are no monetary benefits or incentives for participation in this study. </span></p>
      <p class="MsoBodyText" style="margin-top:0cm;margin-right:9.9pt;margin-bottom:
  0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:justify"><span lang="EN-US">&nbsp;</span></p>
      <p class="MsoNormal" style="margin-bottom:8.0pt;text-align:justify;line-height:
  107%"><span style="font-family:'Calibri',sans-serif">By participating in this
          study you will help us conduct research on:</span></p>
      <p class="MsoListParagraphCxSpFirst" style="margin-bottom:8.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:107%"><span style="font-family:Symbol">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
          style="font-family:'Calibri',sans-serif">Feasibility of virtual clinical
          services to enhance diagnostic access: supervision of self-sampling, access of
          testing results and, clinical management services</span></p>
      <p class="MsoListParagraphCxSpMiddle" style="margin-bottom:8.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:107%"><span style="font-family:Symbol">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
          style="font-family:'Calibri',sans-serif">Workplace transmission of COVID
          infection</span></p>
      <p class="MsoListParagraphCxSpLast" style="margin-bottom:8.0pt;text-align:justify;
  text-indent:-18.0pt;line-height:107%"><span style="font-family:Symbol">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
          style="font-family:'Calibri',sans-serif">Strategies for large scale deployment
          of surveillance during high prevalence/community spread of COVID and other
          respiratory pathogens. </span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">Additionally,
          MHL will be providing guidance to your employer on the necessary steps to take
          if a ‘preliminary positive’ case is identified at your workplace. These steps
          will help ensure the safety of your workplace and those who work there during
          this COVID-19 pandemic. For avoidance of any doubt, only aggregated data may be
          provided to your employer and in no event will any personal or other
          information be provided to your employer, which may identify you or the results
          of your tests. &nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">Who pays for this Study?</span></b></h1>
      <p style="margin:0cm;text-align:justify"><span style="font-family:'Calibri',sans-serif">The
          Study is conducted by MHL, a not-for-profit organization dedicated to COVID-19
          research. The work is performed in partnership with researchers at the Research
          St. Joseph’s – Hamilton</span><span style="font-family:'Calibri',sans-serif">. </span></p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">&nbsp;</p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">This Study has
        been made possible through private funding <span class="A1"><span
            style="font-family:'Calibri',sans-serif;color:windowtext">from your company. &nbsp;</span></span>
      </p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">&nbsp;</p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">Will I be paid?</span></b></h1>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">You will not
        be paid for taking part in this Study.</p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal"><span class="A1"><span
            style="font-family:'Calibri',sans-serif;color:windowtext">&nbsp;</span></span></p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">Costs</span></b></h1>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><span class="A1"><span style="font-size:12.0pt;font-family:'Calibri',sans-serif;
  color:windowtext">There is no cost to you, your private medical insurance (if
            any), or the public health system, (OHIP)</span></span><span class="A1"><span
            style="font-size:12.0pt;font-family:'Calibri',sans-serif;color:#C00000"> </span></span><span
          class="A1"><span style="font-size:12.0pt;font-family:'Calibri',sans-serif;
  color:windowtext">for study participation. The study swabs and questionnaires
            will be provided at no charge for the duration of the study. </span></span></h1>
      <p class="MsoNormal" style="text-align:justify">&nbsp;</p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">How will my safety be
            ensured?</span></b></h1>
      <p class="MsoNormal" style="text-align:justify"><span class="A1"><span
            style="font-family:'Calibri',sans-serif;color:windowtext">If you feel that you
            are from a vulnerable group with respect to COVID-19 effects (for example,
            senior citizens, immuno-compromised), please discuss your participation with
            the research team before consenting. You are under no obligation to participate
            and nothing bad will happen if you change your mind about participating in the
            research.</span></span></p>
      <p class="MsoNormal" style="text-align:justify"><span class="A1"><span
            style="font-family:'Calibri',sans-serif;color:windowtext">&nbsp;</span></span></p>
      <p class="MsoNormal" style="text-align:justify"><span class="A1"><span
            style="font-family:'Calibri',sans-serif;color:windowtext">You do need to follow
            all the requirements of your company guidelines, and local public health
            authorities while taking part in the study.&nbsp; </span></span></p>
      <p class="MsoNormal" style="text-align:justify"><span class="A1"><span
            style="font-family:'Calibri',sans-serif;color:windowtext">&nbsp;</span></span></p>
      <p class="MsoNormal" style="text-align:justify"><span class="A1"><span
            style="font-family:'Calibri',sans-serif;color:windowtext">You are required to wear
            PPE (personal protective equipment) and follow company PPE guidelines at your
            location which includes a medical mask, proper handwashing technique and social
            distancing.&nbsp; This is to be maintained as much as possible.<s> </s></span></span></p>
      <p class="MsoNormal" style="text-align:justify"><span class="A1"><s><span
              style="font-family:'Calibri',sans-serif;color:windowtext"><span
                style="text-decoration:none">&nbsp;</span></span></s></span></p>
      <p class="MsoNormal" style="text-align:justify"><span class="A1"><span
            style="font-family:'Calibri',sans-serif;color:windowtext">Your personal contact
            information that is collected to contact you if you have a sample that tests
            positive is retained in order to follow up with you and/or conduct contact
            tracing if you may have been exposed to COVID-19 in the workplace. This contact
            information will be kept separate from data collected through the research
            study to allow for de-identification of the research data.</span></span></p>
      <p class="MsoNormal" style="text-align:justify"><span class="A1"><span
            style="font-family:'Calibri',sans-serif;color:windowtext">&nbsp;</span></span></p>
      <p class="MsoNormal" style="text-align:justify"><span class="A1"><span
            style="font-family:'Calibri',sans-serif;color:windowtext">You maintain your
            right to withdraw from the study at any time, including your research data (if
            applicable). If you do withdraw, we will continue to maintain your contact
            information and will only give it to public health authorities, if required by law.
            If you wish to withdraw from the study, please tell a member of the study team
            or the Principal Investigator. You may also simply stop collecting your samples
            and completing the questionnaires. </span></span></p>
      <p class="MsoNormal" style="text-align:justify"><span class="A1"><span
            style="font-family:'Calibri',sans-serif;color:windowtext">&nbsp;</span></span></p>
      <p class="MsoNormal" style="text-align:justify"><span class="A1"><span
            style="font-family:'Calibri',sans-serif;color:windowtext">We cannot guarantee
            anonymity as the personal contact information identifies you as a participant.</span></span></p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal"><b>&nbsp;</b></p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">What happens to the
            information collected about me?</span></b></h1>
      <p class="MsoCommentText" style="margin-bottom:0cm;text-align:justify"><span style="font-size:12.0pt">MHL will use
          the information collected about you for
          the purposes of this Study and for scientific research. This includes all
          specimens that are processed in the research lab and the clinical virology lab.
          The information will be stored on the secure Cloud platform on VERTO’s
          server.&nbsp; To protect your privacy, the information will be labeled with a
          number that will not identify you, so your identity will be kept
          confidential.&nbsp;The research team will only access your information for the
          purposes of contacting you to advise of your test results (‘negative’ or ‘preliminary
          positive’). The information from all participants in the Study will be pooled
          and analyzed and if the results of the Study are published your identity will
          be kept confidential. </span></p>
      <p class="MsoCommentText" style="margin-bottom:0cm;text-align:justify"><span style="font-size:12.0pt">&nbsp;</span>
      </p>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif;
  color:black;background:white">All potentially positive specimens and a random
          sample of negative specimens will be anonymized and retained for research on
          test methodology and ongoing quality improvement/test optimization purposes.</span></p>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif;
  background:white">&nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif;
  background:white">&nbsp;</span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <p class="MsoCommentText" style="margin-bottom:0cm;text-align:justify"><span style="font-size:12.0pt">The study team
          will keep your personal information
          (and a list that links you to your Study number) for at least 10 years
          following the completion or termination of the Study, as required by law for
          such research. </span></p>
      <p class="MsoCommentText" style="margin-bottom:0cm;text-align:justify"><span style="font-size:12.0pt">&nbsp;</span>
      </p>
      <p class="MsoCommentText" style="margin-bottom:0cm;text-align:justify"><span style="font-size:12.0pt">The Research
          Ethics Board may access study information
          in order to ensure adherence to the study protocol (study plan) and the
          requirements in this consent form.&nbsp; </span></p>
      <p class="MsoCommentText" style="margin-bottom:0cm;text-align:justify"><span style="font-size:12.0pt">&nbsp;</span>
      </p>
      <p class="MsoCommentText" style="margin-bottom:0cm;text-align:justify"><a name="_Hlk49060834"><span
            style="font-size:12.0pt">If you decide to leave the
            Study at any time you have the option to withdraw and, at your request, we will
            delete all your personal contact information in the stored data.</span></a></p>
      <p class="MsoCommentText" style="margin-bottom:0cm;text-align:justify"><span style="font-size:12.0pt">&nbsp;</span>
      </p>
      <p class="MsoCommentText" style="margin-bottom:0cm;text-align:justify"><span style="font-size:12.0pt">By signing and
          dating this document, you will not lose
          any of your legal rights or release anyone involved in the research from
          responsibility for mistakes. By signing and dating this form, you are agreeing
          to the content of this informed consent and permitting the use of your
          information for the purposes of the Study and future research and development.</span></p>
      <p class="MsoCommentText" style="margin-bottom:0cm;text-align:justify"><span style="font-size:12.0pt">&nbsp;</span>
      </p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">Can I change my mind?</span></b></h1>
      <p class="MsoBodyText" style="margin-left:0cm;text-align:justify">Your
        participation in this Study is completely voluntary.&nbsp;You do not have to
        take part in this Study.&nbsp; You can agree to be in the Study now and change
        your mind later.&nbsp;You may stop your participation at any time.&nbsp;If you
        wish to stop participating, you may notify the study team, or you may simply
        decide not to collect your next swab sample. If you do not notify the study
        team that you no longer wish to participate, you will continue to receive
        reminders to collect your specimen. You completing these tasks is completely
        voluntary. If you want to stop receiving reminders, you will need to contact
        the study team.&nbsp; </p>
      <p class="MsoBodyText" style="margin-left:0cm;text-align:justify">&nbsp;</p>
      <p class="MsoBodyText" style="margin-left:0cm;text-align:justify"><span style="color:black">Your participation in
          this study is completely voluntary. </span><span lang="EN-US" style="color:black;background:white">Regardless of
          your decision to
          participate, you will still need to review your employer's COVID-19 safety
          policy to assess whether you meet your employer’s requirements to work on-site
          at their physical premises</span><span lang="EN-US" style="font-family:'Segoe UI',sans-serif;
  color:black;background:white">.</span></p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">&nbsp;</p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">Can I be removed from
            the Study?</span></b></h1>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">MHL has the
        right to remove you from the study at any time with or without your agreement
        if:&nbsp; </p>
      <p class="Bullet12-1" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
  margin-left:39.6pt"><span style="font-family:Symbol">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span style="font-family:'Calibri',sans-serif">The Study is
          cancelled; or</span></p>
      <p class="Bullet12-1" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
  margin-left:39.6pt"><span style="font-family:Symbol">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><span style="font-family:'Calibri',sans-serif">The study team believes
          that it is in your best interest for any reason.</span></p>
      <p class="Bullet12-1" style="margin:0cm;text-indent:0cm"><span style="font-family:
  'Calibri',sans-serif">&nbsp;</span></p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">What happens after
            the Study is over or if I stop the Study early?</span></b></h1>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">If you decide
        to stop the Study early, we ask that you let the study team know via the
        contact number below. </p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">&nbsp;</p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">Over the
        course of the study and after completion, the study researchers at MHL will be
        looking at the data to evaluate the methods of workplace surveillance.</p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">&nbsp;</p>
      <p class="BodyText12" style="margin-bottom:0cm;line-height:normal">The study
        investigators will prepare reports for publication in peer reviewed journals
        and will post the overall findings on the MHL website, where you will be able
        to learn about the results.&nbsp; </p>
      <p class="MsoNormal" style="text-align:justify"><b><span
            style="font-family:'Calibri',sans-serif">&nbsp;</span></b></p>
      <p class="MsoNormal" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">The
          Study will provide you with a convenient and timely test of your COVID-19
          infection status (‘negative’ or ‘preliminary positive’), monitored (through
          repeated testing) throughout your participation. </span></p>
      <p class="MsoNormal" style="text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="font-family:'Calibri',sans-serif;color:windowtext">Whom to contact about
            this </span></b><b><span style="font-family:'Calibri',sans-serif;color:windowtext">study</span></b>
      </h1>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify"><span
          style="font-family:'Calibri',sans-serif;font-weight:normal">If you have any
          questions at any time during the Study, please contact:</span></p>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify"><span
          style="font-family:'Calibri',sans-serif;font-weight:normal">&nbsp;</span></p>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify"><span
          style="font-family:'Calibri',sans-serif;font-weight:normal">Study HelpDesk</span></p>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify;page-break-after:
  auto"><span style="font-family:'Calibri',sans-serif;font-weight:normal">Research
          Team</span></p>
      <p class="SubheadingBold12" style="margin:0cm;page-break-after:auto"><a
          href="mailto:pgill@mcmasterhealthlabs.ca"><span style="font-family:'Calibri',sans-serif;
  color:windowtext;font-weight:normal">pgill@mcmasterhealthlabs.ca</span></a></p>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify;page-break-after:
  auto"><span style="font-family:'Calibri',sans-serif;font-weight:normal">416-443-3396</span></p>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify;page-break-after:
  auto"><span style="font-family:'Calibri',sans-serif;font-weight:normal">&nbsp;</span></p>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify;page-break-after:
  auto"><span style="font-family:'Calibri',sans-serif;font-weight:normal">Study
          Coordinator</span></p>
      <p class="SubheadingBold12" style="margin:0cm;page-break-after:auto"><span
          style="font-family:'Calibri',sans-serif;font-weight:normal">Pardip Gill</span></p>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify;page-break-after:
  auto"><a href="mailto:pgill@mcmasterhealthlabs.ca"><span style="font-family:
  'Calibri',sans-serif;color:windowtext;font-weight:normal">pgill@mcmasterhealthlabs.ca</span></a></p>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify;page-break-after:
  auto"><span style="font-family:'Calibri',sans-serif;font-weight:normal">1-833-292-5856</span></p>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify;page-break-after:
  auto"><span style="font-family:'Calibri',sans-serif;font-weight:normal">&nbsp;</span></p>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify;page-break-after:
  auto"><a name="_Hlk13045236"><span style="font-family:'Calibri',sans-serif;
  font-weight:normal">A research ethics board (REB) is an independent committee
            established to help protect the rights of research participants.&nbsp; If you
            have any questions about your rights as a research participant, and/or concerns
            or complaints regarding this research study, contact:</span></a></p>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify;page-break-after:
  auto"><span style="font-family:'Calibri',sans-serif;font-weight:normal">&nbsp;</span></p>
      <p class="SubheadingBold12" style="margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;page-break-after:
  auto"><span style="font-family:Symbol;font-weight:normal">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
          style="font-family:'Calibri',sans-serif;font-weight:normal">By mail:</span></p>
      <p class="SubheadingBold12" style="margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:72.0pt;text-align:justify;page-break-after:auto"><span
          style="font-family:'Calibri',sans-serif;font-weight:normal">Study Subject
          Adviser</span></p>
      <p class="SubheadingBold12" style="margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:72.0pt;text-align:justify;page-break-after:auto"><span
          style="font-family:'Calibri',sans-serif;font-weight:normal">Advarra IRB</span></p>
      <p class="SubheadingBold12" style="margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:72.0pt;text-align:justify;page-break-after:auto"><span
          style="font-family:'Calibri',sans-serif;font-weight:normal">6940 Columbia
          Gateway Drive, Suite 110</span></p>
      <p class="SubheadingBold12" style="margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:72.0pt;text-align:justify;page-break-after:auto"><span
          style="font-family:'Calibri',sans-serif;font-weight:normal">Columbia, MD 21046</span></p>
      <p class="SubheadingBold12" style="margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;page-break-after:
  auto"><span style="font-family:Symbol;font-weight:normal">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
          style="font-family:'Calibri',sans-serif;font-weight:normal">or call <u>toll
            free</u>: 877-992-4724</span></p>
      <p class="SubheadingBold12" style="margin-top:0cm;margin-right:0cm;margin-bottom:
  0cm;margin-left:36.0pt;text-align:justify;text-indent:-18.0pt;page-break-after:
  auto"><span style="font-family:Symbol;font-weight:normal">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
          style="font-family:'Calibri',sans-serif;font-weight:normal">or by <u>email</u>:
        </span><a href="mailto:adviser@advarra.com"><span style="font-family:'Calibri',sans-serif;
  color:windowtext;font-weight:normal">adviser@advarra.com</span></a></p>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify;page-break-after:
  auto"><span style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><span
          style="font-size:12.0pt;font-family:'Calibri',sans-serif">Please reference the
          following number when contacting the Study Subject Adviser: </span><b><span
            style="font-size:12.0pt;font-family:'Calibri',sans-serif">Pro00049441</span></b></p>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify;page-break-after:
  auto">&nbsp;</p>
      <h1 style="margin-top:0cm;text-align:justify;line-height:normal"><b><span
            style="color:windowtext">CONSENT</span></b><b><span style="color:windowtext">
            &amp; ACKNOWLEDGMENT</span></b></h1>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify"><span
          style="font-family:'Calibri',sans-serif">If you consent to participating in the
          Study, please read and then sign and date below.</span></p>
      <p class="ICFBodyText" style="margin-bottom:0cm;text-align:justify"><span
          style="font-family:'Calibri',sans-serif">As noted above, this consent form
          contains important information.&nbsp; It will help you decide if you want to take
          part in this <a name="_Hlk49061562">Study.&nbsp;You will have the option of
            downloading a copy of this signed and dated Participant Information and
            Informed Consent Form from www.mhltest.com</a></span><b><span style="font-size:
  12.0pt;font-family:'Calibri',sans-serif;color:#2E74B5"> </span></b><span
          style="font-family:'Calibri',sans-serif">at any time after you complete it. If </span><span
          style="font-family:'Calibri',sans-serif">you still have questions, please ask trained
          site staff or contact the Study HelpDesk <u>before</u> signing and dating this
          form. Otherwise, if you would like to participate in this study, please check
          all boxes below and sign and date where indicated.<a name="Informing_Healthcare_Provider_Consent"></a></span>
      </p>
    </div>
    <span style="font-size:11.0pt;font-family:'Calibri',sans-serif"><br clear="all"
        style="page-break-before:always">
    </span>
    <div class="WordSection2">
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify"><a name="_Hlk49061674"><span
            style="font-family:'Calibri',sans-serif">NOTE:&nbsp; Consent
            will be electronic and the following will be in checkboxes:</span></a></p>
      <p class="SubheadingBold12" style="margin:0cm;text-align:justify"><span
          style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
      <p class="Bullet12-1" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
  margin-left:39.6pt"><span style="font-family:Symbol;font-variant:small-caps">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><b><span style="font-family:'Calibri',sans-serif;font-variant:
  small-caps">I have read and understood all the pages of this information and
            consent form. </span></b></p>
      <p class="Bullet12-1" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
  margin-left:39.6pt"><span style="font-family:Symbol;font-variant:small-caps">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><b><span style="font-family:'Calibri',sans-serif;font-variant:
  small-caps">I acknowledge and confirm that:</span></b></p>
      <p class="Bullet12-1" style="margin-left:57.6pt"><span style="font-family:Symbol;
  font-variant:small-caps">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><b><span style="font-family:'Calibri',sans-serif;font-variant:
  small-caps">The testing conducted for the purposes of the study is not a diagnostic
            test and a “preliminary positive” result is not considered a positive diagnosis
            for covid-19; </span></b></p>
      <p class="Bullet12-1" style="margin-left:57.6pt"><span style="font-family:Symbol;
  font-variant:small-caps">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><b><span style="font-family:'Calibri',sans-serif;font-variant:
  small-caps">In the event of being notified of a “preliminary positive” result, my
            personal information will be shared with the Hamilton Regional Laboratory
            Medicine Program (HRLMP) for confirmatory testing. &nbsp;Until the results of that
            testing are confirmed, i will be advised to follow public health guidelines for
            persons who are presumed positive for covid-19; and</span></b></p>
      <p class="Bullet12-1" style="margin-left:57.6pt"><span style="font-family:Symbol;
  font-variant:small-caps">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><b><span style="font-family:'Calibri',sans-serif;font-variant:
  small-caps">It is my duty and obligation to comply with all applicable laws,
            regulations and public health guidelines at all times regardless of the study
            test results that are communicated to me.</span></b></p>
      <p class="Bullet12-1" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
  margin-left:39.6pt"><span style="font-family:Symbol;font-variant:small-caps">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><b><span style="font-family:'Calibri',sans-serif;font-variant:
  small-caps">This consent</span></b><b><span style="font-family:'Calibri',sans-serif;
  font-variant:small-caps"> has been written in a language that </span></b><b><span
            style="font-family:'Calibri',sans-serif;font-variant:small-caps">i</span></b><b><span
            style="font-family:'Calibri',sans-serif;font-variant:small-caps"> can read and
            understand.&nbsp; </span></b></p>
      <p class="Bullet12-1" style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;
  margin-left:39.6pt"><span style="font-family:Symbol;font-variant:small-caps">·<span
            style="font:7.0pt 'Times New Roman'">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span></span><b><span style="font-family:'Calibri',sans-serif;font-variant:
  small-caps">Based on this information, </span></b><b><span style="font-family:
  'Calibri',sans-serif;font-variant:small-caps">i</span></b><b><span
            style="font-family:'Calibri',sans-serif;font-variant:small-caps"> volunteer to
            take part in this </span></b><b><span style="font-family:'Calibri',sans-serif;
  font-variant:small-caps">study</span></b><b><span style="font-family:'Calibri',sans-serif;
  font-variant:small-caps">.</span></b></p>
      <p class="Basic12" style="text-align:justify"><b><span
            style="font-family:'Calibri',sans-serif">&nbsp;</span></b></p>
      <p class="Basic12" style="text-align:justify"><span style="font-family:'Calibri',sans-serif">&nbsp;</span>
      </p>
      <table class="MsoNormalTable" border="0" cellspacing="0" cellpadding="0" style="border-collapse:collapse">
        <tbody>
          <tr>
            <td width="325" valign="top" style="width:243.9pt;border:none;border-bottom:solid windowtext 1.0pt;
    padding:0cm 5.4pt 0cm 5.4pt">
              <p class="Basic12" style="text-align:justify;page-break-after:avoid"><span
                  style="font-family:'Calibri',sans-serif">${[
                    thisArg.patient.first_name,
                    thisArg.patient.last_name,
                  ]
                    .filter(Boolean)
                    .join(' ')}&nbsp;</span></p>
            </td>
            <td width="174" valign="top" style="width:130.5pt;padding:0cm 5.4pt 0cm 5.4pt">
              <p class="Basic12" style="text-align:justify;page-break-after:avoid"><span
                  style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
            </td>
          </tr>
          <tr>
            <td width="325" valign="top" style="width:243.9pt;padding:0cm 5.4pt 0cm 5.4pt">
              <p class="Basic12" style="text-align:justify;page-break-after:avoid"><span
                  style="font-family:'Calibri',sans-serif">Full Legal Name of Participant</span></p>
              <p class="Basic12" style="text-align:justify;page-break-after:avoid; margin-bottom: -10px;"><span
                    style="font-family:'Calibri',sans-serif"><span
                      style="text-decoration:none">${[
                        thisArg.patient.first_name,
                        thisArg.patient.last_name,
                      ]
                        .filter(Boolean)
                        .join(' ')}&nbsp;</span></span></p>
              <p class="Basic12" style="text-align:justify;page-break-after:avoid"><u><span
                    style="font-family:'Calibri',sans-serif">________________________________________________________</span></u>
              </p>
              <p class="Basic12" style="text-align:justify;page-break-after:avoid"><span
                  style="font-family:'Calibri',sans-serif">The name you normally use as well</span></p>
            </td>
            <td width="174" valign="top" style="width:130.5pt;padding:0cm 5.4pt 0cm 5.4pt">
              <p class="Basic12" style="text-align:justify;page-break-after:avoid"><span
                  style="font-family:'Calibri',sans-serif">&nbsp;</span></p>
            </td>
          </tr>
          <tr>
            <td width="325" valign="top" style="width:243.9pt;border:none;border-bottom:solid windowtext 1.0pt;
    padding:0cm 5.4pt 0cm 5.4pt">
              <p class="Basic12" style="text-align:justify;page-break-after:avoid"><span
                  style="font-family:'Calibri',sans-serif">${thisArg._getSignature()}&nbsp;</span></p>
            </td>
            <td width="174" valign="top" style="width:130.5pt;border:none;border-bottom:solid windowtext 1.0pt;
    padding:0cm 5.4pt 0cm 5.4pt">
              <p class="Basic12" style="text-align:justify;page-break-after:avoid"><span
                  style="font-family:'Calibri',sans-serif">${thisArg.datePipe.transform(
                    thisArg.patient.created_at,
                    'yyyy-MM-dd'
                  )}&nbsp;</span></p>
            </td>
          </tr>
          <tr>
            <td width="325" valign="top" style="width:243.9pt;padding:0cm 5.4pt 0cm 5.4pt">
              <p class="Basic12" style="text-align:justify;page-break-after:avoid"><span
                  style="font-family:'Calibri',sans-serif">Signature of Participant</span></p>
            </td>
            <td width="174" valign="top" style="width:130.5pt;padding:0cm 5.4pt 0cm 5.4pt">
              <p class="Basic12" style="text-align:justify;page-break-after:avoid"><span
                  style="font-family:'Calibri',sans-serif">Date (yyyy-mm-dd)</span></p>
            </td>
          </tr>
        </tbody>
      </table>
  </body>
  </html>`;
};
