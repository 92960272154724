/* Copyright 2023 (Unpublished) Verto Inc. */

import { MoodRendererUnitType } from 'engage-common';
import { MoodState } from 'projects/engage-common/src/lib/mood/mood-state';

export const mhlMccovidLandingPageState = ({ translationService, router }): MoodState => {
  return {
    rendererFrame: [
      {
        type: MoodRendererUnitType.HEADER,
        label: () =>
          translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'landing',
            'header',
          ]),
      },
      {
        type: MoodRendererUnitType.ACCORDION,
        expanded: true,
        header: () =>
          translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'landing',
            'register',
            'header',
          ]),
        content: [
          {
            type: MoodRendererUnitType.BUTTON,
            buttonClasses: ['mood-primary', 'w-100'],
            text: () =>
              translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'landing',
                'register',
                'content',
              ]),
            click: () => router.navigate(['informed-consent-form']),
          },
        ],
        margin: ['0', '0', '1rem', '0'],
      },
      {
        type: MoodRendererUnitType.ACCORDION,
        expanded: true,
        header: () =>
          translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'landing',
            'registered',
            'header',
          ]),
        content: [
          {
            type: MoodRendererUnitType.PARAGRAPH,
            html: () =>
              translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'landing',
                'registered',
                'content',
              ])[0],
            margin: ['0', '0', '1rem', '0'],
          },
          {
            type: MoodRendererUnitType.BUTTON,
            buttonClasses: ['mood-primary', 'w-100'],
            text: () =>
              translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'landing',
                'registered',
                'content',
              ])[1],
            click: () => router.navigate(['sign-in']),
          },
        ],
      },
    ],
  };
};
