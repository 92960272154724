/* Copyright 2023 (Unpublished) Verto Inc. */

import { AnswerQuestionnaireComponent } from '../answer-questionnaire/answer-questionnaire.component';
import { QuestionnairesComponent } from '../questionnaires/questionnaires.component';
import { TokenGuard } from '../services/token.guard';

export const questionnaireRoutes = [
  {
    path: 'questionnaires',
    component: QuestionnairesComponent,
    canActivate: [TokenGuard],
  },
  {
    path: 'answer-questionnaire',
    component: AnswerQuestionnaireComponent,
    canActivate: [TokenGuard],
  },
];
