/* Copyright 2023 (Unpublished) Verto Inc. */

import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from './translation.service';

// https://stackoverflow.com/a/45624434
@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private _translateService: TranslationService) {}

  transform(value: any, pattern: string = 'mediumDate'): any {
    const datePipe: DatePipe = new DatePipe(this._translateService.currentLanguage);
    return datePipe.transform(value, pattern);
  }
}
