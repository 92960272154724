/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MoodRendererUnitType, MoodState } from 'engage-common';
import { ChlFooterService } from '../services/chl-footer.service';
import { TranslationService } from '../services/translation.service';

@Component({
  selector: 'app-registered-with-verto',
  templateUrl: './brown-labs.component.html',
})
export class RegisteredWithVertoComponent implements OnInit {
  state: MoodState;
  registeredWithVerto = '';
  nextDisabled: boolean = true;

  constructor(
    private _translationService: TranslationService,
    private _footerService: ChlFooterService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.state = this._getState();
    this._footerService.visible.next(true);
    this._renderbuttons();
  }

  private _renderbuttons(): void {
    this._footerService.buttons.next([
      {
        buttons: [
          {
            text: () => this._translationService.translate(['buttons', 'back']),
            click: () => this._router.navigate(['landing']),
            buttonClasses: ['mood-default'],
          },
          {
            text: () => this._translationService.translate(['buttons', 'next']),
            click: () => {
              // if user is already registered send them to login
              if (this.registeredWithVerto === '0') {
                this._router.navigate(['sign-in']);
                return;
              }
              this._router.navigate(['registration-introduction']);
            },
            buttonClasses: ['mood-primary'],
            disabled: this.nextDisabled,
          },
        ],
      },
    ]);
  }

  private _getState(): MoodState {
    const translate = (...path: string[]) => {
      return this._translationService.translate([
        'customization',
        'brown-labs',
        'pages',
        'register-with-verto',
        ...path,
      ]);
    };

    return {
      rendererFrame: [
        {
          type: MoodRendererUnitType.HEADER,
          label: translate('header'),
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          header: translate('study-overview', 'header'),
          expanded: true,
          content: [
            {
              type: MoodRendererUnitType.BUTTON,
              text: 'Log In',
              click: () => this._router.navigate(['sign-in']),
              buttonClasses: ['mood-primary', 'w-25'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: translate('study-overview', 'content')[0],
              margin: ['0', '0', '20px', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: translate('study-overview', 'content')[1],
              margin: ['0', '0', '20px', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: translate('study-overview', 'content')[2],
              margin: ['0', '0', '20px', '0'],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          header: translate('about-verto', 'header'),
          expanded: true,
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: translate('about-verto', 'content')[0],
              margin: ['0', '0', '20px', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: translate('about-verto', 'content')[1],
              margin: ['0', '0', '20px', '0'],
            },
          ],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: translate('are-you-registered'),
        },
        {
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row', 'w-50'],
          change: ({ checked, radioButton }) => {
            this.nextDisabled = !checked;
            this._renderbuttons();
            this.registeredWithVerto = radioButton.value;
          },
          formData: {
            controlName: 'registered-with-verto',
            controlType: 'checkbox',
            options: [
              {
                label: this._translationService.translate(['options', 'yes']),
                value: '0',
              },
              {
                label: this._translationService.translate(['options', 'no']),
                value: '1',
              },
              {
                label: this._translationService.translate(['options', 'dontKnow']),
                value: '2',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
        },
      ],
    };
  }
}
