/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MoodRendererComponent, MoodRendererUnitType, MoodRenderFrame } from 'engage-common';
import { ChlFooterService } from '../services/chl-footer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EngageSerializableStoreService } from 'engage-utils';
import { ChlPatientService } from '../services/chl-patient.service';
import { TranslationService } from '../services/translation.service';
import { MessageService } from 'primeng/api';
import { MoodCaptchaComponent } from '../../../../engage-common/src/lib/mood/mood-captcha/mood-captcha.component';

@Component({
  selector: 'app-signup-confirm',
  templateUrl: './user-preferences-confirm.component.html',
  styleUrls: ['./user-preferences-confirm.component.scss'],
})
export class UserPreferencesConfirmComponent {
  VERIFICATION_KEY = 'verification';

  constructor(
    private _translationService: TranslationService,
    private _footerService: ChlFooterService,
    private _router: Router,
    private _store: EngageSerializableStoreService,
    private _patientService: ChlPatientService,
    private _primeNgMessageService: MessageService,
    private _activatedRoute: ActivatedRoute
  ) {}

  onUpdate(updateValues): void {
    this._store.store(this.VERIFICATION_KEY, updateValues);
  }

  private _submit(): void {
    const { code } = this._store.retrieve(this.VERIFICATION_KEY);

    const success = () => {
      this._router.navigate(['user-preferences']);
    };

    const error = () => {
      const errorMessages = [];

      if (errorMessages.length === 0) {
        errorMessages.push(
          this._translationService.translate([
            'pages',
            'user-preferences-confirm',
            'genericValidationError',
          ])
        );
      }

      errorMessages.forEach((message) => {
        this._primeNgMessageService.add({
          severity: 'error',
          summary: '',
          detail: message,
        });
      });
    };

    this._patientService.verifyContact({ code }).subscribe(success, error);
  }

  rendererFrame: MoodRenderFrame = [
    {
      type: MoodRendererUnitType.HEADER,
      label: () =>
        this._translationService.translate(['pages', 'user-preferences-confirm', 'header']),
      margin: ['0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () =>
        this._translationService.translate([
          'pages',
          'user-preferences-confirm',
          'confirmationParagraph',
        ]),
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.INPUT,
      label: () =>
        this._translationService.translate([
          'pages',
          'user-preferences-confirm',
          'accessCode',
          'label',
        ]),
      formData: {
        placeholder: () =>
          this._translationService.translate([
            'pages',
            'sign-up-confirm',
            'accessCode',
            'placeholder',
          ]),
        controlName: 'code',
        valueType: 'text',
        validators: {
          required: true,
        },
      },
    },
    {
      type: MoodRendererUnitType.BUTTON,
      text: this._translationService.translate([
        'pages',
        'user-preferences-confirm',
        'submitButton',
      ]),
      buttonClasses: ['mood-primary'],
      click: () => this._submit(),
      margin: ['0', '0', '1rem', '0'],
    },
  ];
}
