/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, ViewChild, OnInit } from '@angular/core';
import { ChlPatientService } from '../../services/chl-patient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EngageSerializableStoreService } from 'engage-utils';
import { MoodRendererComponent, MoodRendererUnitType, MoodRenderFrame } from 'engage-common';
import { MessageService } from 'primeng/api';
import { ConfigurationService } from '../../services/configuration.service';
import { ChlHeaderService } from '../../services/chl-header.service';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'add-new-kit',
  templateUrl: './add-new-kit.component.html',
  styleUrls: ['./add-new-kit.component.scss'],
})
export class AddNewKitComponent implements OnInit {
  textButton = {
    buttonClasses: ['mood-primary'],
    disabled: false,
    text: {
      label: this.translationService.translate(['buttons', 'submit']),
      style: {
        'font-size': '15px',
        padding: '0 10px 0 15px',
      },
    },
    spinner: {
      styles: { width: '45px', height: '45px' },
      light: true,
    },
    click: () => this.addNewKitId(),
  };

  errorFrame: MoodRenderFrame = [
    {
      type: MoodRendererUnitType.HEADER,
      label: () => this.translationService.translate(['pages', 'addNewKit', 'issueHeader']),
      margin: ['0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () => this.translationService.translate(['pages', 'addNewKit', 'patientNotFound']),
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.BUTTON,
      buttonClasses: ['mood-primary', 'w-10'],
      text: () => this.translationService.translate(['buttons', 'back']),
      click: () => this._router.navigate(['test-kits']),
    },
  ];

  @ViewChild(MoodRendererComponent) renderer: MoodRendererComponent;

  errorAddingKit = false;
  addKitErrorCode: string;

  buttonFrame: MoodRenderFrame;
  newTestKit = {
    length: 12,
    mask: 'aaa-aaa-aaa-aaa',
    unmask: false,
  };

  code: string;
  selected_patient: {
    id?: number;
    first_name?: string;
    last_name?: string;
  } = {};
  pageError: boolean = false;
  loaded: boolean = false;
  nextPage: string = 'questionnaires';

  constructor(
    private _store: EngageSerializableStoreService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _patientService: ChlPatientService,
    private _primeNgMessageService: MessageService,
    private _headerService: ChlHeaderService,
    private _configurationService: ConfigurationService,
    public translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      const id = params.i;

      if (id) {
        this.selected_patient.id = id;

        this._patientService.retrieveDependent(id).subscribe(
          (res) => {
            if (res) {
              this.selected_patient.first_name = res.first_name;
              this.selected_patient.last_name = res.last_name;
            } else {
              this.pageError = true;
            }
            this.loaded = true;
          },
          () => {
            this.pageError = true;
            this.loaded = true;
          }
        );

        this._configurationService.getConfig().subscribe((app) => {
          // Set next page after kit registration - defaults to questionnaires for backwards compatibility
          // with Birch Hill implementation
          this.nextPage = app.config.content.after_kit_registration_route || 'questionnaires';
          if (app.config.content.new_test_kit) {
            this.newTestKit = {
              ...this.newTestKit,
              ...app.config.content.new_test_kit,
            };
          }
          this.setButtonFrame();
        });
      }
    });
  }

  addNewKitId(): void {
    if (!this.code) {
      this._primeNgMessageService.add({
        severity: 'error',
        summary: '',
        detail: this.translationService.translate(['pages', 'addNewKit', 'noCodeProvided']),
      });
      return;
    }
    this.textButton.disabled = true;
    this.errorAddingKit = false;
    this._patientService.registerKit(this.code.toUpperCase(), this.selected_patient.id).subscribe(
      (res) => {
        this.textButton.disabled = false;
        this._primeNgMessageService.add({
          severity: 'success',
          summary: '',
          detail: this.translationService.translate(['pages', 'addNewKit', 'testSuccessRegister']),
        });
        this._router.navigate([this.nextPage]);

        // todo(aleksanderbodurri): determine based on response how many kits exist, if only 1 exists then we just created that one kit, mark it as administered per the first registration workflow

        // this._patientService.markKitAsAdministered(res.kits[0]).subscribe(
        //   () => {
        //     this._router.navigate(['test-kits-overview']);
        //   },
        //   () => {
        //     this.textButton.disabled = false;
        //     this._primeNgMessageService.add({
        //       severity: 'error',
        //       summary: '',
        //       detail: 'Could not register the Kit',
        //     });
        //   }
        // );
      },
      (er) => {
        this.textButton.disabled = false;
        this.errorAddingKit = true;

        let description = er.error.error_code;
        let errorMesssages = {
          unlinked: 'testKitNotLinkError',
          'resource-not-found': 'testKitInvalidError',
          'already-registered': 'testKitLinkedError',
        };

        if (errorMesssages.hasOwnProperty(description)) {
          this.addKitErrorCode = errorMesssages[description];
        } else if (er.status === 422) {
          this.addKitErrorCode = 'testKitInvalidError';
        } else if (er.status === 403) {
          this.addKitErrorCode = 'testKitVerifiedContactError';
        } else {
          this.addKitErrorCode = 'testKitAddError';
        }
      }
    );
  }

  getHeader(): string {
    let header = this.translationService.translate(['pages', 'addNewKit', 'header']);
    if (this.selected_patient.first_name)
      header += ` - ${this.selected_patient.first_name} ${this.selected_patient.last_name}`;

    return header;
  }

  updateForm(updatedValues) {
    this.code = updatedValues.testKitId;
  }

  setButtonFrame(): void {
    this.buttonFrame = [
      {
        type: MoodRendererUnitType.PARAGRAPH,
        margin: ['0'],
        hidden: () => !this.errorAddingKit,
        html: () =>
          `<span class="text-danger">${this.translationService.translate([
            'pages',
            'addNewKit',
            this.addKitErrorCode || 'testKitAddError',
          ])}</span>`,
      },
      {
        type: MoodRendererUnitType.INPUT,
        margin: ['0'],
        label: this.translationService.translate(['pages', 'addNewKit', 'testKitId']),
        formData: {
          placeholder: this.translationService.translate([
            'pages',
            'addNewKit',
            'testKitIdPlaceholder',
          ]),
          controlName: 'testKitId',
          controlType: 'text',
          valueType: 'text',
          mask: this.newTestKit.mask,
          unmaskInput: this.newTestKit.unmask,
          validators: {
            required: true,
          },
        },
      },
    ];
  }
}
