/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component } from '@angular/core';

@Component({
  selector: 'mood-screen-header',
  templateUrl: './mood-screen-header.component.html',
  styleUrls: ['./mood-screen-header.component.scss'],
})
export class MoodScreenHeaderComponent {}
