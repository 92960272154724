/* Copyright 2023 (Unpublished) Verto Inc. */

import { Injectable } from '@angular/core';

import { ConfigurationService } from './configuration.service';

import * as en from '../locale/en.json';
import * as fr from '../locale/fr.json';
import { ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private translations: any;
  currentLanguage: 'en' | 'fr';

  en = (en as any).default;
  fr = (fr as any).default;

  langObservable = new ReplaySubject<'en' | 'fr'>(1);
  customization = environment.client;

  constructor(private _configurationService: ConfigurationService) {
    this.langObservable.subscribe((lang) => {
      this.load(lang);
    });

    this._configurationService.getConfig().subscribe((res) => {
      if (res.config.client) {
        this.customization = res.config.client;
      }
    });
  }

  load(lang: 'en' | 'fr' = 'en'): void {
    this.currentLanguage = lang;

    if (lang === 'en') {
      this.translations = this.en;
    } else if (lang === 'fr') {
      this.translations = this.fr;
    }
  }

  findTranslation(obj: any, keypath: string[]): any {
    for (let i = 0; i < keypath.length; i++) {
      const k = keypath[i];
      if (k in obj) {
        obj = obj[k];
      } else {
        return undefined;
      }
    }
    return obj;
  }

  translate(keypath: string[]): any {
    // Load en by default
    if (!this.translations) {
      this.load();
    }
    let obj: any;

    if (this.customization && keypath[0] !== 'customization') {
      obj = this.findTranslation(
        this.translations,
        ['customization', this.customization].concat(keypath)
      );

      // Fallback to environment client if translation not found for application client
      if (obj === undefined) {
        obj = this.findTranslation(
          this.translations,
          ['customization', environment.client].concat(keypath)
        );
      }
    }

    if (obj === undefined) {
      obj = this.findTranslation(this.translations, keypath);
    }
    if (obj === undefined) {
      console.warn(`Translation not found for ${keypath}`);
    }
    return obj;
  }
}
