<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div [ngClass]="{ 'mhl-branding-enabled': chlBrandingMode }" class="mhl-footer">
  <mood-renderer
    *ngIf="!chlBrandingMode; else chlBranding"
    [moodFrame]="buttonRenderFrame"
    [wrapperClasses]="[]"
  ></mood-renderer>

  <ng-template #chlBranding>
    <div class="mhl-footer-branding">
      <img src="assets/images/mhl-footer-theme.svg" />
    </div>
  </ng-template>
</div>
