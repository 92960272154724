<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<p-progressBar
  [showValue]="false"
  [value]="((page + 1) * 100) / (pageFrames.length || 1)"
  styleClass="questionnaire-progress"
></p-progressBar>

<div class="answer-questionnaire" *ngIf="questionnaire; else spinner">
  <div class="answer-questionnaire-body">
    <mood-renderer
      [moodFrame]="pageFrames[page]"
      [wrapperClasses]="['row']"
      (formUpdated)="onUpdate($event)"
    ></mood-renderer>
  </div>
</div>

<ng-template #spinner>
  <mood-spinner></mood-spinner>
</ng-template>
