/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-link-sent',
  templateUrl: './link-sent.component.html',
  styleUrls: ['./link-sent.component.scss'],
})
export class LinkSentComponent {
  @Input() header: string;
  @Input() confirmation: string;
  @Input() issue: string;
}
