/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

// Engage Utilities
import { MoodRendererUnitType, MoodRenderFrame, MoodRendererComponent } from 'engage-common';
import { EngageSerializableStoreService } from 'engage-utils';

// Services
import { TranslationService } from '../services/translation.service';
import { ConfigurationService } from '../services/configuration.service';
import { ChlFooterService } from '../services/chl-footer.service';
import { CONSENT_KEY } from '../services/chl-patient.service';

import { CustomInformedConsentForm } from './overrides/custom-informed-consent-forms.enum';
@Component({
  selector: 'app-informed-consent-form',
  templateUrl: './informed-consent-form.component.html',
  styleUrls: ['./informed-consent-form.component.scss'],
})
export class InformedConsentFormComponent implements OnInit, OnDestroy, AfterViewInit {
  consentFormOverride: CustomInformedConsentForm = CustomInformedConsentForm.default;
  consentForm = CustomInformedConsentForm;

  prevUrl: string;
  nextUrl: string;
  ANSWERS = ['consent1', 'consent2', 'consent3'];
  SIGNATURE = ['signature', 'date'];
  nextDisabled = true;

  initialButtons = [
    {
      text: () => this.translationService.translate(['buttons', 'back']),
      click: () => this._return(),
      buttonClasses: ['mood-default'],
    },
    {
      text: () => this.translationService.translate(['buttons', 'continue']),
      disabled: () => this.nextDisabled,
      click: () => this._next(),
      buttonClasses: ['mood-primary'],
    },
  ];

  @ViewChild(MoodRendererComponent) renderer: MoodRendererComponent;

  rendererForms: MoodRendererComponent[];

  ngAfterViewInit() {
    setTimeout(() => {
      const current = this.renderer?.children?.last;
      current?.form.patchValue({
        date: this.currentDate(),
      });
    });
  }

  currentDate() {
    const dp = new DatePipe('en');
    return dp.transform(new Date(), 'yyyy/MM/dd');
  }

  rendererFrame: MoodRenderFrame = [];

  constructor(
    public translationService: TranslationService,
    private _configurationService: ConfigurationService,
    private _store: EngageSerializableStoreService,
    private _router: Router,
    private _footerService: ChlFooterService
  ) {}

  private _next() {
    this._router.navigate([this.nextUrl]);
  }

  private _return() {
    this._router.navigate([this.prevUrl]);
  }

  private _showButtons(): void {
    this._footerService.buttons.next([
      {
        buttons: this.initialButtons,
      },
    ]);
  }

  ngOnInit(): void {
    this._configurationService.getConfig().subscribe((app) => {
      const signup_preamble = app.config.content.signup_preamble;
      const index = signup_preamble.indexOf('informed-consent-form');
      if (index === 0) {
        this.prevUrl = 'landing';
        this.nextUrl = signup_preamble[index + 1];
      } else {
        this.prevUrl = signup_preamble[index - 1];
        this.nextUrl = signup_preamble[index + 1];
      }
      this.consentFormOverride = app.config.content.informed_consent_form_override
        ? CustomInformedConsentForm[app.config.content.informed_consent_form_override]
        : CustomInformedConsentForm.default;
      this.buildRenderFrame();
    });
    this._footerService.visible.next(true);
    this._showButtons();
  }

  ngOnDestroy(): void {
    this._footerService.visible.next(false);
  }

  checkForm(updateValues) {
    this.nextDisabled = !!(
      this.ANSWERS.find((a) => updateValues[a] !== '1') ||
      this.SIGNATURE.find((s) => updateValues[s] === '')
    );
  }

  onUpdate(updateValues) {
    this.checkForm(updateValues);
  }

  onNestedUpdate(updateValues) {
    this.checkForm(updateValues);
    this._store.store(CONSENT_KEY, updateValues);
  }

  buildAccordions(
    accordions: { header: string; content: string[] }[],
    margin = ['0', '0', '1rem', '0']
  ): MoodRenderFrame {
    let result = [];
    accordions.forEach(({ header, content }) => {
      result.push({
        type: MoodRendererUnitType.ACCORDION,
        expanded: true,
        header: header,
        content: this.buildContent(content),
        margin: margin,
      });
    });
    return result;
  }

  buildContent(content: string[], margin = ['0', '0', '1rem', '0']): MoodRenderFrame {
    let result = [];
    content.forEach((c) => {
      result.push({
        type: MoodRendererUnitType.PARAGRAPH,
        html: c,
        margin: margin,
      });
    });
    return result;
  }

  buildCheckboxes(group: string[]): MoodRenderFrame {
    let result = [];
    group.forEach((g) => {
      result.push({
        type: MoodRendererUnitType.RADIO_GROUP,
        classes: ['custom-radio-row'],
        formData: {
          controlName: 'noInfection',
          controlType: 'checkbox',
          options: [
            {
              label: g,
              value: '1',
            },
          ],
          validators: {
            required: true,
            minlength: 1,
          },
        },
        margin: ['0', '0', '1rem', '0'],
      });
    });
    return result;
  }

  buildRenderFrame(): void {
    let extraCheckBoxes: MoodRenderFrame = [];
    let consentFormAccordian: MoodRenderFrame = [];
    if (this.consentFormOverride === CustomInformedConsentForm.mhl_reassurance) {
      for (let index = 3; index < 6; index++) {
        this.ANSWERS.push('consent' + (index + 1));
        extraCheckBoxes.push({
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row'],
          formData: {
            controlName: 'consent' + (index + 1),
            controlType: 'checkbox',
            options: [
              {
                label: this.translationService.translate([
                  'customization',
                  this.consentFormOverride,
                  'pages',
                  'informed-consent-form',
                  'consent',
                  'checkboxes',
                ])[index],
                value: '1',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
        });
      }
    } else if (this.consentFormOverride === CustomInformedConsentForm.brown_labs) {
      this.rendererFrame = [
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'brown-labs',
            'pages',
            'informed-consent-form',
            'study-overview',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'brown-labs',
                'pages',
                'informed-consent-form',
                'study-overview',
                'content',
              ])[0],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'brown-labs',
                'pages',
                'informed-consent-form',
                'study-overview',
                'content',
              ])[1],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'brown-labs',
                'pages',
                'informed-consent-form',
                'study-overview',
                'content',
              ])[2],
              margin: ['0', '0', '1rem', '0'],
            },
          ],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'brown-labs',
            'pages',
            'informed-consent-form',
            'consent',
            'content',
          ])[0],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row'],
          formData: {
            controlName: 'consent1',
            controlType: 'checkbox',
            options: [
              {
                label: this.translationService.translate([
                  'customization',
                  'brown-labs',
                  'pages',
                  'informed-consent-form',
                  'consent',
                  'checkboxes',
                ])[0],
                value: '1',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row'],
          formData: {
            controlName: 'consent2',
            controlType: 'checkbox',
            options: [
              {
                label: this.translationService.translate([
                  'customization',
                  'brown-labs',
                  'pages',
                  'informed-consent-form',
                  'consent',
                  'checkboxes',
                ])[1],
                value: '1',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row'],
          formData: {
            controlName: 'consent3',
            controlType: 'checkbox',
            options: [
              {
                label: this.translationService.translate([
                  'customization',
                  'brown-labs',
                  'pages',
                  'informed-consent-form',
                  'consent',
                  'checkboxes',
                ])[2],
                value: '1',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
        },
      ];
      return;
    } else if (this.consentFormOverride === CustomInformedConsentForm.mhl_mccovid) {
      this.SIGNATURE.push('legal_name');
      this.rendererFrame = [
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'subheader',
          ]),
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'people',
          ])[0],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'people',
          ])[1],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'people',
          ])[2],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'intro',
          ]),
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'why-is-this-research-being-done',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'why-is-this-research-being-done',
                'content',
              ])[0],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'what-happens-during-the-study',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'what-happens-during-the-study',
                'content',
              ])[0],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'what-happens-during-the-study',
                'content',
              ])[1],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'what-happens-during-the-study',
                'content',
              ])[2],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'specimen-collection',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'specimen-collection',
                'content',
              ])[0],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'specimen-collection',
                'content',
              ])[1],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'specimen-collection',
                'content',
              ])[2],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'responsibilities',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'responsibilities',
                'content',
              ])[0],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'responsibilities',
                'content',
              ])[1],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'responsibilities',
                'content',
              ])[2],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'study-length',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'study-length',
                'content',
              ])[0],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'risks-and-discomforts',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'risks-and-discomforts',
                'content',
              ])[0],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'risks-and-discomforts',
                'content',
              ])[1],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'risks-and-discomforts',
                'content',
              ])[2],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'risks-and-discomforts',
                'content',
              ])[3],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'benefits',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'benefits',
                'content',
              ])[0],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'benefits',
                'content',
              ])[1],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'who-pays',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'who-pays',
                'content',
              ])[0],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'payment',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'payment',
                'content',
              ])[0],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'information-collected',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'information-collected',
                'content',
              ])[0],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'information-collected',
                'content',
              ])[1],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'information-collected',
                'content',
              ])[2],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'information-collected',
                'content',
              ])[3],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'information-collected',
                'content',
              ])[4],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'change-of-mind',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'change-of-mind',
                'content',
              ])[0],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'end-early',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'end-early',
                'content',
              ])[0],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'end-early',
                'content',
              ])[1],
              margin: ['0', '0', '0', '1rem'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'end-early',
                'content',
              ])[2],
              margin: ['0', '0', '0', '1rem'],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'study-end',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'study-end',
                'content',
              ])[0],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'contact',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'contact',
                'content',
              ])[0],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'contact',
                'content',
              ])[1],
            },
          ],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'before-consent',
          ])[0],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'before-consent',
          ])[1],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'mhl-mccovid',
            'pages',
            'informed-consent-form',
            'before-consent',
          ])[2],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row'],
          formData: {
            controlName: 'consent1',
            controlType: 'checkbox',
            options: [
              {
                label: this.translationService.translate([
                  'customization',
                  'mhl-mccovid',
                  'pages',
                  'informed-consent-form',
                  'consent',
                  'checkboxes',
                ])[0],
                value: '1',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row'],
          formData: {
            controlName: 'consent2',
            controlType: 'checkbox',
            options: [
              {
                label: this.translationService.translate([
                  'customization',
                  'mhl-mccovid',
                  'pages',
                  'informed-consent-form',
                  'consent',
                  'checkboxes',
                ])[1],
                value: '1',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row'],
          formData: {
            controlName: 'consent3',
            controlType: 'checkbox',
            options: [
              {
                label: this.translationService.translate([
                  'customization',
                  'mhl-mccovid',
                  'pages',
                  'informed-consent-form',
                  'consent',
                  'checkboxes',
                ])[2],
                value: '1',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row'],
          formData: {
            controlName: 'consent4',
            controlType: 'checkbox',
            options: [
              {
                label: this.translationService.translate([
                  'customization',
                  'mhl-mccovid',
                  'pages',
                  'informed-consent-form',
                  'consent',
                  'checkboxes',
                ])[3],
                value: '1',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.INPUT,
          label: () =>
            this.translationService.translate([
              'customization',
              'mhl-mccovid',
              'pages',
              'informed-consent-form',
              'form-fields',
              'legal-name',
              'label',
            ]),
          formData: {
            placeholder: '',
            controlName: 'legal_name',
            controlType: 'text',
            valueType: 'text',
            validators: {
              validatorFunctions: [Validators.required],
            },
          },
        },
        {
          type: MoodRendererUnitType.INPUT,
          label: () =>
            this.translationService.translate([
              'customization',
              'mhl-mccovid',
              'pages',
              'informed-consent-form',
              'form-fields',
              'preferred-name',
              'label',
            ]),
          formData: {
            placeholder: '',
            controlName: 'preferred_name',
            controlType: 'text',
            valueType: 'text',
            validators: {},
          },
        },
        {
          type: MoodRendererUnitType.INPUT,
          label: () =>
            this.translationService.translate([
              'customization',
              'mhl-mccovid',
              'pages',
              'informed-consent-form',
              'form-fields',
              'signature',
              'label',
            ]),
          formData: {
            placeholder: () =>
              this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'form-fields',
                'signature',
                'placeholder',
              ]),
            controlName: 'signature',
            controlType: 'text',
            valueType: 'text',
            validators: {
              validatorFunctions: [Validators.required],
            },
          },
        },
        {
          type: MoodRendererUnitType.INPUT,
          disabled: true,
          label: () =>
            this.translationService.translate([
              'customization',
              'mhl-mccovid',
              'pages',
              'informed-consent-form',
              'form-fields',
              'date',
              'label',
            ]),
          formData: {
            placeholder: () =>
              this.translationService.translate([
                'customization',
                'mhl-mccovid',
                'pages',
                'informed-consent-form',
                'form-fields',
                'date',
                'placeholder',
              ]),
            controlName: 'date',
            mask: '9999-99-99',
            controlType: 'text',
            valueType: 'text',
            currentValue: new Date().toISOString().substring(0, 10),
            validators: {
              validatorFunctions: [Validators.required],
            },
          },
        },
      ];
      return;
    } else if (this.consentFormOverride === CustomInformedConsentForm.mhl_general) {
      this.ANSWERS = [];
      this.SIGNATURE.push('legal_name');
      this.rendererFrame = [
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'mhl-general',
            'pages',
            'informed-consent-form',
            'subheader',
          ]),
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'mhl-general',
            'pages',
            'informed-consent-form',
            'intro',
          ])[0],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'mhl-general',
            'pages',
            'informed-consent-form',
            'intro',
          ])[1],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-general',
            'pages',
            'informed-consent-form',
            'sample-collection',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'sample-collection',
                'content',
              ])[0],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-general',
            'pages',
            'informed-consent-form',
            'results',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'results',
                'content',
              ])[0],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-general',
            'pages',
            'informed-consent-form',
            'privacy-and-confidentiality',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'privacy-and-confidentiality',
                'content',
              ])[0],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'privacy-and-confidentiality',
                'content',
              ])[1],
            },
          ],
        },
        {
          type: MoodRendererUnitType.ACCORDION,
          expanded: true,
          header: this.translationService.translate([
            'customization',
            'mhl-general',
            'pages',
            'informed-consent-form',
            'consent-and-acknowledgement',
            'header',
          ]),
          content: [
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'consent-and-acknowledgement',
                'content',
              ])[0],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'consent-and-acknowledgement',
                'content',
              ])[1],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'consent-and-acknowledgement',
                'content',
              ])[2],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'consent-and-acknowledgement',
                'content',
              ])[3],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'consent-and-acknowledgement',
                'content',
              ])[4],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'consent-and-acknowledgement',
                'content',
              ])[5],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'consent-and-acknowledgement',
                'content',
              ])[6],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'consent-and-acknowledgement',
                'content',
              ])[7],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'consent-and-acknowledgement',
                'content',
              ])[8],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'consent-and-acknowledgement',
                'content',
              ])[9],
              margin: ['0', '0', '1rem', '0'],
            },
            {
              type: MoodRendererUnitType.PARAGRAPH,
              html: this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'consent-and-acknowledgement',
                'content',
              ])[10],
            },
          ],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'mhl-general',
            'pages',
            'informed-consent-form',
            'declaration',
            'header',
          ]),
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'mhl-general',
            'pages',
            'informed-consent-form',
            'declaration',
            'content',
          ])[0],
        },
        {
          type: MoodRendererUnitType.INPUT,
          label: () =>
            this.translationService.translate([
              'customization',
              'mhl-general',
              'pages',
              'informed-consent-form',
              'form-fields',
              'legal-name',
              'label',
            ]),
          formData: {
            placeholder: '',
            controlName: 'legal_name',
            controlType: 'text',
            valueType: 'text',
            validators: {
              validatorFunctions: [Validators.required],
            },
          },
        },
        {
          type: MoodRendererUnitType.INPUT,
          label: () =>
            this.translationService.translate([
              'customization',
              'mhl-general',
              'pages',
              'informed-consent-form',
              'form-fields',
              'signature',
              'label',
            ]),
          formData: {
            placeholder: () =>
              this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'form-fields',
                'signature',
                'placeholder',
              ]),
            controlName: 'signature',
            controlType: 'text',
            valueType: 'text',
            validators: {
              validatorFunctions: [Validators.required],
            },
          },
        },
        {
          type: MoodRendererUnitType.INPUT,
          disabled: true,
          label: () =>
            this.translationService.translate([
              'customization',
              'mhl-general',
              'pages',
              'informed-consent-form',
              'form-fields',
              'date',
              'label',
            ]),
          formData: {
            placeholder: () =>
              this.translationService.translate([
                'customization',
                'mhl-general',
                'pages',
                'informed-consent-form',
                'form-fields',
                'date',
                'placeholder',
              ]),
            controlName: 'date',
            mask: '9999-99-99',
            controlType: 'text',
            valueType: 'text',
            currentValue: new Date().toISOString().substring(0, 10),
            validators: {
              validatorFunctions: [Validators.required],
            },
          },
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this.translationService.translate([
            'customization',
            'mhl-general',
            'pages',
            'informed-consent-form',
            'if-minor',
          ]),
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.INPUT,
          label: () =>
            this.translationService.translate([
              'customization',
              'mhl-general',
              'pages',
              'informed-consent-form',
              'form-fields',
              'parent-guardian-legal-name',
              'label',
            ]),
          formData: {
            placeholder: '',
            controlName: 'parent_guardian_legal_name',
            controlType: 'text',
            valueType: 'text',
            validators: {},
          },
        },
      ];
      return;
    }

    consentFormAccordian = [
      ...this.buildContent(
        this.translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'consent',
          'content',
        ])
      ),
      {
        type: MoodRendererUnitType.RADIO_GROUP,
        classes: ['custom-radio-row'],
        formData: {
          controlName: 'consent1',
          controlType: 'checkbox',
          options: [
            {
              label: this.translationService.translate([
                'customization',
                this.consentFormOverride,
                'pages',
                'informed-consent-form',
                'consent',
                'checkboxes',
              ])[0],
              value: '1',
            },
          ],
          validators: {
            required: true,
            minlength: 1,
          },
        },
        margin: ['0', '0', '1rem', '0'],
      },
      {
        type: MoodRendererUnitType.RADIO_GROUP,
        classes: ['custom-radio-row'],
        formData: {
          controlName: 'consent2',
          controlType: 'checkbox',
          options: [
            {
              label: this.translationService.translate([
                'customization',
                this.consentFormOverride,
                'pages',
                'informed-consent-form',
                'consent',
                'checkboxes',
              ])[1],
              value: '1',
            },
          ],
          validators: {
            required: true,
            minlength: 1,
          },
        },
        margin: ['0', '0', '1rem', '0'],
      },
      {
        type: MoodRendererUnitType.RADIO_GROUP,
        classes: ['custom-radio-row'],
        formData: {
          controlName: 'consent3',
          controlType: 'checkbox',
          options: [
            {
              label: this.translationService.translate([
                'customization',
                this.consentFormOverride,
                'pages',
                'informed-consent-form',
                'consent',
                'checkboxes',
              ])[2],
              value: '1',
            },
          ],
          validators: {
            required: true,
            minlength: 1,
          },
        },
        margin: ['0', '0', '1rem', '0'],
      },
    ];

    this.rendererFrame = [
      {
        type: MoodRendererUnitType.PARAGRAPH,
        html: this.translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'description',
          'paragraph',
        ]),
        margin: ['0', '0', '1rem', '0'],
      },
      {
        type: MoodRendererUnitType.PARAGRAPH,
        html: this.translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'description',
          'sponsor',
          'title',
        ]),
        margin: ['0', '0', '0', '0'],
      },
      ...this.buildContent(
        this.translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'description',
          'sponsor',
          'detail',
        ]),
        ['0', '0', '0', '0']
      ),
      {
        type: MoodRendererUnitType.PARAGRAPH,
        html: this.translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'description',
          'information',
          'investigator',
        ]),
        margin: ['1rem', '0', '0', '0'],
      },
      {
        type: MoodRendererUnitType.PARAGRAPH,
        html: this.translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'description',
          'information',
          'co-investigator',
        ]),
        margin: ['0', '0', '0', '0'],
      },
      {
        type: MoodRendererUnitType.PARAGRAPH,
        html: this.translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'description',
          'information',
          'collaborators',
        ]),
        margin: ['0', '0', '0', '0'],
      },
      {
        type: MoodRendererUnitType.PARAGRAPH,
        html: this.translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'description',
          'information',
          'more-collaborators',
        ]),
        margin: ['0', '0', '0', '0'],
      },
      ...this.buildAccordions(
        this.translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'accordions',
        ])
      ),
      {
        type: MoodRendererUnitType.ACCORDION,
        expanded: true,
        header: this.translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'consent',
          'header',
        ]),
        content: consentFormAccordian.concat(extraCheckBoxes).concat([
          {
            type: MoodRendererUnitType.INPUT,
            margin: ['6px', '0', '6px', '0'],
            label: () =>
              this.translationService.translate([
                'customization',
                this.consentFormOverride,
                'pages',
                'informed-consent-form',
                'consent',
                'signature',
              ]).label,
            formData: {
              placeholder: () =>
                this.translationService.translate([
                  'customization',
                  this.consentFormOverride,
                  'pages',
                  'informed-consent-form',
                  'consent',
                  'signature',
                ]).placeholder,
              controlName: 'signature',
              controlType: 'text',
              valueType: 'text',
              validators: {
                validatorFunctions: [Validators.required],
              },
            },
          },
          {
            type: MoodRendererUnitType.INPUT,
            margin: ['6px', '0', '6px', '0'],
            disabled: true,
            label: () =>
              this.translationService.translate([
                'customization',
                this.consentFormOverride,
                'pages',
                'informed-consent-form',
                'consent',
                'date',
              ]).label,
            formData: {
              placeholder: () =>
                this.translationService.translate([
                  'customization',
                  this.consentFormOverride,
                  'pages',
                  'informed-consent-form',
                  'consent',
                  'date',
                ]).placeholder,
              controlName: 'date',
              mask: '9999/99/99',
              controlType: 'text',
              valueType: 'text',
              validators: {
                validatorFunctions: [Validators.required],
              },
            },
          },
        ]),
      },
    ];
  }
}
