/* Copyright 2023 (Unpublished) Verto Inc. */

import { MoodState } from 'engage-common';
import { brownLabsLandingPageState } from './brown-labs';
import { mhlMccovidLandingPageState } from './mhl-mccovid';
import { mhlGeneralLandingPageState } from './mhl-general';
import { LandingPage } from './custom-landing-pages.enum';

const defaults: MoodState = {
  onUpdate: () => {
    /* noop */
  },
  onNestedUpdate: () => {
    /* noop */
  },
  rendererFrame: [],
};

class _LandingPageStateFactory {
  landingPageContextFromLandingPageEnum = {
    [LandingPage.brown_labs]: brownLabsLandingPageState,
    [LandingPage.mhl_mccovid]: mhlMccovidLandingPageState,
    [LandingPage.mhl_general]: mhlGeneralLandingPageState,
  };

  getLandingPageState(landingPage: LandingPage) {
    const landingPageContext = this.landingPageContextFromLandingPageEnum[landingPage];
    return {
      forComponent: (landingComponent): MoodState => {
        return Object.assign(defaults, landingPageContext?.(landingComponent));
      },
    };
  }
}

export const LandingPageStateFactory = new _LandingPageStateFactory();
