/* Copyright 2023 (Unpublished) Verto Inc. */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationConfig } from './configuration/application-config';
import { environment } from '../../environments/environment';
import { publishReplay, refCount, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  config: Observable<ApplicationConfig>;
  key: string = environment.key;
  constructor(private http: HttpClient, private _route: ActivatedRoute) {}

  getConfig(): Observable<ApplicationConfig> {
    return this._route.queryParams.pipe(
      switchMap((params) => {
        if (!this.config || (params.key && this.key !== params.key)) {
          if (params.key) {
            this.key = params.key;
          }
          this.config = this.http
            .get<ApplicationConfig>(`${environment.backendUrl}/api/public/application/${this.key}`)
            .pipe(publishReplay(1), refCount());
        }
        return this.config;
      })
    );
  }
}
