/* Copyright 2023 (Unpublished) Verto Inc. */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EngageSerializableStoreService } from 'engage-utils';
import { ACCESS_TOKEN_KEY } from './chl-patient.service';
import { AppComponent } from '../app.component';
import { NavComponent } from '../utility-components/nav/nav.component';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root',
})
export class NoTokenGuard  {
  constructor(
    private _store: EngageSerializableStoreService,
    private _router: Router,
    private _configurationService: ConfigurationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._store.retrieve(ACCESS_TOKEN_KEY)) {
      this._configurationService.getConfig().subscribe((app) => {
        const default_page = app.config.content.default_page || 'test-kits';
        this._router.navigate([default_page], {
          queryParams: next.queryParams,
          queryParamsHandling: null,
        });
      });
      return false;
    }

    return true;
  }
}
