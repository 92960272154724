/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChlFooterService } from './services/chl-footer.service';
import { Subscription } from 'rxjs';
import { ChlPatientService } from './services/chl-patient.service';
import { TranslationService } from './services/translation.service';
import { ChlHeaderService } from './services/chl-header.service';
import { NavigationExtras, Router } from '@angular/router';
import { PrintService } from './services/print.service';
import { environment } from '../environments/environment';
import { ConfigurationService } from './services/configuration.service';
import { Title } from '@angular/platform-browser';
import { ShellLoader } from 'projects/shell/src/app/ShellLoader';
import { ActivatedRoute } from '@angular/router';

// polyfill for ie https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/includes
const stringIncludes = (string: string, search: string, start?: number) => {
  if (start === undefined) {
    start = 0;
  }
  return string.indexOf(search, start) !== -1;
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  showContentFooter: boolean;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _footerService: ChlFooterService,
    private _headerService: ChlHeaderService,
    private _patientService: ChlPatientService,
    public translationService: TranslationService,
    private _router: Router,
    private _printService: PrintService,
    private _configurationService: ConfigurationService,
    private titleService: Title,
    private shellLoader: ShellLoader,
    private route: ActivatedRoute
  ) {
    // Patch router navigation to always preserve query parameters
    // https://github.com/angular/angular/issues/12664#issuecomment-829984182
    const _navigate = this._router.navigate;
    this._router.navigate = function (commands: any[], extras: NavigationExtras = {}) {
      extras = {
        queryParamsHandling: 'preserve',
        ...extras,
      };
      return _navigate.call(this, commands, extras);
    };
  }

  printContent: string;
  headerLogoFilename: string;
  wallpaperFilename?: string;
  appStyle: any = {};
  hideLangChange = false;
  hidePageFooter = false;
  hideFooterText = false;
  footerLogo = '../assets/images/verto.svg';
  defaultPageTitle = 'CHL Test Portal';
  footerText: string;
  footerUrl: string;
  defaultFooterText = 'About Canada HealthLabs';
  defaultFooterUrl = 'https://canadahealthlabs.ca/en/home.php';
  privacyPolicyText = this.translationService.translate(['footer', 'privacy']);
  privacyPolicyUrl = '/privacy-policy';
  supportedLanguages = ['en', 'fr'];

  ngOnInit(): void {
    this._subscriptions = [
      this.route.queryParams.subscribe((params) => {
        if (params.lang && this.supportedLanguages.includes(params.lang)) {
          this.loadLanguage(params.lang);
          this.privacyPolicyText = this.translationService.translate(['footer', 'privacy']);
        }
      }),
      this._footerService.visible.subscribe((visible: boolean) => {
        setTimeout(() => (this.showContentFooter = visible));
      }),
      this._printService.print.subscribe((content) => {
        this.printContent = content;
        setTimeout(print);
      }),
      this._configurationService.getConfig().subscribe((res) => {
        (window as any).backendApiKey = res.key;

        // hack to inject generic open clinic widgets into chl-patient-portal
        (this.shellLoader.config as any) = res.config;
        this.shellLoader.configLoaded.next();

        setTimeout(() => {
          this._setPageTitle(res.config.content.page_title || this.defaultPageTitle);
          // defaults to mhl logo if file name not given in the config
          this.headerLogoFilename = `../assets/images/${
            res.config.content.header_logo_filename
              ? res.config.content.header_logo_filename
              : 'mhl-logo.svg'
          }`;

          if (res.config.content.hasOwnProperty('hide_page_footer')) {
            this.hidePageFooter = res.config.content.hide_page_footer;
          }
          if (res.config.content.hasOwnProperty('hide_footer_text')) {
            this.hideFooterText = res.config.content.hide_footer_text;
          }
          if (res.config.content.wallpaper_filename) {
            this.wallpaperFilename = `url('../assets/images/${res.config.content.wallpaper_filename}')`;
            this.appStyle = { 'background-image': this.wallpaperFilename };
          }
          if (res.config.content.background_style) {
            this.appStyle = { ...this.appStyle, ...res.config.content.background_style };
          }
          if (res.config.content.footer_logo) {
            this.footerLogo = `../assets/images/${res.config.content.footer_logo}`;
          }
          if (res.config.content.hide_lang_change)
            this.hideLangChange = res.config.content.hide_lang_change;

          const footerContents = res.config.content.footer;
          if (footerContents) {
            this.footerText = footerContents.text ? footerContents.text : this.defaultFooterText;
            this.footerUrl = footerContents.url ? footerContents.url : this.defaultFooterUrl;
          } else {
            this.footerText = this.defaultFooterText;
            this.footerUrl = this.defaultFooterUrl;
          }

          const privacyPolicy = res.config.content.privacy_policy;
          if (privacyPolicy) {
            this.privacyPolicyText = privacyPolicy.text
              ? privacyPolicy.text
              : this.privacyPolicyText;
            this.privacyPolicyUrl = privacyPolicy.url ? privacyPolicy.url : this.privacyPolicyUrl;
          }
        });
      }),
    ];
    // this.loadLanguage((localStorage.getItem('preferredLanguage') as any) ?? 'en');
    this.translationService.langObservable.next('en'); // temporary until we implement french
    localStorage.setItem('preferredLanguage', 'en'); // temporary until we implement french

    this._initiateUnloadListener();
  }

  unloadWhiteList = ['/answer-questionnaire'];
  test = environment.testing;

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  loadLanguage(lang: 'en' | 'fr') {
    if (lang === this.translationService.currentLanguage) {
      return;
    }

    localStorage.setItem('preferredLanguage', lang);
    this.translationService.langObservable.next(lang);
  }

  get currentLanguage() {
    return this.translationService.currentLanguage;
  }

  private _initiateUnloadListener(): void {
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    window.addEventListener('beforeunload', (event) => {
      let unloadTrigger = false;
      for (const route of this.unloadWhiteList) {
        if (stringIncludes(this._router.url, route)) {
          unloadTrigger = true;
          break;
        }
      }

      if (!unloadTrigger) {
        return;
      }

      // Cancel the event
      event.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      event.returnValue = '';
    });
  }

  private _setPageTitle(title: string): void {
    this.titleService.setTitle(title);
  }
}
