/* Copyright 2023 (Unpublished) Verto Inc. */

import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// https://stackoverflow.com/questions/39794588/angular2-innerhtml-removes-styling
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
