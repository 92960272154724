/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MoodRendererUnitType, MoodRenderFrame, MoodRendererComponent } from 'engage-common';
import { TranslationService } from '../../../services/translation.service';
import { Router } from '@angular/router';
import { ConfigurationService } from '../../../services/configuration.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-mhl-reassurance-landing',
  templateUrl: './mhl-reassurance-landing.component.html',
  styleUrls: ['./mhl-reassurance-landing.component.scss'],
})
export class MhlReassuranceLandingComponent implements OnInit {
  nextUrl: string;
  canProceed: boolean;
  nextDisabled: boolean;
  ANSWERS = ['age18', 'informedConsent', 'employment', 'noInfection', 'noSymptoms'];

  @ViewChild(MoodRendererComponent) renderer: MoodRendererComponent;

  constructor(
    public _translationService: TranslationService,
    private _router: Router,
    private _configurationService: ConfigurationService,
    private _primeNgMessageService: MessageService
  ) {}

  onUpdate(updateValues) {}

  onNestedUpdate(updateValues) {
    this.nextDisabled = false;
    this.canProceed = true;
    for (let i = 0; i < this.ANSWERS.length; i++) {
      const value = updateValues[this.ANSWERS[i]];
      if (value === '0') {
        this.nextDisabled = true;
      } else if (value === '') {
        this.canProceed = false;
      }
    }
  }

  ngOnInit(): void {
    this._configurationService.getConfig().subscribe((app) => {
      this.nextUrl = app.config.content.signup_preamble[0];
    });
  }

  onClick() {
    if (!this.canProceed) {
      this._primeNgMessageService.add({
        severity: 'error',
        summary: '',
        detail: this._translationService.translate([
          'customization',
          'mhl-reassurance',
          'pages',
          'landing',
          'error',
          'answer-all',
        ]),
      });
    } else {
      this._router.navigate([this.nextUrl]);
    }
  }

  onSigninClick() {
    this._router.navigate(['sign-in']);
  }

  rendererFrame: MoodRenderFrame = [
    {
      type: MoodRendererUnitType.ACCORDION,
      expanded: false,
      header: this._translationService.translate([
        'customization',
        'mhl-reassurance',
        'pages',
        'landing',
        'study-overview',
        'header',
      ]),
      content: [
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this._translationService.translate([
            'customization',
            'mhl-reassurance',
            'pages',
            'landing',
            'study-overview',
            'content',
          ])[0],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this._translationService.translate([
            'customization',
            'mhl-reassurance',
            'pages',
            'landing',
            'study-overview',
            'content',
          ])[1],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this._translationService.translate([
            'customization',
            'mhl-reassurance',
            'pages',
            'landing',
            'study-overview',
            'content',
          ])[2],
          margin: ['0', '0', '1rem', '0'],
        },
      ],
      margin: ['0', '0', '1rem', '0'],
    },
    {
      type: MoodRendererUnitType.ACCORDION,
      expanded: true,
      header: this._translationService.translate([
        'customization',
        'mhl-reassurance',
        'pages',
        'landing',
        'registered',
        'header',
      ]),
      content: [
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this._translationService.translate([
            'customization',
            'mhl-reassurance',
            'pages',
            'landing',
            'registered',
            'content',
          ]),
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.BUTTON,
          buttonClasses: ['mood-primary', 'w-100'],
          text: () =>
            this._translationService.translate([
              'customization',
              'mhl-reassurance',
              'pages',
              'landing',
              'registered',
              'login-button',
            ]),
          click: () => this.onSigninClick(),
        },
      ],
      margin: ['0', '0', '1rem', '0'],
    },
    {
      type: MoodRendererUnitType.ACCORDION,
      expanded: true,
      header: this._translationService.translate([
        'customization',
        'mhl-reassurance',
        'pages',
        'landing',
        'eligibility',
        'header',
      ]),
      content: [
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this._translationService.translate([
            'customization',
            'mhl-reassurance',
            'pages',
            'landing',
            'eligibility',
            'content',
          ])[0],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row'],
          formData: {
            controlName: 'age18',
            controlType: 'checkbox',
            options: [
              {
                label: this._translationService.translate(['options', 'yes']),
                value: '1',
              },
              {
                label: this._translationService.translate(['options', 'no']),
                value: '0',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this._translationService.translate([
            'customization',
            'mhl-reassurance',
            'pages',
            'landing',
            'eligibility',
            'content',
          ])[1],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row'],
          formData: {
            controlName: 'informedConsent',
            controlType: 'checkbox',
            options: [
              {
                label: this._translationService.translate(['options', 'yes']),
                value: '1',
              },
              {
                label: this._translationService.translate(['options', 'no']),
                value: '0',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this._translationService.translate([
            'customization',
            'mhl-reassurance',
            'pages',
            'landing',
            'eligibility',
            'content',
          ])[2],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row'],
          formData: {
            controlName: 'employment',
            controlType: 'checkbox',
            options: [
              {
                label: this._translationService.translate(['options', 'yes']),
                value: '1',
              },
              {
                label: this._translationService.translate(['options', 'no']),
                value: '0',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this._translationService.translate([
            'customization',
            'mhl-reassurance',
            'pages',
            'landing',
            'eligibility',
            'content',
          ])[3],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row'],
          formData: {
            controlName: 'noInfection',
            controlType: 'checkbox',
            options: [
              {
                label: this._translationService.translate(['options', 'yes']),
                value: '1',
              },
              {
                label: this._translationService.translate(['options', 'no']),
                value: '0',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this._translationService.translate([
            'customization',
            'mhl-reassurance',
            'pages',
            'landing',
            'eligibility',
            'content',
          ])[4],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row'],
          formData: {
            controlName: 'noSymptoms',
            controlType: 'checkbox',
            options: [
              {
                label: this._translationService.translate(['options', 'yes']),
                value: '1',
              },
              {
                label: this._translationService.translate(['options', 'no']),
                value: '0',
              },
            ],
            validators: {
              required: true,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this._translationService.translate([
            'customization',
            'mhl-reassurance',
            'pages',
            'landing',
            'eligibility',
            'content',
          ])[5],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this._translationService.translate([
            'customization',
            'mhl-reassurance',
            'pages',
            'landing',
            'eligibility',
            'content',
          ])[6],
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.BUTTON,
          disabled: () => this.nextDisabled,
          buttonClasses: ['mood-primary', 'w-100'],
          text: () => this._translationService.translate(['buttons', 'continue']),
          click: () => this.onClick(),
        },
      ],
      margin: ['0', '0', '1rem', '0'],
    },
  ];
}
