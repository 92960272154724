/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component } from '@angular/core';
import { MoodRendererUnitType, MoodRenderFrame } from 'engage-common';
import { TranslationService } from '../services/translation.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent {
  constructor(private _translationService: TranslationService) {}

  rendererFrame: MoodRenderFrame = [
    {
      type: MoodRendererUnitType.HEADER,
      label: this._translationService.translate(['pages', 'privacyPolicy', 'header']),
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: this._translationService.translate(['pages', 'privacyPolicy', 'body']),
    },
  ];
}
