/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, Input } from '@angular/core';
import { MoodRendererUnitType } from 'engage-common';
import { TranslationService } from '../services/translation.service';
import { Router } from '@angular/router';
import { EngageSerializableStoreService } from 'engage-utils';
import { ChlHeaderService } from '../services/chl-header.service';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  @Input() headerTitle: string = '';
  @Input() headerSubtitle: string = '';
  @Input() expanded: boolean = false;
  icon: string = 'arrow-down';

  constructor(
    private _translationService: TranslationService,
    private _router: Router,
    private _store: EngageSerializableStoreService,
    private _headerService: ChlHeaderService
  ) {}

  moodButton = [
    {
      type: MoodRendererUnitType.BUTTON,
      text: 'Consent Form',
      buttonClasses: ['mood-primary'],
      margin: ['1rem', '0', '0', '0'],
      click: () => this._router.navigate(['informed-consent']),
    },
  ];

  digitalCommunication = [
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: `<b>${
        this._translationService.translate(['pages', 'settings', 'digitalCommunication']).note
      }</b>`,
      margin: ['0.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: this._translationService.translate(['pages', 'settings', 'digitalCommunication'])
        .contactName,
      margin: ['0', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: this._translationService.translate(['pages', 'settings', 'digitalCommunication'])
        .researchPosition,
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: `<a href = "mailto:${
        this._translationService.translate(['pages', 'settings', 'digitalCommunication']).email
      }">${
        this._translationService.translate(['pages', 'settings', 'digitalCommunication']).email
      }</a>`,
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: this._translationService.translate(['pages', 'settings', 'digitalCommunication'])
        .phoneNumber,
    },
  ];

  removalFromStudy = [
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: this._translationService.translate(['pages', 'settings', 'removalFromStudy']).note1,
      margin: ['0.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: this._translationService.translate(['pages', 'settings', 'removalFromStudy']).note2,
      margin: ['0.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: this._translationService.translate(['pages', 'settings', 'removalFromStudy'])
        .researchPosition,
      margin: ['0', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: `<a href = "mailto:${
        this._translationService.translate(['pages', 'settings', 'removalFromStudy']).email
      }">${
        this._translationService.translate(['pages', 'settings', 'removalFromStudy']).email
      }</a>`,
      margin: ['0.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: this._translationService.translate(['pages', 'settings', 'removalFromStudy'])
        .phoneNumber,
      margin: ['0.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: this._translationService.translate(['pages', 'settings', 'removalFromStudy']).note3,
    },
  ];

  settings = [
    {
      title: this._translationService.translate(['pages', 'settings', 'informedConsent']).title,
      subtitle: this._translationService.translate(['pages', 'settings', 'informedConsent'])
        .subtitle,
      content: this.moodButton,
    },
    {
      title: this._translationService.translate(['pages', 'settings', 'digitalCommunication'])
        .title,
      subtitle: this._translationService.translate(['pages', 'settings', 'digitalCommunication'])
        .subtitle,
      content: this.digitalCommunication,
    },
    {
      title: this._translationService.translate(['pages', 'settings', 'removalFromStudy']).title,
      subtitle: this._translationService.translate(['pages', 'settings', 'removalFromStudy'])
        .subtitle,
      content: this.removalFromStudy,
    },
  ];
}
