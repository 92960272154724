/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Engage Common Components
import { GenericModule, MoodModule } from 'engage-common';

// Imports
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

// Components
import { TestKitListComponent } from './test-kit/test-kit-list/test-kit-list.component';
import { TestKitDetailsComponent } from './test-kit/test-kit-details/test-kit-details.component';
import { LocalizedDatePipe } from './services/localized-date.pipe';
import { SettingsComponent } from './settings/settings.component';
import { TestKitAccordionComponent } from './test-kit/test-kits-overview/test-kit-accordion/test-kit-accordion.component';

@NgModule({
  declarations: [
    TestKitListComponent,
    TestKitDetailsComponent,
    TestKitAccordionComponent,
    SettingsComponent,
    LocalizedDatePipe,
  ],
  imports: [
    FormsModule,
    HttpClientModule,
    CommonModule,
    MoodModule,
    GenericModule,
    ProgressBarModule,
    BrowserAnimationsModule,
  ],
  exports: [
    MoodModule,
    GenericModule,
    CommonModule,
    ToastModule,
    LocalizedDatePipe,
    BrowserAnimationsModule,
  ],
  providers: [MessageService, LocalizedDatePipe],
})
export class AppSharedModule {}
