/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MoodRendererUnitType, MoodRenderFrame, MoodRendererComponent } from 'engage-common';
import { TranslationService } from '../../../services/translation.service';
import { Router } from '@angular/router';
import { ConfigurationService } from '../../../services/configuration.service';
import { MessageService } from 'primeng/api';
import { ChlFooterService } from '../../../services/chl-footer.service';

@Component({
  selector: 'app-reassurance-demo-landing',
  templateUrl: './reassurance-demo-landing.component.html',
  styleUrls: ['./reassurance-demo-landing.component.scss'],
})
export class ReassuranceDemoLandingComponent implements OnInit {
  nextUrl: string;
  @ViewChild(MoodRendererComponent) renderer: MoodRendererComponent;

  constructor(
    public _translationService: TranslationService,
    private _router: Router,
    private _configurationService: ConfigurationService,
    private _footerService: ChlFooterService
  ) {}

  onUpdate(updateValues) {}

  onNestedUpdate(updateValues) {}

  ngOnInit(): void {
    this._footerService.visible.next(false);
    this._configurationService.getConfig().subscribe((app) => {
      this.nextUrl = app.config.content.signup_preamble[0];
    });
  }

  onClick() {
    this._router.navigate([this.nextUrl]);
  }

  onSigninClick() {
    this._router.navigate(['sign-in']);
  }

  rendererFrame: MoodRenderFrame = [
    {
      type: MoodRendererUnitType.ACCORDION,
      expanded: true,
      header: this._translationService.translate(['pages', 'landing', 'registered', 'header']),
      content: [
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: this._translationService.translate(['pages', 'landing', 'registered', 'content']),
          margin: ['0', '0', '1rem', '0'],
        },
        {
          type: MoodRendererUnitType.BUTTON,
          buttonClasses: ['mood-primary', 'w-100'],
          text: () =>
            this._translationService.translate(['pages', 'landing', 'registered', 'login-button']),
          click: () => this.onSigninClick(),
        },
      ],
      margin: ['0', '0', '1rem', '0'],
    },
    {
      type: MoodRendererUnitType.ACCORDION,
      expanded: true,
      header: this._translationService.translate(['pages', 'landing', 'eligibility', 'header']),
      content: [
        {
          type: MoodRendererUnitType.BUTTON,
          buttonClasses: ['mood-primary', 'w-100'],
          text: () =>
            this._translationService.translate(['pages', 'landing', 'eligibility', 'button']),
          click: () => this.onClick(),
        },
      ],
      margin: ['0', '0', '1rem', '0'],
    },
  ];
}
