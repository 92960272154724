/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component } from '@angular/core';
import { TranslationService } from '../services/translation.service';

@Component({
  template: `<app-link-sent
    [header]="
      translationService.translate(['customization', 'brown-labs', 'pages', 'linkSent', 'header'])
    "
    [confirmation]="
      translationService.translate([
        'customization',
        'brown-labs',
        'pages',
        'linkSent',
        'confirmation'
      ])
    "
    [issue]="
      translationService.translate(['customization', 'brown-labs', 'pages', 'linkSent', 'issue'])
    "
  >
  </app-link-sent>`,
})
export class LinkSentComponent {
  constructor(public translationService: TranslationService) {}
}
