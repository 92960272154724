/* Copyright 2023 (Unpublished) Verto Inc. */

import { InformedConsentComponent } from './informed-consent.component';

export const informedConsentForm = (thisArg: InformedConsentComponent) => {
  return `<html>
    <head>
        <meta content="text/html; charset=UTF-8" http-equiv="content-type" />
    </head>
    <body class="c43">
        <p class="c28"><span class="c4">&nbsp;</span></p>
        <p class="c15 c27"><span class="c6 c14"></span></p>
        <p class="c15">
            <span style="overflow: hidden; display: inline-block; margin: 0px 0px; border: 0px solid #000000; transform: rotate(0rad) translateZ(0px); -webkit-transform: rotate(0rad) translateZ(0px); width: 194px; height: 43px;">
                <img alt="" src="/assets/images/mhl-print-header.png" style="width: 194px; height: 43px; margin-left: 0px; margin-top: 0px; transform: rotate(0rad) translateZ(0px); -webkit-transform: rotate(0rad) translateZ(0px);" title="" />
            </span>
        </p>
        <p class="c15"><span class="c6 c14">Participant Information and Consent Form</span></p>
        <a id="t.0192b1f1e6a14d7fc2da06c2f680c5ef7784b7a6"></a><a id="t.0"></a>
        <table class="c10">
            <tbody>
                <tr class="c38">
                    <td class="c11" colspan="1" rowspan="1">
                        <p class="c3"><span class="c6 c7">Sponsors:</span></p>
                        <p class="c3"><span class="c6 c7">&nbsp;</span></p>
                    </td>
                    <td class="c0" colspan="1" rowspan="1">
                        <p class="c3"><span class="c9">McMaster HealthLabs (MHL)</span></p>
                        <p class="c3"><span class="c9">175 Longwood Road South, Suite 305</span></p>
                        <p class="c3"><span class="c9">Hamilton, Ontario | L8P0A1</span></p>
                        <p class="c3"><span class="c9">1-833-292-5856</span></p>
                        <p class="c3"><span class="c6 c7">&nbsp;</span></p>
                    </td>
                </tr>
                <tr class="c38">
                <td class="c11" colspan="1" rowspan="1">
                    <p class="c3"><span class="c6 c7">Protocol Title:</span></p>
                    <p class="c3"><span class="c6 c7">&nbsp;</span></p>
                </td>
                <td class="c0" colspan="1" rowspan="1">
                    <p class="c3"><span class="c17 c9">COVID-19 Workplace Surveillance Study</span></p>
                </td>
            </tr>
                <tr class="c22">
                    <td class="c11" colspan="1" rowspan="1">
                        <p class="c3"><span class="c6 c7">Protocol Number:</span></p>
                        <p class="c3"><span class="c6 c7">&nbsp;</span></p>
                    </td>
                    <td class="c0" colspan="1" rowspan="1">
                        <p class="c3"><span class="c6 c7">Pro00048267</span></p>
                        <p class="c3"><span class="c6 c7">&nbsp;</span></p>
                    </td>
                </tr>
                <tr class="c46">
                    <td class="c11" colspan="1" rowspan="1">
                        <p class="c3"><span class="c6 c7">Principal Investigator(s):</span></p>
                        <p class="c3"><span class="c6 c7">&nbsp;</span></p>
                    </td>
                    <td class="c0" colspan="1" rowspan="1">
                        <p class="c3"><span class="c9">Dr. Marek Smieja - Scientific Director | McMaster Health Labs</span></p>
                    </td>
                </tr>
                <tr class="c46">
                    <td class="c11" colspan="1" rowspan="1">
                        <p class="c3"><span class="c6 c7">Co-Investigator:</span></p>
                        <p class="c3"><span class="c6 c7">&nbsp;</span></p>
                    </td>
                    <td class="c0" colspan="1" rowspan="1">
                        <p class="c3"><span class="c9">Dr. David Bulir - Affiliate Research Scientist, Research St. Joseph’s – Hamilton</span></p>
                    </td>
                </tr>
                <tr class="c46">
                    <td class="c11" colspan="1" rowspan="1">
                        <p class="c3"><span class="c6 c7">Collaborators:</span></p>
                        <p class="c3"><span class="c6 c7">&nbsp;</span></p>
                    </td>
                    <td class="c0" colspan="1" rowspan="1">
                        <p class="c3"><span class="c9">Dr. Daniela Leto - Medical Microbiologist | Hamilton Regional Laboratory Medicine Program</span></p>
                    </td>
                </tr>
                <tr class="c46">
                    <td class="c11" colspan="1" rowspan="1">
                        <p class="c3"><span class="c6 c7">Collaborators:</span></p>
                        <p class="c3"><span class="c6 c7">&nbsp;</span></p>
                    </td>
                    <td class="c0" colspan="1" rowspan="1">
                        <p class="c3"><span class="c9">Dr. Zain Chagla - Medical Director of Infectious Disease | St. Joseph’s Healthcare Hamilton</span></p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <a id="t.e29ed84272bd7fe91bf11de507e9f219feb73e9c"></a><a id="t.1"></a>
        <table class="c10">
            <tbody>
                <tr class="c42">
                    <td class="c34" colspan="1" rowspan="1">
                        <p class="c35"><span class="c6 c5">Please read this document carefully</span></p>
                        <p class="c35">
                            <span class="c5">You are invited to participate in this COVID-19 research study (&ldquo;</span><span class="c5 c9">Study</span>
                            <span class="c5">&ldquo;) because you are an employee at Birch Hill Equity Partners Management Inc. </span>
                            <span class="c5">Your taking part in this Study is voluntary. Before you decide, we would like you to know why this Study is being done and what it involves. &nbsp;</span>
                            <span class="c5 c9">Please read this form carefully and take your time to decide</span><span class="c1">. &nbsp;You can ask the research team any questions you may have. </span>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <h1 class="c16" id="h.37q5sa5hudlg"><span class="c6 c13">Why is this study being done?</span></h1>
        <p class="c3">
            <span class="c1">
                You are being invited to participate in this research Study to help provide information about Canada’s response to the COVID-19 pandemic, and to help respond better to future outbreaks. Birch Hill Equity Partners Management Inc. and the research team are also exploring the potential of workplace COVID-19 testing programs.
            </span>
        </p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <p class="c3"><span class="c1">Your participation will start upon your signing and dating this consent document, and providing it to the research team on site. </span></p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                McMaster HealthLabs (MHL) is an independent, not-for-profit organization. McMaster Health Labs is working with a team of researchers who are affiliated with Research St. Joseph’s – Hamilton to conduct the study. They have contracted with Birch Hill Equity Partners Management Inc. to initiate the study. MHL is responsible for managing all aspects of the study.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <h1 class="c8" id="h.3fvfkbn6em5m"><span class="c6 c13">What happens during the Study?</span></h1>
        <p class="c2">
            <span class="c1">
                The Study will involve answering an online questionnaire on eight separate occasions (today and once a week for the next seven weeks). You will also be asked to self-collect an oral-nasal specimen on each occasion, which will be sent to a laboratory for COVID-19 analysis. Oral-nasal specimens are self-administered swabs that involve moistening the swab on the tip of your tongue, followed by inserting the swab between the lower gums and cheek and turning it three times. Then, the swab is inserted about one inch into each nostril, and then turned three times. The swab is then placed into McMaster Molecular Medium (MMM), which inactivates virus. 
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                The baseline and end of study questionnaires will take approximately 15 minutes. Weekly questionnaires be shorter, and will take approximately 5 minutes to complete. We will ask you questions about your history of COVID-19 testing, your perceived COVID-19 risk, and motivations for participating in the study. All study questionnaires and questions within the questionnaires are completely voluntary. You may skip any question you do not wish to answer. These surveys are online and information is not stored until submitted so before you begin each survey, we ask you to please decide if you will have enough time to complete the survey.
            </span>
        </p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <h1 class="c16" id="h.t9mz0uwotk19"><span class="c6 c13">Study Schedule</span></h1>
        <p class="c2">
            <span class="c1">
                When the study begins, research staff will be available at each Birch Hill Equity Partners Management Inc.  site to provide information about the study, and answer any questions you may have. At baseline, you will be asked to fill out an eligibility questionnaire, register your first test kit, collect a self-administered oral-nasal swab sample, and complete a questionnaire. Trained researchers will assist you with this process.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                At baseline you will be provided with information by research staff on where to pick-up your weekly test kit, how to collect your weekly specimens, and where to drop off your specimens. In each subsequent week, you will receive a notification via SMS or email when it’s time to collect your sample.  Samples can be collected wherever you feel most comfortable; this may be either on site, in your car, or at home. You will need to return your specimen to a tamper proof drop-box on site. Specimens will be couriered to the virology research lab of Research St. Joseph’s – Hamilton on a prespecified schedule.
            </span>
        </p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                If you require further assistance from research staff at any point during the study, you may contact the study HelpDesk by phone or by email at any time. They will answer any questions you may have regarding test kit registration, specimen collection, study questionnaires, or anything else you may require assistance with. You can expect an initial response from HelpDesk within 24-28 hours after you have contacted them with your request. If the HelpDesk does not respond after 72 hours, you may contact them again or contact the Research Consultant.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                You will be provided with results within 48 hours of your specimen arriving at the laboratory. The availability of results will be communicated with you via SMS or email, and you may view them on a password protected website with your personal password as well. Test results will be reported to you as either “negative” or “invalid” or “non-negative”. If your sample tests non-negative/presumptive positive in the research laboratory, a delegated and trained study staff member will contact you using the contact information you provided during study enrolment to inform you of your result. They will advise you on the precautions you need to take, and inform you that your sample will be sent to the clinical virology laboratory of the Hamilton Regional Laboratory Medicine Program (HRLMP) for confirmatory testing. The study staff member may request your health card number for the purpose of sharing your result with the HRLMP clinical laboratory and Public Health. Your personal information will be shared with HRLMP as well as Public Health for the purposes of registering and testing your specimen in the clinical lab regardless of whether you provide your health card number or not. Clinical test results will also be shared with the study team. Once your non-negative specimen is processed in the clinical virology lab, a delegated and trained study staff member will contact you a second time using the contact information you provided during study enrolment to inform you of the result.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                All participants that receive positive results will be subject to regional Public Health guidelines. Public health may contact you and ask you to self-isolate, and initiate procedures for contact tracing by asking you who you have been in close contact with. If close contacts are identified, Public Health may contact them (including your employer or those you work with) to assess risk. MHL will not share your result with your employer. 
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                HelpDesk will be also available 7 days a week to support participants for the full duration of the study. Participants may reach out to the HelpDesk at any time via phone or email. 
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <h1 class="c16" id="h.z4ch2ft1n7jx"><span class="c6 c13">Study Duration</span></h1>
        <p class="c2">
            <span class="c1">
                You will be in the Study for 8 weeks. Samples will be collected at study enrollment, and you will self-collect samples on a weekly basis during weeks two to eight. You will also be completing an online questionnaire on all these days.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2"><span class="c1">Special Information Collection</span></p>
        <p class="c2">
            <span class="c1">
                MHL has partnered with a data management organization called VERTO. Your personal contact information (for example, name, date of birth, telephone number, and e-mail address) will be collected at your baseline visit today. This will allow research staff to contact you if needed regarding your test results. Your personal contact information will be kept confidential and stored on an encrypted, firewall-secured Cloud platform at VERTO.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                Your swab samples collected today and during weeks two to eight will be labelled with a unique Study code, so that no one can identify you. Only the unique code is available to researchers. The unique sample code can only be linked back to your personal information by accessing the VERTO database which is restricted to delegated study members and will only be accessed in the case of a non-negative result. Your personal contact information will be kept confidential and stored in a separate database managed by VERTO.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2"><span class="c1">The questionnaire data that you complete will be linked to the same study code and not to any personally identifying information.</span></p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2"><span class="c1">None of the entities that have provided private funding will have access to your personal information.</span></p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <h1 class="c16" id="h.3esd5juw54bt"><span class="c6 c13">What are the possible risks and inconveniences of being in this observational Study?</span></h1>
        <p class="c2">
            <span class="c1">
                There is the risk that your COVID-19 test will return a false “non-negative” or “positive”.  This is unfortunately an element of the current testing protocols, and not particular to this Study.  Not all individuals who test positive for COVID-19 have symptoms, and many do not experience severe illness.  However, you may experience stress or anxiety upon receipt of a “non-negative” test result. If this is a concern, please consider your best interests in whether or not to participate. There is also the risk of a false “negative” result. Please be advised that receiving a negative result does not guarantee that you do not have COVID-19.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                If you receive a negative result, but have COVID-19-like symptoms, you need to take precautions. We recommend you complete the Ontario Ministry of Health online self-assessment found here: https://covid-19.ontario.ca/self-assessment/ and visit an assessment center if recommended.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                There is a risk of viewing the consent document on a personal electronic device (PED), especially if that PED is shared with other users, is lost, hacked or subject to a search warrant or subpoena.  Since you signed this consent using a PED, you will be able to download an electronic copy of the signed and dated document.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                There is the risk that you will find the testing technique uncomfortable. There are no other significant risks to you when participating in this Study. There may be other risks or side effects that are unforeseeable or not listed above.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                There is no additional reward available for any risks or inconveniences of participating in the Study.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <h1 class="c8" id="h.3y9wmxvtnlh2"><span class="c6 c13">Alternatives to participation</span></h1>
        <p class="c2">
            <span class="c1">
                This research study is for research purposes only. The only alternative is to not participate in this study. You may seek COVID-19 testing at an assessment centre if you meet the guidelines for clinical testing.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <h1 class="c8" id="h.l31adp67aoek"><span class="c6 c13">New findings</span></h1>
        <p class="c2">
            <span class="c1">
                Any new important information that is discovered during the study and which may influence your willingness to continue participation in the study will be provided to you.
            </span>
        </p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <h1 class="c16" id="h.9yqdzzsu425z"><span class="c6 c13">What are the benefits of being in the Study?</span></h1>
        <p class="c2"><span class="c1">There is no guarantee that you will benefit from your participation in this study.</span></p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                In the event your COVID-19 test returns “non-negative”, you have the benefit of MHL notifying you immediately. If your result is confirmed “positive”, you and those you have been in contact with may also have the benefit of Public Health contacting you to initiate procedures for contact tracing.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <h1 class="c16" id="h.6pri1ng9kli7"><span class="c6 c13">Who pays for this Study?</span></h1>
        <p class="c3">
            <span class="c1">
                The Study is conducted by MHL, a not-for-profit organization dedicated to COVID-19 research. The work is performed in partnership with researchers at the Research St. Joseph’s -  Hamilton.
            </span>
        </p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                This Study has been made possible through private funding from MHL and Birch Hill Equity Partners Management Inc.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <h1 class="c16" id="h.eeehdtxzjuta"><span class="c6 c13">Will I be paid?</span></h1>
        <p class="c2"><span class="c1">You will not be paid for taking part in this Study.</span></p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <h1 class="c8" id="h.gza43u2uj2ys"><span class="c6 c13">Costs</span></h1>
        <h1 class="c8" id="h.ma2gdph7k2vq">
            <span class="c1">
                There is no cost to you, your private medical insurance (if any), or the public health system for study participation. The study swabs and questionnaires will be provided at no charge for the duration of the study. 
            </span>
        </h1>
        <h1 class="c8" id="h.fmawb3iw8rmj"><span class="c1">&nbsp;</span></h1>
        <h1 class="c8" id="h.us8neuft0o6z"><span class="c6 c13">How will my safety be ensured?</span></h1>
        <p class="c2">
            <span class="c1">
                If you feel that you are from a vulnerable group with respect to COVID-19 effects (for example, senior citizen, immuno-compromised), please discuss your participation with the research team before consenting. You are under no obligation to participate and nothing bad will happen if you change your mind about participating in the research.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                You do need to follow all the requirements of Birch Hill Equity Partners Management Inc. company guidelines, and local public health authorities while taking part in the study.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2"><span class="c1">You are required to wear a face covering or cloth mask at Birch Hill Equity Partners Management Inc. locations.</span></p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2"><span class="c1">Wash your hands or use hand sanitizer regularly.</span></p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2"><span class="c1">Physical distancing will be maintained, at all times.</span></p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                Your personal contact information that is collected to contact you if you have a sample that tests positive is retained in order to follow up with you and/or conduct contact tracing if you may have been exposed to COVID-19 in the workplace. This contact information will be kept separate from data collected through the research study to allow for de-identification of the research data.
            </span>
        </p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <p class="c3">
            <span class="c1">
                You maintain your right to withdraw from the study at any time, including your research data (if applicable). If you do withdraw, we will continue to maintain your contact information and will only give it to Public Health if we are legally required. If you wish to withdraw from the study, please tell a member of the study team or the Principal Investigator. You may also simply stop collecting weekly samples and completing the questionnaires. 
            </span>
        </p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <p class="c3"><span class="c1">We cannot guarantee anonymity as the personal contact information identifies you as a participant.</span></p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <h1 class="c16" id="h.i109seqky2ax"><span class="c6 c13">What happens to the information collected about me?</span></h1>
        <p class="c2">
            <span class="c1">
                MHL will use the information collected about you for the purposes of the Study and for scientific research. This includes all specimens that are processed in the research lab and the clinical virology lab. The information will be stored on the secure Cloud platform on VERTO’s server.  To protect your privacy, the information will be labeled with a number that will not identify you, so your identity will be kept confidential. The research team will only access your information for the purposes of contacting you if you were to test non-negative for COVID-19. The information from all participants in the Study will be pooled and analyzed and if the results of the Study are published your identity will be kept confidential.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                All non-negative specimens and a random sample of negative specimens will be anonymized and retained for research and development.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                The Study team will keep your personal information (and a list that links you to your Study number) for at least 10 years following the completion or termination of the Study, as required by law for such research.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                The Research Ethics Board may access study information in order to ensure adherence to the study protocol (study plan) and the requirements in this consent form.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                If you decide to leave the Study at any time you have the option to withdraw and, at your request, we will delete all your personal contact information in the stored data.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                By signing and dating this document, you will not lose any of your legal rights or release anyone involved in the research from responsibility for mistakes. By signing and dating this form, you are agreeing to and permitting the use of your information for the purposes of the Study.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <h1 class="c16" id="h.79qendsy16wn"><span class="c6 c13">Can I change my mind?</span></h1>
        <p class="c3 c36"><span class="c1">&nbsp;</span></p>
        <p class="c3">
            <span class="c1">
                Your participation in this Study is completely voluntary.  You do not have to take part in this Study.  You can agree to be in the Study now and change your mind later.  You may stop your participation at any time.  If you wish to stop participating, you may notify the study team, or you may simply decide not to collect your next swab sample.  If you do not notify the study team that you no longer wish to participate, you will still continue to receive weekly reminders to collect your specimen and complete your weekly questionnaire. You completing these tasks is completely voluntary. If you want to stop receiving reminders, you will need to contact the study team.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <h1 class="c16" id="h.v8yx0m3jf2o5"><span class="c6 c13">Can I be removed from the Study?</span></h1>
        <p class="c2"><span class="c1">MHL has the right to remove you from the study at any time with or without your agreement if:</span></p>
        <p class="c2 c26"><span class="c19 c5">&middot;</span><span class="c18">&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c1">The Study is cancelled; or</span></p>
        <p class="c2 c26">
            <span class="c19 c5">&middot;</span><span class="c18">&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span class="c1">
                the Study believes that it is in your best interest for any reason.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <h1 class="c16" id="h.9cuplkv3r178"><span class="c6 c13">What happens after the Study is over or if I stop the Study early?</span></h1>
        <p class="c2">
            <span class="c1">
                If you decide to stop the Study early, we would ask that you let the study team know via the contact number below.
            </span>
        </p>
        <p class="c2">
            <span class="c1">
                After the Study is over, the study researchers and Birch Hill Equity Partners Management Inc. will be looking at the data to determine whether a workplace surveillance program with oral-nasal swabs is a feasible method of COVID-19 surveillance.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                The study investigators will prepare reports for publication in peer reviewed journals and will post the overall findings on the MHL website, where you will be able to learn about the results.
            </span>
        </p>
        <p class="c3"><span class="c6 c5">&nbsp;</span></p>
        <p class="c2">
            <span class="c1">
                The Study will provide you with a convenient and timely test of your COVID-19 infection status, monitored (through repeated testing) for an eight-week period.
            </span>
        </p>
        <p class="c3"><span class="c6 c5">&nbsp;</span></p>
        <h1 class="c16" id="h.cb5bo8qjtjzq"><span class="c6 c13">Whom to contact about this study</span></h1>
        <p class="c3"><span class="c1">If you have any questions at any time during the Study, please contact:</span></p>
        <p class="c3"><span class="c1">Study HelpDesk</span></p>
        <p class="c3"><span class="c1">pgill@mcmasterhealthlabs.ca</span></p>
        <p class="c3"><span class="c1">1-833-292-5856</span></p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <p class="c3">
            <span class="c1">
                A research ethics board (REB) is an independent committee established to help protect the rights of research subjects.  If you have any questions about your rights as a research subject, and/or concerns or complaints regarding this research study, contact:
            </span>
        </p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <p class="c3 c20"><span class="c19 c5">&middot;</span><span class="c18">&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c1">By mail:</span></p>
        <p class="c3 c25"><span class="c1">Study Subject Adviser</span></p>
        <p class="c3 c25"><span class="c1">Advarra IRB</span></p>
        <p class="c3 c25"><span class="c1">6940 Columbia Gateway Drive, Suite 110</span></p>
        <p class="c3 c25"><span class="c1">Columbia, MD 21046</span></p>
        <p class="c3 c20">
            <span class="c19 c5">&middot;</span><span class="c18">&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c5">or call </span><span class="c17 c5">toll free</span><span class="c1">: 877-992-4724</span>
        </p>
        <p class="c3 c20">
            <span class="c19 c5">&middot;</span><span class="c18">&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c5">or by </span><span class="c17 c5">email</span><span class="c5">: </span>
            <span class="c5 c39 c41">adviser@advarra.com</span>
        </p>
        <p class="c3"><span class="c6 c5">&nbsp;</span></p>
        <p class="c3"><span class="c5">Please reference the following number when contacting the Study Subject Adviser: </span><span class="c17 c5">Pro00048267</span><span class="c1">.</span></p>
        <p class="c3"><span class="c6 c5">&nbsp;</span></p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <h1 class="c16" id="h.plgw70wo6jje"><span class="c6 c13">CONSENT</span></h1>
        <p class="c2"><span class="c5 c6">If you consent to participating in the Study, please read and then sign and date below.</span></p>
        <p class="c2">
            <span class="c5">
                As noted above, this consent form contains important information.  It will help you decide if you want to take part in this Study.  You will have the option of downloading a copy of this signed and dated Participant Information and Informed Consent Form from www.MHLstudy.com at any time after you complete it. If you still have questions, please ask the study team who will be available in the registration area, before signing and dating this form. Otherwise if you would like to participate in this study, please check all boxes below and sign and date where indicated.
            </span>
        </p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c2"><span class="c1">&nbsp;</span></p>
        <p class="c3"><span class="c6 c5">NOTE: &nbsp;Consent will be electronic and the following will be in checkboxes:</span></p>
        <p class="c3"><span class="c6 c5">&nbsp;</span></p>
        <p class="c2 c26">
            <span class="c19 c5">&middot;</span><span class="c18">&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c1">I have read and understood all the pages of this information and consent form.</span>
        </p>
        <p class="c2 c26">
            <span class="c19 c5">&middot;</span><span class="c18">&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c1">It has been written in a language that I can read and I understand. </span>
        </p>
        <p class="c2 c26">
            <span class="c5 c19">&middot;</span><span class="c18">&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span class="c1">Based on this information, I volunteer to take part in this Study.</span>
        </p>
        <p class="c3"><span class="c6 c5">&nbsp;</span></p>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <a id="t.a4a008b66dbfc2ee294919e76a2c150dfb566075"></a><a id="t.2"></a>
        <table class="c10">
            <tbody>
                <tr class="c22">
                    <td class="c33" colspan="1" rowspan="1">
                        <p class="c3"><span class="c1">&nbsp;</span></p>
                        <p class="c3"><span style="position: relative;">&nbsp;${[
                          thisArg.patient.first_name,
                          thisArg.patient.last_name,
                        ]
                          .filter(Boolean)
                          .join(' ')}</span></p>
                    </td>
                    <td class="c40" colspan="1" rowspan="1">
                        <p class="c3"><span class="c1">&nbsp;</span></p>
                    </td>
                </tr>
                <tr class="c23">
                    <td class="c24" style="
    position: relative;
    top: 11px;
" colspan="1" rowspan="1">
                        <p class="c3"><span class="c1" style="
    position: relative;
    bottom: 12px;
">Full Legal Name of Participant</span></p>
                        <p class="c3"><span style="position: relative;">&nbsp;${[
                          thisArg.patient.first_name,
                          thisArg.patient.last_name,
                        ]
                          .filter(Boolean)
                          .join(' ')}</span></p>
                        <p class="c3"><span class="c1">________________________________________________________</span></p>
                        <p class="c3"><span class="c1">The name you normally use as well</span></p>
                    </td>
                    <td class="c40" colspan="1" rowspan="1">
                        <p class="c3"><span class="c1">&nbsp;</span></p>
                    </td>
                </tr>
                <tr class="c21">
                    <td class="c33" colspan="1" rowspan="1">
                        <p class="c3"><span class="c1">&nbsp;</span></p>
                    </td>
                    <td class="c44" colspan="1" rowspan="1">
                        <p class="c3"><span class="c1">&nbsp;</span></p>
                    </td>
                </tr>
                <tr class="c37">
                    <p class="c3"><span style="position: relative;top: 160px; left: 11px;">${thisArg._getSignature()}</span></p>
                    <td class="c24" colspan="1" rowspan="1">
                        <p class="c3"><span class="c1">Signature of Participant</span></p>
                    </td>
                    <td style="
    position: relative;
    bottom: 24px;
" class="c31" colspan="1" rowspan="1">
                        <p class="c3"><span class="c1">${thisArg.datePipe.transform(
                          thisArg.patient.created_at,
                          'yyyy-MM-dd'
                        )}</span></p>
                        <p class="c3"><span class="c1">Date (yyyy-mm-dd)</span></p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="c3"><span class="c1">&nbsp;</span></p>
        <p class="c3 c27"><span class="c4"></span></p>
    </body>
</html>
`;
};
