<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<mood-context
  *ngIf="
    [LandingPage.brown_labs, LandingPage.mhl_mccovid, LandingPage.mhl_general].includes(landingPageOverride);
    else deprecatedWayOfRendering
  "
  [state]="landingPageState"
>
</mood-context>

<!-- todo(aleksanderbodurri): Create factory configs to replace these -->
<ng-template #deprecatedWayOfRendering>
  <div class="landing-container">
    <div [ngSwitch]="landingPageOverride">
      <div *ngSwitchCase="LandingPage.gtaa">
        <app-gtaa-landing></app-gtaa-landing>
      </div>
      <div *ngSwitchCase="LandingPage.birch_hill">
        <app-birch-hill-landing></app-birch-hill-landing>
      </div>
      <div *ngSwitchCase="LandingPage.mhl_reassurance">
        <app-mhl-reassurance-landing></app-mhl-reassurance-landing>
      </div>
      <div *ngSwitchCase="LandingPage.reassurance_demo">
        <app-reassurance-demo-landing></app-reassurance-demo-landing>
      </div>
      <div *ngSwitchCase="LandingPage.vector_health">
        <app-vector-health-landing></app-vector-health-landing>
      </div>
      <div *ngSwitchCase="LandingPage.cpx_yourwellness">
        <app-cpx-yourwellness-landing></app-cpx-yourwellness-landing>
      </div>
      <div *ngSwitchCase="LandingPage.default">
        <h3 class="header">{{ this.translationService.translate(['pages', 'landing', 'title']) }}</h3>
        <mood-renderer [moodFrame]="rendererFrame"></mood-renderer>
      </div>
    </div>
  </div>
</ng-template>
