<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="mood-nav-container" *ngIf="visible">
  <div class="mood-nav-left">
    <div class="mood-nav-left-btn">
      <mood-button [buttonClasses]="['mood-custom']" (onClick)="menu.toggle($event)">Menu</mood-button>
      <p-menu #menu [popup]="true" [model]="routes"></p-menu>
    </div>
    <div class="mood-nav-paragraph" *ngIf="title">
      <mood-paragraph [content]="title"></mood-paragraph>
    </div>
  </div>
  <div class="mood-nav-right" *ngIf="rightButton">
    <mood-button
      [buttonClasses]="rightButton.buttonClasses"
      [disabled]="rightButton.disabled"
      (onClick)="rightButton.click()"
    >
      <img
        engage-asset
        [ngStyle]="rightButton.icon.style"
        src="{{ '/assets/icons/' + rightButton.icon.name + '.svg' }}"
      />
      <span [ngStyle]="rightButton.text.style">{{ rightButton.text.label() }}</span>
    </mood-button>
  </div>
</div>
