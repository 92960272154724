<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<h3 class="header">
  {{ this._translationService.translate(['pages', 'landing', 'header']) }}
</h3>
<mood-renderer
  [moodFrame]="rendererFrame"
  (formUpdated)="onUpdate($event)"
  (nestedFormUpdated)="onNestedUpdate($event)"
></mood-renderer>
