/* Copyright 2023 (Unpublished) Verto Inc. */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { EngageSerializableStoreService } from 'engage-utils';
import { switchMap } from 'rxjs/operators';
import { ChlHeaderService } from './chl-header.service';
import { ConfigurationService } from './configuration.service';

export interface Patient {
  cell_phone_number?: string;
  email?: string;
  phone_number?: string;
  first_name: string;
  last_name: string;
  healthcard?: string;
  preferred_language: string;
  recaptcha_key?: string;
  created_at?: string;
  country_code?: string;
  dependents?: Patient[];
  work_site?: string; // TODO(post-migration): Remove
  unit_external_identifier?: string;
  informed_consent_signature?: string;
}

export interface Verification {
  identifier: string;
  code: string;
}

export interface ContactUpdateRequest {
  email?: string;
  cell_phone_number?: string;
  country_code?: string;
}

export interface PatientResponse {
  access_token: string;
  patient: {
    cell_phone_number: string;
    email: string;
    first_name: string;
    last_name: string;
    preferred_language: string;
  };
}

export interface PatientKitsResponse {
  kits: Kit[];
}

export interface Questionnaire {
  completed_date?: string; // ISO datetime string (ex: new Date().toISOString() OR '2020-08-20T15:42:21.739Z' )
  responses: Object;
  type: string;
  key: string;
  attach_to: string;
}

export interface QuestionnaireResponse {
  questionnaires: Questionnaire[];
}

export interface PatientsResponse {
  id: string;
  first_name: string;
  last_name: string;
  kits?: Kit[];
  dependents?: PatientsResponse[];
}

export interface Kit {
  id: number;
  identifier: string;
  name: string;
  state_history: {
    date: string; // ISO datetime string (ex: new Date().toISOString() OR '2020-08-20T15:42:21.739Z' )
    name: string;
  }[];
  status?: string;
  created_at?: string; // ISO datetime string (ex: new Date().toISOString() OR '2020-08-20T15:42:21.739Z' )
}

export interface HttpOptions {
  // Angular's httpClient options
  body?: any;
  headers?: HttpHeaders | { [header: string]: string | string[] };
  observe?: 'body';
  params?: HttpParams | { [param: string]: string | string[] };
  reportProgress?: boolean;
  withCredentials?: boolean;
}

export const ACCESS_TOKEN_KEY = 't';
export const DEMOGRAPHICS_KEY = 'demographics';
export const DEPENDENT_KEY = 'dependent';
export const CONTACT_KEY = 'contact';
export const USER_PREFERENCES_KEY = 'user-preferences';
export const CONSENT_KEY = 'consent';

@Injectable({
  providedIn: 'root',
})
export class ChlPatientService {
  base = environment.backendUrl;
  token: string;
  patient: Patient;
  apiKey = environment.key;

  constructor(
    private _http: HttpClient,
    private _store: EngageSerializableStoreService,
    private _headerService: ChlHeaderService,
    private _configurationService: ConfigurationService
  ) {
    const enableNav = () => {
      this._headerService.visible.next(true);
    };

    this.token = this._store.retrieve(ACCESS_TOKEN_KEY);
    this.patient = this._store.retrieve('patient');

    if (this.patient) {
      enableNav();
    }

    this._store.onChange(ACCESS_TOKEN_KEY, (token: string) => (this.token = token));

    this._store.onChange('patient', (patient: Patient) => {
      this.patient = patient;
      if (this.token && this.patient) {
        enableNav();
      }
    });

    this._configurationService.getConfig().subscribe((res) => {
      this.apiKey = res.key;
    });
  }

  requestAuthOptions(token?: string): HttpOptions {
    return {
      headers: new HttpHeaders().set('Authorization', `Bearer ${token || this.token}`),
    };
  }

  register(patient: Patient): Observable<{}> {
    const url = `${this.base}/api/chl/register`;

    return this._http.post(url, { ...patient, key: this.apiKey });
  }

  verify(code: string, identifier: string) {
    const verification: Verification = { code, identifier };
    const url = `${this.base}/api/chl/verify`;

    return this._http.patch<PatientResponse>(url, { ...verification, key: this.apiKey });
  }

  updateContact(contactUpdate: ContactUpdateRequest): Observable<{}> {
    const url = `${this.base}/api/chl/contact`;

    return this._http.post(url, { ...contactUpdate, key: this.apiKey }, this.requestAuthOptions());
  }

  verifyContact(payload: { code: string }): Observable<{}> {
    const url = `${this.base}/api/chl/contact`;

    return this._http.patch(url, { ...payload, key: this.apiKey }, this.requestAuthOptions());
  }

  requestToken(contactInfo: {
    email?: string;
    cell_phone_number?: string;
    recaptcha_key?: string;
    country_code?: string;
  }): Observable<{ success: boolean }> {
    let { email, cell_phone_number, country_code } = contactInfo;

    if (!email && (!cell_phone_number || !country_code)) {
      return throwError({ error: { error_code: 'fields-not-entered' } });
    }

    const url = `${this.base}/api/patients/send_token`;

    return this._http.post<{ success: boolean }>(url, { key: this.apiKey, ...contactInfo });
  }

  retrieveInformation(token?: string): Observable<PatientResponse> {
    if (!this.token && !token) {
      return throwError('retrieveInformation requires a token');
    }

    const accessToken = token || this.token;

    const url = `${this.base}/api/patients/`;

    return this._http.get<PatientResponse>(url, this.requestAuthOptions(accessToken));
  }

  retrieveDependents(): Observable<PatientsResponse> {
    if (!this.token) {
      return throwError('retrieveDependents requires a token');
    }
    const patients_url = `${this.base}/api/chl/patient`;

    return this._http.get<PatientsResponse>(patients_url, this.requestAuthOptions());
  }

  retrieveDependent(patient_id: number): Observable<Patient> {
    if (!this.token) {
      return throwError('retrieveDependents requires a token');
    }

    const patients_url = `${this.base}/api/chl/patient`;

    return this._http.get<Patient>(patients_url, this.requestAuthOptions()).pipe(
      switchMap((res: Patient) => {
        if (res['id'] === patient_id) return of(res);
        return of(res.dependents.find((dependent) => dependent['id'] === patient_id));
      })
    );
  }

  getReport(encounterId: number, type: string): Observable<Blob> {
    const url = `${this.base}/api/chl/encounter/${encounterId}/forms/${type}/export`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      responseType: 'blob' as 'json',
      params: {
        key: this.apiKey,
      },
    };
    return this._http.get<Blob>(url, httpOptions);
  }

  getDocument(dataFileId: string): Observable<Blob> {
    const url = `${this.base}/api/chl/document/${dataFileId}`;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
      }),
      responseType: 'blob' as 'json',
      params: {
        key: this.apiKey,
      },
    };
    return this._http.get<Blob>(url, httpOptions);
  }

  retrieveKits(id: string): Observable<PatientKitsResponse> {
    const url = `${this.base}/api/chl/patient/${id}/kits`;
    let data = this.requestAuthOptions();
    data.params = { key: this.apiKey };

    return this._http.get<PatientKitsResponse>(url, data).pipe(
      switchMap((res: PatientKitsResponse) => {
        res.kits.sort((a, b) => +new Date(a.created_at) - +new Date(b.created_at)); // sort chronologically
        return of(res);
      })
    );
  }

  retrieveKit(identifier: string, patient_id: string): Observable<any> {
    const url = `${this.base}/api/chl/patient/${patient_id}/kits/${identifier}`;
    let data = this.requestAuthOptions();
    data.params = { key: this.apiKey };

    return this._http.get<any>(url, data).pipe(switchMap((res) => of(res.kits[0])));
  }

  registerKit(identifier: string, selected_patient_id?: number): Observable<PatientKitsResponse> {
    if (!this.token) {
      return throwError('registerKit requires a token');
    }
    let data = this.requestAuthOptions();
    data.params = { key: this.apiKey };

    const url = `${this.base}/api/chl/patient/${selected_patient_id}/kits`;
    return this._http.post<PatientKitsResponse>(url, { identifier }, data);
  }

  markKitAsAdministered(kit: Kit): Observable<any> {
    if (!this.token) {
      return throwError('markKitAsAdministered requires a token');
    }

    const url = `${this.base}/api/patients/kits/${kit.identifier}`;

    return this._http.patch<any>(url, { status: 'administered' }, this.requestAuthOptions());
  }

  retrieveCompletedQuestionnaires(attach_to: string): Observable<QuestionnaireResponse> {
    if (!this.token) {
      return throwError('retrieveCompletedQuestionnaires requires a token');
    }

    const url = `${this.base}/api/questionnaires?attach_to=${attach_to}`;

    return this._http.get<QuestionnaireResponse>(url, this.requestAuthOptions());
  }

  submitQuestionnaire(questionnaire: Questionnaire): Observable<Questionnaire> {
    if (!this.token) {
      return throwError('submitQuestionnaire requires a token');
    }

    const url = `${this.base}/api/questionnaires`;
    return this._http.post<Questionnaire>(url, questionnaire, this.requestAuthOptions());
  }

  exchangeToken(token: string): Observable<{ access_token: string }> {
    const url = `${this.base}/api/tokens/authorize`;

    return this._http.post<{ access_token: string }>(url, { engage_token: token });
  }

  logout(): void {
    const url = `${this.base}/api/tokens/revoke`;
    this._http.post<any>(url, {}, this.requestAuthOptions()).subscribe();
  }

  retrieveTestResult(resourceId: number): Observable<any> {
    const url = `${this.base}/api/chl/resources/${resourceId}/test_result`;
    let data = {
      params: {
        key: this.apiKey,
      },
    };
    return this._http.get<any>(url, data);
  }
}
