/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChlPatientService } from '../../services/chl-patient.service';
import { EngageSerializableStoreService } from 'engage-utils';
import { MessageService } from 'primeng/api';
import { TranslationService } from '../../services/translation.service';
import { ConfigurationService } from '../../services/configuration.service';
import { openDocument } from '../../utils/document-utilities';

@Component({
  selector: 'test-kit-details',
  templateUrl: './test-kit-details.component.html',
  styleUrls: ['./test-kit-details.component.scss'],
})
export class TestKitDetailsComponent implements OnInit {
  stateHistory = [];
  loaded = false;
  hideNameInKitHeader = false;

  getStateIcon(name: string): string {
    if (name === 'Results Ready') {
      return 'envelope';
    } else {
      return 'history';
    }
  }

  constructor(
    public translationService: TranslationService,
    private _store: EngageSerializableStoreService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _patientService: ChlPatientService,
    private _primeNgMessageService: MessageService,
    private _configurationService: ConfigurationService
  ) {}

  testKitId = '';
  parentId = -1;
  patientDisplayName = '';
  showShareButton = false;
  showExportButton = false;
  reportExportType = '';
  loadingExport = false;
  reportExportName = 'test-result';

  ngOnInit(): void {
    var showExportButton = false;
    this._configurationService.getConfig().subscribe((res) => {
      this.showShareButton = res.config.content.include_test_kit_share_button;
      showExportButton = res.config.content.include_test_kit_export_button;
      this.reportExportType = res.config.content.test_kit_report_type;
      this.reportExportName = res.config.content.test_kit_report_name;
      this.hideNameInKitHeader = res.config.content.hide_name_in_kit_header;
    });

    this._route.params.subscribe((params) => {
      const id = params.id;
      const patient_id = params.i;
      if (id) {
        this._patientService.retrieveDependent(patient_id).subscribe((response) => {
          this.patientDisplayName = `${response['first_name']} ${response['last_name']} `;
        });

        this._patientService.retrieveKit(id, patient_id).subscribe(
          (res) => {
            this.testKitId = `(${res.identifier})`;
            this.parentId = res.encounter_id;
            if (!res.state_history) {
              this._primeNgMessageService.add({
                severity: 'error',
                summary: '',
                detail: this.translationService.translate([
                  'pages',
                  'kitDetails',
                  'stateHistoryNotFound',
                ]),
              });

              return;
            }

            this.stateHistory = res.state_history.sort((a: any, b: any) => {
              return +new Date(b.date) - +new Date(a.date);
            });
            this.loaded = true;
            this.showExportButton = showExportButton;
          },
          () => {
            this._primeNgMessageService.add({
              severity: 'error',
              summary: '',
              detail: this.translationService.translate([
                'pages',
                'kitDetails',
                'couldNotRetrieveKit',
              ]),
            });
            this.loaded = true;
            this._router.navigate(['test-kits']);
          }
        );
      }
    });
  }

  exportKit() {
    this.loadingExport = true;
    this._primeNgMessageService.add({
      severity: 'info',
      summary: this.translationService.translate(['pages', 'kitDetails', 'downloadingExport']),
      detail: this.translationService.translate([
        'pages',
        'kitDetails',
        'downloadingExportDetails',
      ]),
      life: 4000,
    });
    this._patientService.getReport(this.parentId, this.reportExportType).subscribe(
      (pdfBinary) => {
        this.loadingExport = false;
        openDocument(pdfBinary, this.reportExportName);
      },
      () => {
        this.loadingExport = false;
        this._primeNgMessageService.add({
          severity: 'error',
          summary: '',
          detail: this.translationService.translate(['pages', 'kitDetails', 'exportFailed']),
        });
        this.loaded = true;
      }
    );
  }

  shareKit() {
    console.log(`share`);
  }

  containsResultNote(status: string): boolean {
    return this.translationService.translate(['pages', 'kitDetails', 'resultNote'])[status];
  }
}
