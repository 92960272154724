/* Copyright 2023 (Unpublished) Verto Inc. */

import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import {
  MoodMask,
  MoodRegexValidator,
  MoodRendererComponent,
  MoodRendererUnitType,
  MoodRenderFrame,
} from 'engage-common';
import { TranslationService } from '../services/translation.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  ChlPatientService,
  ContactUpdateRequest,
  USER_PREFERENCES_KEY,
} from '../services/chl-patient.service';
import { EngageSerializableStoreService } from 'engage-utils';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ConfigurationService } from '../services/configuration.service';
@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss'],
})
export class UserPreferencesComponent implements OnInit {
  constructor(
    private _translationService: TranslationService,
    private _store: EngageSerializableStoreService,
    private _patientService: ChlPatientService,
    private _router: Router,
    private _primeNgMessageService: MessageService,
    private _configurationService: ConfigurationService
  ) {}

  originalCellPhoneNumber = '';
  originalEmail = '';
  cellPhoneNumber = new UntypedFormControl(
    '',
    Validators.pattern(MoodRegexValidator.phoneNumber())
  );
  email = new UntypedFormControl('', Validators.pattern(MoodRegexValidator.email()));
  signInType?: 'sms' | 'email';

  cellPhoneNumberProps: {
    label: string;
    placeholder: string;
    altLabel: string;
  };
  countries = [];
  country_code_selected = null;
  mask = MoodMask.phone('delimited', false);
  emailProps: {
    label: string;
    placeholder: string;
    altLabel: string;
  };
  buttonProps: string;

  // loading information
  loading = true;
  sendingPhoneCode: boolean;
  sendingEmailCode: boolean;

  @ViewChild(MoodRendererComponent) renderer: MoodRendererComponent;

  ngOnInit(): void {
    this.sendingPhoneCode = false;
    this.sendingEmailCode = false;
    this._translationService.langObservable.subscribe(() => {
      this.cellPhoneNumberProps = this._translationService.translate([
        'pages',
        'user-preferences',
        'fields',
        'phone',
      ]);
      this.emailProps = this._translationService.translate([
        'pages',
        'user-preferences',
        'fields',
        'email',
      ]);
      this.buttonProps = this._translationService.translate([
        'pages',
        'user-preferences',
        'accessLinkButton',
      ]);
    });
    this._patientService.retrieveInformation().subscribe((response) => {
      this.originalCellPhoneNumber = response['cell_phone_number'];
      this.originalEmail = response['email'];
      this.loading = false;
    });
    this._configurationService.getConfig().subscribe((res) => {
      this.countries = res.config.content.country_codes.options;
      // Defaults to first country code on list (e.g. Canada for BirchHill config)
      this.country_code_selected = this.countries[0].value;
      if (res.config.content.hasOwnProperty('allowed_sign_in_type')) {
        this.signInType = res.config.content.allowed_sign_in_type;
      }
    });
  }

  onUpdate(updateValues): void {
    // Prepend cell phone number with one, to matched format expected by API
    if (updateValues.cell_phone_number) {
      updateValues.cell_phone_number = '1' + updateValues.cell_phone_number;
    }

    this._store.store(USER_PREFERENCES_KEY, updateValues);
  }

  sendValidationCode(contactToValidate: string) {
    let value;

    if (contactToValidate === 'email') {
      value = this.email.value;
      this.sendingEmailCode = true;
    } else if (contactToValidate === 'cell_phone_number') {
      value = this.cellPhoneNumber.value;
      this.sendingPhoneCode = true;
    }

    const contactPayload: ContactUpdateRequest = {
      [contactToValidate]: value,
    };

    if (this.country_code_selected) {
      contactPayload.country_code = this.country_code_selected;
    }

    const success = () => {
      this._router.navigate(['user-preferences-confirm']);
    };

    const error = (e) => {
      this.sendingEmailCode = false;
      this.sendingPhoneCode = false;
      const errorMessages = [];

      if (e.error?.errors?.email) {
        this.email.setErrors({ incorrect: true });
        errorMessages.push(
          this._translationService.translate(['pages', 'user-preferences', 'emailInvalid'])
        );
      }

      if (e.error?.errors?.cell_phone_number) {
        this.cellPhoneNumber.setErrors({ incorrect: true });
        errorMessages.push(
          this._translationService.translate(['pages', 'user-preferences', 'phoneInvalid'])
        );
      }

      if (errorMessages.length === 0) {
        errorMessages.push(
          this._translationService.translate([
            'pages',
            'user-preferences',
            'genericValidationError',
          ])
        );
      }

      errorMessages.forEach((message) => {
        this._primeNgMessageService.add({
          severity: 'error',
          summary: '',
          detail: message,
        });
      });
    };

    this._patientService.updateContact(contactPayload).subscribe(success, error);
  }

  rendererFrame: MoodRenderFrame = [
    {
      type: MoodRendererUnitType.HEADER,
      label: () => this._translationService.translate(['pages', 'user-preferences', 'header']),
      margin: ['0', '0', '12px', '0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () => this._translationService.translate(['pages', 'user-preferences', 'explanation']),
      margin: ['0.5rem', '0', '0', '0'],
    },
  ];
}
