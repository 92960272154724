/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'generic-dropdown',
  templateUrl: './generic-dropdown.component.html',
  styleUrls: ['./generic-dropdown.component.scss'],
})
export class GenericDropdownComponent {
  @Input() options: any[];
  @Input() label: string;
  @Input() required = false;
  @Input() placeholder: string;
  @Input() inputId = '';
  @Input() disable = false;
  @Output() valueChange = new EventEmitter<string>();
  @Output() triggerValidate = new EventEmitter<void>();
  currentValue: string;

  @Input()
  get value() {
    return this.currentValue;
  }

  set value(value) {
    this.currentValue = value;
    this.triggerValidate.emit();
    this.valueChange.emit(this.currentValue);
  }
}
