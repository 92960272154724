/* Copyright 2023 (Unpublished) Verto Inc. */

/**
 * Opens a document received in binary data in the browser
 * @param blob: document binary data
 * @param documentName: name of the document
 */
export function openDocument(blob: Blob, documentName: string) {
  const documentBlob = new Blob([blob], { type: 'application/pdf' });

  // IE doesn't allow using a blob object directly as link href
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(documentBlob);
    return;
  }

  // For other browsers: Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(documentBlob);

  var link = document.createElement('a');
  link.href = data;
  // TODO: extension
  link.download = `${documentName}.pdf`;

  // For firefox: this is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
}
