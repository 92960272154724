/* Copyright 2023 (Unpublished) Verto Inc. */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { defaultRoutes } from './routes/default-routes';
import { testKitRoutes } from './routes/test-kit-routes';
import { questionnaireRoutes } from './routes/questionnaire-route';
import { brownLabsRoutes } from './routes/brown-labs-routes';
import { vectorHealthRoutes } from './routes/vector-health-routes';
import { ResultComponent } from './result/result.component';
import { ResultDocumentComponent } from './result-document/result-document.component';

const routes: Routes = [
  ...defaultRoutes,
  ...testKitRoutes,
  ...questionnaireRoutes,
  ...brownLabsRoutes,
  ...vectorHealthRoutes,
  {
    path: 'result/:id',
    component: ResultComponent,
  },
  {
    path: 'result_document',
    component: ResultDocumentComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
