/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MoodRendererUnitType, MoodRenderFrame, MoodRendererComponent } from 'engage-common';
import { TranslationService } from '../services/translation.service';
import { ChlFooterService } from '../services/chl-footer.service';
import { ChlHeaderService } from '../services/chl-header.service';
import { EngageSerializableStoreService } from 'engage-utils';
import { Patient } from '../services/chl-patient.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { informedConsentForm } from './form';
import { mhlInformedConsentForm } from './overrides/mhl-reassurance/form';
import { PrintService } from '../services/print.service';
import { Validators } from '@angular/forms';
import { CustomInformedConsentForm } from './overrides/custom-informed-consent-forms.enum';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-informed-consent',
  templateUrl: './informed-consent.component.html',
  styleUrls: ['./informed-consent.component.scss'],
})
export class InformedConsentComponent implements OnInit, AfterViewInit {
  constructor(
    private _footerService: ChlFooterService,
    private _headerService: ChlHeaderService,
    private _translationService: TranslationService,
    private _configurationService: ConfigurationService,
    private _store: EngageSerializableStoreService,
    private _router: Router,
    public datePipe: DatePipe,
    private _printService: PrintService
  ) {}

  patient: Patient;
  printContent: string;
  consentFormOverride: CustomInformedConsentForm = CustomInformedConsentForm.default;
  consentForms = CustomInformedConsentForm;

  @ViewChild(MoodRendererComponent) renderer: MoodRendererComponent;

  ngOnInit(): void {
    this.patient = this._store.retrieve('patient');
    this._configurationService.getConfig().subscribe((app) => {
      this.consentFormOverride = app.config.content.informed_consent_form_override
        ? CustomInformedConsentForm[app.config.content.informed_consent_form_override]
        : CustomInformedConsentForm.default;
      this._translationService.langObservable.subscribe((_) => this._reloadFrame());
      switch (this.consentFormOverride) {
        case CustomInformedConsentForm.mhl_reassurance:
          this.printContent = mhlInformedConsentForm(this);
          break;
        default:
          this.printContent = informedConsentForm(this);
          break;
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const current = this.renderer?.children?.last;
      let values = {
        signature: this._getSignature(),
        date: this.datePipe.transform(this.patient.created_at, 'yyyy/MM/dd'),
      };
      if (this.consentFormOverride === CustomInformedConsentForm.mhl_reassurance) {
        for (let index = 0; index < 6; index++) {
          values['consent-form' + index] = true;
        }
      }
      current?.form?.patchValue(values);
      current?.form?.disable();
    });
  }

  _getSignature(): string {
    const { first_name, last_name, informed_consent_signature } = this.patient;
    return informed_consent_signature ? informed_consent_signature : first_name + ' ' + last_name;
  }

  currentDate() {
    const dp = new DatePipe('en');
    return dp.transform(new Date(), 'yyyy/MM/dd');
  }

  buildAccordions(
    accordions: { header: string; content: string[] }[],
    margin = ['0', '0', '1rem', '0']
  ): MoodRenderFrame {
    let result = [];
    accordions.forEach(({ header, content }) => {
      result.push({
        type: MoodRendererUnitType.ACCORDION,
        expanded: true,
        header: header,
        content: this.buildContent(content),
        margin: margin,
      });
    });
    return result;
  }

  buildContent(content: string[], margin = ['0', '0', '1rem', '0']): MoodRenderFrame {
    let result = [];
    content?.forEach((c) => {
      result.push({
        type: MoodRendererUnitType.PARAGRAPH,
        html: c,
        margin: margin,
      });
    });
    return result;
  }

  rendererFrame: MoodRenderFrame = [];
  private _reloadFrame() {
    let extraCheckBoxes: MoodRenderFrame = [];
    if (this.consentFormOverride === CustomInformedConsentForm.mhl_reassurance) {
      for (let index = 0; index < 6; index++) {
        extraCheckBoxes.push({
          type: MoodRendererUnitType.RADIO_GROUP,
          classes: ['custom-radio-row'],
          formData: {
            controlName: 'consent-form' + index,
            controlType: 'checkbox',
            options: [
              {
                label: this._translationService.translate([
                  'customization',
                  this.consentFormOverride,
                  'pages',
                  'informed-consent-form',
                  'consent',
                  'checkboxes',
                ])[index],
                value: true,
              },
            ],
            placeholder: 'true',
            validators: {
              required: false,
              minlength: 1,
            },
          },
          margin: ['0', '0', '1rem', '0'],
        });
      }
    }

    this.rendererFrame = [
      {
        type: MoodRendererUnitType.BUTTON,
        text: 'Export',
        buttonClasses: ['mood-primary', 'print-button'],
        click: () => this._printService.print.next(this.printContent),
        margin: ['0', '0', '1rem', '0'],
      },
      {
        type: MoodRendererUnitType.PARAGRAPH,
        html: this._translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'description',
          'paragraph',
        ]),
        margin: ['0', '0', '1rem', '0'],
      },
      {
        type: MoodRendererUnitType.PARAGRAPH,
        html: this._translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'description',
          'sponsor',
          'title',
        ]),
        margin: ['0', '0', '0', '0'],
      },
      ...this.buildContent(
        this._translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'description',
          'sponsor',
          'detail',
        ]),
        ['0', '0', '0', '0']
      ),
      {
        type: MoodRendererUnitType.PARAGRAPH,
        html: this._translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'description',
          'information',
          'investigator',
        ]),
        margin: ['1rem', '0', '0', '0'],
      },
      {
        type: MoodRendererUnitType.PARAGRAPH,
        html: this._translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'description',
          'information',
          'co-investigator',
        ]),
        margin: ['0', '0', '0', '0'],
      },
      {
        type: MoodRendererUnitType.PARAGRAPH,
        html: this._translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'description',
          'information',
          'collaborators',
        ]),
        margin: ['0', '0', '0', '0'],
      },
      {
        type: MoodRendererUnitType.PARAGRAPH,
        html: this._translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'description',
          'information',
          'more-collaborators',
        ]),
        margin: ['0', '0', '0', '0'],
      },
      ...this.buildAccordions(
        this._translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'accordions',
        ])
      ),
      {
        type: MoodRendererUnitType.ACCORDION,
        expanded: true,
        header: this._translationService.translate([
          'customization',
          this.consentFormOverride,
          'pages',
          'informed-consent-form',
          'consent',
          'header',
        ]),
        content: [
          ...this.buildContent(
            this._translationService.translate([
              'customization',
              this.consentFormOverride,
              'pages',
              'informed-consent-form',
              'consent',
              'content',
            ])
          ),
        ]
          .concat(extraCheckBoxes)
          .concat([
            {
              type: MoodRendererUnitType.INPUT,
              margin: ['6px', '0', '6px', '0'],
              label: () =>
                this._translationService.translate([
                  'customization',
                  this.consentFormOverride,
                  'pages',
                  'informed-consent-form',
                  'consent',
                  'signature',
                ]).label,
              formData: {
                placeholder: () =>
                  this._translationService.translate([
                    'customization',
                    this.consentFormOverride,
                    'pages',
                    'informed-consent-form',
                    'consent',
                    'signature',
                  ]).placeholder,
                controlName: 'signature',
                controlType: 'text',
                valueType: 'text',
                validators: {
                  validatorFunctions: [Validators.required],
                },
              },
            },
            {
              type: MoodRendererUnitType.INPUT,
              margin: ['6px', '0', '6px', '0'],
              label: () =>
                this._translationService.translate([
                  'customization',
                  this.consentFormOverride,
                  'pages',
                  'informed-consent-form',
                  'consent',
                  'date',
                ]).label,
              formData: {
                placeholder: () =>
                  this._translationService.translate([
                    'customization',
                    this.consentFormOverride,
                    'pages',
                    'informed-consent-form',
                    'consent',
                    'date',
                  ]).placeholder,
                controlName: 'date',
                mask: '9999/99/99',
                controlType: 'text',
                valueType: 'text',
                validators: {
                  validatorFunctions: [Validators.required],
                },
              },
            },
          ]),
      },
    ];
  }
}
