<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<h3 class="header">COVID-19 Testing Certificate</h3>
<div class="results-container">
  <div *ngIf="!loadingDocument; else spinner">
    <p>{{ message }}</p>
    <ngx-extended-pdf-viewer
      [src]="documentBlob"
      useBrowserLocale="true"
      height="100vh"
      [filenameForDownload]="reportExportName"
      [showDownloadButton]="true"
      [showOpenFileButton]="false"
      [showPropertiesButton]="false"
    ></ngx-extended-pdf-viewer>
  </div>
  <ng-template #spinner>
    <mood-spinner></mood-spinner>
  </ng-template>
</div>
