<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="sign-up-container">
  <div class="page-header">
    <mood-renderer [moodFrame]="rendererHeader"></mood-renderer>
  </div>
  <div *ngIf="demoConfig.length > 0">
    <div class="demographics-container" *ngIf="!loading; else spinner">
      <div class="demographics-header">
        {{ primaryContactHeader }}
      </div>
      <div class="demographics-content primary-contact-demographics">
        <mood-renderer
          [moodFrame]="rendererFrameDemo"
          [wrapperClasses]="['row']"
          (formUpdated)="onUpdate($event)"
        ></mood-renderer>
      </div>
    </div>
  </div>

  <div class="demographics-container" *ngFor="let frame of additionalFrames; index as i">
    <div class="demographics-header">Additional Patient {{ i + 1 }}</div>
    <div class="demographics-content">
      <mood-renderer [moodFrame]="frame" [wrapperClasses]="['row']" (formUpdated)="onUpdate($event, i)"></mood-renderer>
    </div>
  </div>

  <div class="additional-patients" *ngIf="dependentsEnabled">
    <button class="add-dependent-btn" (click)="addPatient()">+ Register additional member from your household</button>
  </div>

  <div *ngIf="quarantineConfig.length > 0">
    <div class="demographics-container" *ngIf="!loading; else spinner">
      <div class="demographics-header">
        {{ quarantineLocationHeader }}
      </div>
      <div class="demographics-content">
        <mood-renderer
          [moodFrame]="rendererFrameQuarantine"
          [wrapperClasses]="['row']"
          (formUpdated)="onUpdate($event)"
        ></mood-renderer>
      </div>
    </div>
  </div>

  <div *ngIf="arrivalConfig.length > 0">
    <div class="demographics-container" *ngIf="!loading; else spinner">
      <div class="demographics-header">
        {{ arrivalInfoHeader }}
      </div>
      <div class="demographics-content">
        <mood-renderer
          [moodFrame]="rendererFrameArrival"
          [wrapperClasses]="['row']"
          (formUpdated)="onUpdate($event)"
        ></mood-renderer>
      </div>
    </div>
  </div>
</div>
<ng-template #spinner>
  <mood-spinner></mood-spinner>
</ng-template>
