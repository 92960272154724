<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="kit-details-container">
  <div class="kit-details-header">
    <mood-header
      [label]="
        this.translationService.translate(['pages', 'kitDetails', 'header']) +
        (hideNameInKitHeader ? '' : patientDisplayName) +
        testKitId
      "
    ></mood-header>
  </div>
  <div class="kit-details-history" *ngIf="!loadingExport; else spinner">
    <div class="buttons">
      <mood-button
        class="button"
        *ngIf="this.showExportButton"
        [disabled]="this.loadingExport"
        (onClick)="this.exportKit()"
      >
        {{ this.translationService.translate(['pages', 'kitDetails', 'exportButton']) }}
      </mood-button>
      <mood-button class="button" *ngIf="this.showShareButton" (onClick)="this.shareKit()">
        {{ this.translationService.translate(['pages', 'kitDetails', 'shareButton']) }}
      </mood-button>
    </div>
    <div *ngFor="let item of stateHistory" class="card-container">
      <div class="card-status">
        <div class="card-icon">
          <img engage-asset src="{{ '/assets/icons/' + getStateIcon(item.name) + '.svg' }}" />
        </div>
        <div class="card-text">
          <span class="card-text-title">{{
            this.translationService.translate(['pages', 'kitDetails', 'states', item.name])
          }}</span>
          <span class="card-text-timestamp">
            {{ item.date | localizedDate : 'yyyy-MM-dd' }}
            {{ this.translationService.translate(['pages', 'kitDetails', 'dateAt']) }}
            {{ item.date | localizedDate : 'HH:mm' }}
          </span>
        </div>
      </div>
      <div *ngIf="containsResultNote(item.name)" class="card-details">
        <mood-paragraph
          *ngFor="let paragraph of this.translationService.translate(['pages', 'kitDetails', 'resultNote', item.name])"
          [content]="paragraph"
        ></mood-paragraph>
      </div>
    </div>
  </div>
  <ng-template #spinner>
    <mood-spinner></mood-spinner>
  </ng-template>
</div>
