/* Copyright 2023 (Unpublished) Verto Inc. */

import { FaqComponent } from '../faq/faq.component';
import { InformedConsentFormComponent } from '../informed-consent-form/informed-consent-form.component';
import { InformedConsentComponent } from '../informed-consent/informed-consent.component';
import { LandingComponent } from '../landing/landing.component';
import { LinkSentViewComponent } from '../link-sent/link-sent-view.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { DemographicsGuard } from '../services/demographics.guard';
import { NoTokenGuard } from '../services/no-token.guard';
import { TokenGuard } from '../services/token.guard';
import { SettingsComponent } from '../settings/settings.component';
import { SignInComponent } from '../sign-in/sign-in.component';
import { SignUpConfirmComponent } from '../sign-up-confirm/sign-up-confirm.component';
import { SignUpContactComponent } from '../sign-up-contact/sign-up-contact.component';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { StudyLinksComponent } from '../study-links/study-links.component';
import { TokenRedirectComponent } from '../token-redirect/token-redirect.component';
import { UserPreferencesConfirmComponent } from '../user-preferences-confirm/user-preferences-confirm.component';
import { UserPreferencesComponent } from '../user-preferences/user-preferences.component';
import { Routes } from '@angular/router';

export const defaultRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'landing',
  },
  {
    path: 'landing',
    component: LandingComponent,
    canActivate: [NoTokenGuard],
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [NoTokenGuard],
  },
  {
    path: 'login',
    pathMatch: 'full',
    redirectTo: 'sign-in',
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    canActivate: [NoTokenGuard],
  },
  {
    path: 'sign-up-contact',
    component: SignUpContactComponent,
    canActivate: [NoTokenGuard, DemographicsGuard],
  },
  {
    path: 'sign-up-confirm',
    component: SignUpConfirmComponent,
    canActivate: [NoTokenGuard],
  },
  {
    path: 'link-sent',
    component: LinkSentViewComponent,
    canActivate: [NoTokenGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [TokenGuard],
  },
  {
    path: 'informed-consent-form',
    component: InformedConsentFormComponent,
    canActivate: [NoTokenGuard],
  },
  {
    path: 'informed-consent',
    component: InformedConsentComponent,
    canActivate: [TokenGuard],
  },
  {
    path: 'user-preferences',
    component: UserPreferencesComponent,
    canActivate: [TokenGuard],
  },
  {
    path: 'user-preferences-confirm',
    component: UserPreferencesConfirmComponent,
    canActivate: [TokenGuard],
  },
  {
    path: 'study-links',
    component: StudyLinksComponent,
  },
  {
    path: 'l',
    component: TokenRedirectComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
];
