/* Copyright 2023 (Unpublished) Verto Inc. */

import { MoodRendererUnitType } from 'engage-common';
import { MoodState } from 'projects/engage-common/src/lib/mood/mood-state';

export const brownLabsLandingPageState = ({ translationService, router }): MoodState => {
  let nextDisabled: boolean = true;
  let nextUrl = '';
  return {
    rendererFrame: [
      {
        type: MoodRendererUnitType.HEADER,
        label: () => translationService.translate(['pages', 'landing', 'header']),
      },
      {
        type: MoodRendererUnitType.PARAGRAPH,
        html: () => translationService.translate(['pages', 'landing', 'registered']),
        margin: ['0', '0', '1rem', '0'],
      },
      {
        type: MoodRendererUnitType.RADIO_GROUP,
        classes: ['custom-radio-row'],
        formData: {
          controlName: 'registered',
          controlType: 'checkbox',
          options: [
            {
              label: () => translationService.translate(['options', 'yes']),
              value: '0',
            },
            {
              label: () => translationService.translate(['options', 'no']),
              value: '1',
            },
            {
              label: () => translationService.translate(['options', 'dontKnow']),
              value: '2',
            },
          ],
          validators: {
            required: true,
            minlength: 1,
          },
        },
        margin: ['0', '0', '1rem', '0'],
        change: ({ checked, radioButton }) => {
          nextDisabled = !checked;
          nextUrl = {
            '0': 'register-with-verto',
            '1': 'registration-info',
            '2': 'registration-info',
          }[radioButton.value];
        },
      },
      {
        type: MoodRendererUnitType.BUTTON,
        disabled: () => nextDisabled,
        buttonClasses: ['mood-primary', 'w-100'],
        text: () => translationService.translate(['buttons', 'continue']),
        click: () => router.navigate([nextUrl]),
      },
    ],
  };
};
