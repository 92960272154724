/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MoodRendererUnitType, MoodRenderFrame, MoodRendererComponent } from 'engage-common';
import { TranslationService } from '../../../services/translation.service';
import { Router } from '@angular/router';
import { ConfigurationService } from '../../../services/configuration.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-vector-health-landing',
  templateUrl: './vector-health-landing.component.html',
  styleUrls: ['./vector-health-landing.component.scss'],
})
export class VectorHealthLandingComponent implements OnInit {
  @ViewChild(MoodRendererComponent) renderer: MoodRendererComponent;

  constructor(
    public _translationService: TranslationService,
    private _router: Router,
    private _configurationService: ConfigurationService,
    private _primeNgMessageService: MessageService
  ) {}

  onUpdate(updateValues) {}

  onNestedUpdate(updateValues) {}

  ngOnInit(): void {
    this._router.navigate(['sign-in']);
  }

  onSignInClick() {
    this._router.navigate(['sign-in']);
  }

  rendererFrame: MoodRenderFrame = [
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: this._translationService.translate([
        'customization',
        'vector-health',
        'pages',
        'landing',
        'registered',
        'content',
      ]),
      margin: ['0', '0', '1rem', '0'],
    },
    {
      type: MoodRendererUnitType.BUTTON,
      buttonClasses: ['mood-primary', 'w-100'],
      text: () =>
        this._translationService.translate([
          'customization',
          'vector-health',
          'pages',
          'landing',
          'registered',
          'login-button',
        ]),
      click: () => this.onSignInClick(),
    },
  ];
}
