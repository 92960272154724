/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MoodRendererUnitType, MoodRenderFrame, MoodState } from 'engage-common';
import { ChlFooterService } from '../services/chl-footer.service';
import { EngageSerializableStoreService } from 'engage-utils';
import { Router } from '@angular/router';
import { TranslationService } from '../services/translation.service';
import { MessageService } from 'primeng/api';
import { ConfigurationService } from '../services/configuration.service';
import { LandingPage } from './overrides/custom-landing-pages.enum';
import { LandingPageStateFactory } from './overrides/landing-page-factory';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  landingPageOverride: LandingPage;
  LandingPage = LandingPage;
  landingPageState: MoodState;

  constructor(
    public translationService: TranslationService,
    private _footerService: ChlFooterService,
    private _store: EngageSerializableStoreService,
    public router: Router,
    public primeNgMessageService: MessageService,
    public configurationService: ConfigurationService
  ) {}

  ngOnInit() {
    this._footerService.visible.next(true);
    this._footerService.chlBrandingMode.next(true);
    this.configurationService.getConfig().subscribe((app) => {
      let landingPage = app.config?.content?.landing_page_override as LandingPage;

      if (landingPage === undefined) {
        throw new Error('Could not parse application config for landing page');
      }

      this.landingPageState =
        LandingPageStateFactory.getLandingPageState(landingPage).forComponent(this);

      // todo(aleksanderbodurri): remove this in favour of factory state config;
      this.landingPageOverride = app.config.content.landing_page_override
        ? LandingPage[app.config.content.landing_page_override]
        : LandingPage.default;

      // todo: find a better way to remove branding conditionally
      if (this.landingPageOverride === LandingPage.brown_labs) {
        this._footerService.chlBrandingMode.next(false);
        this._footerService.visible.next(false);
      }
    });
  }

  ngOnDestroy(): void {
    // Ensure store is clear when starting a new sign-up
    this._store.clear();
    this._footerService.visible.next(false);
    this._footerService.chlBrandingMode.next(false);
  }

  rendererFrame: MoodRenderFrame = [
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () => this.translationService.translate(['pages', 'landing', 'introduction']),
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.HEADER,
      label: () => this.translationService.translate(['pages', 'landing', 'registerHeader']),
      margin: ['0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () => this.translationService.translate(['pages', 'landing', 'register']),
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.BUTTON,
      buttonClasses: ['mood-primary', 'w-100', 'h-52'],
      text: () => this.translationService.translate(['pages', 'landing', 'registerButton']),
      click: () => this.router.navigate(['sign-up']),
      margin: ['0', '0', '20px', '0'],
    },
    {
      type: MoodRendererUnitType.HEADER,
      label: () => this.translationService.translate(['pages', 'landing', 'header']),
      margin: ['0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () => this.translationService.translate(['pages', 'landing', 'explanation']),
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.BUTTON,
      buttonClasses: ['mood-primary', 'w-100'],
      text: () => this.translationService.translate(['pages', 'landing', 'loginButton']),
      click: () => this.router.navigate(['sign-in']),
    },
  ];
}
