/* Copyright 2023 (Unpublished) Verto Inc. */

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EngageSerializableStoreService } from 'engage-utils';
import { DEMOGRAPHICS_KEY } from './chl-patient.service';

@Injectable({
  providedIn: 'root',
})
export class DemographicsGuard  {
  constructor(private _store: EngageSerializableStoreService, private _router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._store.retrieve(DEMOGRAPHICS_KEY)) {
      return true;
    }

    this._router.navigate(['sign-up'], {
      queryParams: next.queryParams,
      queryParamsHandling: null,
    });
    return false;
  }
}
