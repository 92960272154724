/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component } from '@angular/core';
import { MoodRendererUnitType, MoodRenderFrame } from 'engage-common';
import { TranslationService } from '../services/translation.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  constructor(private _translationService: TranslationService) {}

  rendererFrame: MoodRenderFrame = [];
  ngOnInit(): void {
    this.buildRenderFrame();
  }

  buildRenderFrame(): void {
    let content: MoodRenderFrame = [
      {
        type: MoodRendererUnitType.HEADER,
        label: this._translationService.translate(['pages', 'faq', 'header']),
      },
    ];

    Object.entries(this._translationService.translate(['pages', 'faq', 'questions'])).forEach(
      ([key, question]: [any, { q: string; a: string }]) => {
        if (key === 'faqFooter') return;

        let answers: MoodRenderFrame = [];
        Object.keys(question).forEach((answer) => {
          if (answer == 'q') return;
          answers.push({
            type: MoodRendererUnitType.PARAGRAPH,
            html: question[answer],
          });
        });

        content.push({
          type: MoodRendererUnitType.ACCORDION,
          expanded: false,
          header: question.q,
          content: answers,
          margin: ['1rem', '0', '0', '0'],
        });
      }
    );

    if (this._translationService.translate(['pages', 'faq', 'questions', 'faqFooter', 'info'])) {
      content.push({
        type: MoodRendererUnitType.PARAGRAPH,
        html: this._translationService.translate([
          'pages',
          'faq',
          'questions',
          'faqFooter',
          'info',
        ]),
        margin: ['2rem', '0', '0', '0'],
      });
    }

    this.rendererFrame = content;
  }
}
