/* Copyright 2023 (Unpublished) Verto Inc. */

export enum FieldTypeEnum {
  input = 'input',
  textarea = 'textarea',
  dropdown = 'dropdown',
  datepicker = 'datepicker',
  paragraph = 'paragraph',
  accordion = 'accordion',
  header = 'header',
  radiogroup = 'radiogroup',
  checkbox = 'checkbox',
  multi_select = 'multi_select',
  multi_checkbox = 'multi_checkbox',
}
