/* Copyright 2023 (Unpublished) Verto Inc. */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';

// Environment
import { environment } from './environments/environment';
import { environmentLoader } from './environments/environment-loader';

environmentLoader.then((env) => {
  if (env.production) {
    enableProdMode();
    environment.production = true;
  }
  environment.backendUrl = env.backendUrl;
  environment.testing = env.testing;
  environment.recaptchaSiteKey = env.recaptchaSiteKey;
  environment.key = env.key;
  environment.client = env.client;

  if (env.sentryDSN) {
    Sentry.init({
      dsn: env.sentryDSN,
    });
  }

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
});
