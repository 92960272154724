/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChlPatientService } from '../services/chl-patient.service';
import { EngageSerializableStoreService } from 'engage-utils';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs/operators';
import { TranslationService } from '../services/translation.service';
import { AppComponent } from '../app.component';
import { NavComponent } from '../utility-components/nav/nav.component';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-token-redirect',
  templateUrl: './token-redirect.component.html',
  styleUrls: ['./token-redirect.component.scss'],
})
export class TokenRedirectComponent implements OnInit {
  constructor(
    private _translationService: TranslationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _patientService: ChlPatientService,
    private _store: EngageSerializableStoreService,
    private _primeNgMessageService: MessageService,
    private _configurationService: ConfigurationService
  ) {}

  STORE_KEY = 't';

  ngOnInit(): void {
    this._route.queryParams.subscribe((params) => {
      if (!params.hasOwnProperty('t')) {
        this._errorRedirect(
          this._translationService.translate(['pages', 'tokenRedirect', 'invalidToken'])
        );
        return;
      }

      const engageToken = params.t;
      const redirect = params.p;
      const questionnaires = params.filter;

      this._patientService.exchangeToken(engageToken).subscribe(
        (exchangeResult) => {
          const { access_token } = exchangeResult;

          if (!access_token) return;

          this._store.store('filter', questionnaires);

          this._store.store(this.STORE_KEY, access_token);

          this._patientService.retrieveInformation(access_token).subscribe(
            (patient) => {
              this._store.store('patient', patient);
              this._configurationService
                .getConfig()
                .pipe(first())
                .subscribe((app) => {
                  let default_page = 'test-kits';
                  if (app.config.content.default_page)
                    default_page = app.config.content.default_page;
                  setTimeout(() =>
                    // override default routing options to replace params with a version without the token and redirect
                    this._router.navigate([redirect ?? default_page], {
                      queryParams: { ...params, t: null, p: null },
                      queryParamsHandling: 'merge',
                    })
                  );
                });
            },
            () =>
              this._errorRedirect(
                this._translationService.translate([
                  'pages',
                  'tokenRedirect',
                  'errorAccountInformation',
                ])
              )
          );
        },
        () => {
          this._errorRedirect(
            this._translationService.translate(['pages', 'tokenRedirect', 'invalidToken'])
          );
        }
      );
    });
  }

  private _errorRedirect(message: string) {
    this._primeNgMessageService.add({
      severity: 'error',
      summary: '',
      detail: message,
    });
    this._router.navigate(['sign-in']);
  }
}
