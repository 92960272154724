/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit } from '@angular/core';
import { MoodTableSettingsModel } from '../../../../../engage-common/src/lib/mood/mood-table/mood-table-settings.models';
import { MoodTableCellType } from '../../../../../engage-common/src/lib/mood/mood-table/mood-table-cell-type.enum';
import { MoodButtonDataModel } from '../../../../../engage-common/src/lib/mood/mood-table/mood-table-button-data.model';
import { ChlPatientService, PatientsResponse } from '../../services/chl-patient.service';
import { Router } from '@angular/router';
import { EngageSerializableStoreService } from 'engage-utils';
import { MessageService } from 'primeng/api';
import { ChlHeaderService } from '../../services/chl-header.service';
import { DatePipe } from '@angular/common';
import { TranslationService } from '../../services/translation.service';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'test-kits-list',
  templateUrl: './test-kit-list.component.html',
  styleUrls: ['./test-kit-list.component.scss'],
})
export class TestKitListComponent implements OnInit {
  testKitListSettings: MoodTableSettingsModel = {
    columnHeadersShow: true,
    messages: {
      empty: 'Click "Add New Kit" to track your first test kit',
    },
    fields: [
      {
        name: 'testKitId',
        label: 'Test Kit ID',
        type: MoodTableCellType.SINGLE_TEXT,
        itemClasses: ['text-large', 'pd-tb-10', 'center'],
        itemStyle: {
          'min-width': '190px',
        },
      },
      {
        names: ['status', 'timestamp'],
        label: 'Status',
        type: MoodTableCellType.MULTI_TEXT,
        headerClasses: ['center'],
        itemStyle: {
          'min-width': '172px',
        },
      },
      {
        name: 'id',
        label: 'Action',
        type: MoodTableCellType.BUTTON,
        headerClasses: ['center'],
        itemClasses: ['center'],
        itemStyle: {
          'min-width': '190px',
        },
        moodButton: {
          disabled: false,
          text: {
            style: {
              'font-size': '18px',
            },
          },
          icon: {
            style: {
              'margin-right': '10px',
              'vertical-align': 'text-top',
              width: '18px',
              height: '18px',
            },
          },
        },
      },
    ],
  };

  // Page Data Placeholder
  testKitListData = [];
  patientKitData = [];
  showNewKitButton = true;

  openTestKit(identifier, patient_id: number): void {
    this._router.navigate(['test-kit-details', identifier, { i: patient_id }]);
  }

  addNewKit(patient: {}): void {
    this._router.navigate(['add-new-kit', { i: patient['id'] }]);
  }

  logout(): void {
    this._store.store('t', undefined).store('patient', undefined);
    this._router.navigate(['landing']);
  }

  constructor(
    private _patientService: ChlPatientService,
    private _router: Router,
    private _store: EngageSerializableStoreService,
    private _primeNgMessageService: MessageService,
    private _headerService: ChlHeaderService,
    private _datePipe: DatePipe,
    public translationService: TranslationService,
    private _configurationService: ConfigurationService
  ) {}

  loaded = false;

  togglePatientKits(patient) {
    if (patient.show) {
      patient.show = false;
      return;
    } else if (patient.hasOwnProperty('kitsData')) {
      patient.show = true;
      return;
    }

    const statusMap = {
      assigned: this.translationService.translate(['pages', 'testKitList', 'states', 'Assigned']),
      'retesting-required': this.translationService.translate([
        'pages',
        'testKitList',
        'states',
        'Retesting Required',
      ]),
      administered: this.translationService.translate([
        'pages',
        'testKitList',
        'states',
        'Administered',
      ]),
      created: this.translationService.translate(['pages', 'testKitList', 'states', 'Created']),
      arrived: this.translationService.translate([
        'pages',
        'testKitList',
        'states',
        'Arrived in Lab',
      ]),
      negative: this.translationService.translate(['pages', 'testKitList', 'resultsReady']),
      'non-negative': this.translationService.translate(['pages', 'testKitList', 'resultsReady']),
      'negative-presumptive': this.translationService.translate([
        'pages',
        'testKitList',
        'resultsReady',
      ]),
      positive: this.translationService.translate(['pages', 'testKitList', 'resultsReady']),
      not_received: this.translationService.translate([
        'pages',
        'testKitList',
        'states',
        'Not Received',
      ]),
      invalid: this.translationService.translate(['pages', 'testKitList', 'states', 'Invalid']),
      defective: this.translationService.translate(['pages', 'testKitList', 'states', 'Defective']),
      indeterminate: this.translationService.translate([
        'pages',
        'testKitList',
        'states',
        'Indeterminate',
      ]),
      pending: this.translationService.translate([
        'pages',
        'testKitList',
        'states',
        'Arrived in Lab',
      ]),
      extracted: this.translationService.translate(['pages', 'testKitList', 'states', 'Extracted']),
      protected: this.translationService.translate(['pages', 'testKitList', 'resultsReady']),
      unprotected: this.translationService.translate(['pages', 'testKitList', 'resultsReady']),
      insufficient: this.translationService.translate(['pages', 'testKitList', 'resultsReady']),
    };

    this._patientService.retrieveKits(patient.info.id).subscribe(
      (kits) => {
        let testKitListData = [];
        kits?.kits?.forEach((kit) => {
          let status = kit['status'];
          let timestamp = kit['created_at'];

          testKitListData.push({
            id: kit.id,
            testKitId: kit.identifier,
            status: statusMap[status] ?? status,
            timestamp: this._datePipe.transform(timestamp, 'yyyy-MM-dd h:mmaa O'),
            button: {
              text:
                statusMap[status] ===
                this.translationService.translate(['pages', 'testKitList', 'resultsReady'])
                  ? this.translationService.translate(['pages', 'testKitList', 'viewResults'])
                  : this.translationService.translate(['pages', 'testKitList', 'viewHistory']),
              icon:
                statusMap[status] ===
                this.translationService.translate(['pages', 'testKitList', 'resultsReady'])
                  ? 'envelope'
                  : 'history',
              click: () => this.openTestKit(kit.id, patient.info.id),
            } as MoodButtonDataModel,
          });
        });

        patient.kitsData = testKitListData;
        patient.kits = kits.kits;
        patient.show = true;
      },
      () => {
        this._primeNgMessageService.add({
          severity: 'error',
          summary: '',
          detail: this.translationService.translate([
            'pages',
            'testKitList',
            'couldNotRetrieveKits',
          ]),
        });
        patient.show = false;
        this.loaded = true;
      }
    );
  }

  storePatientData(patient) {
    this.patientKitData.push({
      info: {
        id: patient['id'],
        name: `${patient['first_name']} ${patient['last_name']}`,
      },
      show: false,
      button: {
        disabled: false,
        text: {
          label: `Add a New Test Kit for ${patient['first_name']} ${patient['last_name']}`,
        },
        click: () => this.addNewKit(patient),
      },
    });
  }

  sortKits(kits) {
    return kits.sort((a, b) => {
      return this.formatDate(b.timestamp).getTime() - this.formatDate(a.timestamp).getTime();
    });
  }

  formatDate(date) {
    let day = date.split(' ');

    if (day[1].includes('PM') && !day[1].startsWith('12')) {
      day[1] =
        parseInt(day[1].substring(0, day[1].indexOf(':'))) +
        12 +
        day[1].substring(day[1].indexOf(':')).replace('PM', '');
    } else {
      day[1] = day[1].replace('PM', '').replace('AM', '');
    }
    return new Date(day.join(' '));
  }

  ngOnInit(): void {
    this._configurationService.getConfig().subscribe((res) => {
      if (res.config.content.hasOwnProperty('hide_add_test_kit_button')) {
        this.showNewKitButton = !res.config.content.hide_add_test_kit_button;
      }
    });

    this._patientService.retrieveDependents().subscribe(
      (res) => {
        this.patientKitData = [];

        this.storePatientData(res);
        this.togglePatientKits(this.patientKitData[0]);

        res['dependents']?.forEach((dependent) => {
          this.storePatientData(dependent);
        });

        this.loaded = true;
      },
      () => {
        this._primeNgMessageService.add({
          severity: 'error',
          summary: '',
          detail: this.translationService.translate([
            'pages',
            'testKitList',
            'couldNotRetrieveKits',
          ]),
        });
        this.loaded = true;
      }
    );
  }
}
