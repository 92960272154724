/* Copyright 2023 (Unpublished) Verto Inc. */

import { Environment } from './environment-interface';

export const environment: Environment = {
  production: true,
  backendUrl: '',
  frontendUrl: '',
  key: '',
  client: '',
  testing: false,
};
