/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'generic-p-toast',
  templateUrl: './generic-p-toast.component.html',
  styleUrls: ['./generic-p-toast.component.scss'],
})
export class GenericPToastComponent {
  @Input() position: string;
}
