/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { ChlPatientService } from '../services/chl-patient.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
})
export class ResultComponent implements OnInit {
  kit;

  patientInfo = [];
  resourceInfo = [];
  validStatuses = ['positive', 'negative', 'negative-presumptive'];

  constructor(
    private patientService: ChlPatientService,
    private _route: ActivatedRoute,
    private _primeNgMessageService: MessageService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this._route.params.subscribe((params) => {
      this.patientService.retrieveTestResult(params.id).subscribe(
        (res) => {
          this.kit = res;

          let patient = this.kit.patient;
          this.patientInfo = [
            {
              label: 'Client Name:',
              value: (patient?.first_name || '') + ' ' + (patient?.last_name || ''),
            },
            {
              label: 'Date of Birth:',
              value: patient?.dob,
            },
            {
              label: 'Home Address:',
              value: [patient?.address_one, patient?.city, patient?.province, patient?.postal_code]
                .filter((info) => info)
                .join(', '),
              fullWidth: true,
            },
            {
              label: 'Phone Number',
              value: patient?.cell_phone_number
                ? patient?.cell_phone_number?.slice(0, 3) +
                  '-' +
                  patient?.cell_phone_number?.slice(3, 6) +
                  '-' +
                  patient?.cell_phone_number?.slice(6)
                : '',
            },
            {
              label: 'Email',
              value: patient?.email,
            },
            {
              label: 'Travel Document',
              value: this.kit?.travel_document_number,
            },
          ];

          this.resourceInfo = [
            {
              label: 'Encounter ID:',
              value: this.kit?.encounter_id,
            },
            {
              label: 'Unit Name:',
              value: this.kit?.unit?.name,
            },
            {
              label: 'Date/Time of Sample Collection:',
              value: this.datePipe.transform(this.kit?.created_at, 'medium'),
            },
            {
              label: 'Date/Time of Result:',
              value: this.validStatuses.includes(this.kit?.status)
                ? this.datePipe.transform(this.kit?.updated_at, 'medium')
                : '',
            },
            {
              label: 'Equipment approved by Health Canada:',
              value: this.kit?.equipment_code,
              fullWidth: true,
            },
            {
              label: 'Test Type:',
              value: this.kit?.test_type,
              fullWidth: true,
            },
            {
              label: 'Collection Method:',
              value: this.kit?.swab_method,
            },
            {
              label: 'Performed by',
              value: this.kit?.nurse_name,
            },
          ];

          if (!this.validStatuses.includes(this.kit?.status)) {
            this.kit.status = 'Result Not Available';
          }
        },
        (err) => {
          if (err.status === 404) {
            this._primeNgMessageService.add({
              severity: 'error',
              summary: '',
              detail: 'Unable to find the resource.',
            });
          } else {
            this._primeNgMessageService.add({
              severity: 'error',
              summary: '',
              detail: 'An error occured while retrieving the resource.',
            });
          }
        }
      );
    });
  }
}
