<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="kits-container">
  <div class="kits-header">
    <div class="header">
      <mood-header label="{{ this.translationService.translate(['pages', 'testKitList', 'header']) }}"></mood-header>
    </div>
  </div>
  <div *ngIf="loaded; else spinner" class="patient-list">
    <div *ngFor="let patient of patientKitData">
      <div *ngIf="patient.kits; else kitsloading">
        <mood-table
          *ngIf="patient.kits.length > 0"
          class="kits-table"
          [tableSettings]="testKitListSettings"
          [data]="sortKits(patient.kitsData)"
        >
        </mood-table>
      </div>
      <ng-template #kitsloading>
        <mood-spinner></mood-spinner>
      </ng-template>
      <div class="add-new-btn" *ngIf="showNewKitButton">
        <mood-button [disabled]="patient.button.disabled" (onClick)="patient.button.click()">
          <span class="patient-btn-text">{{ patient.button.text.label }}</span>
        </mood-button>
      </div>
    </div>
  </div>
  <ng-template #spinner>
    <mood-spinner></mood-spinner>
  </ng-template>
</div>
