<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div>
  <h3 class="header">
    {{
      translationService.translate(['customization', consentFormOverride, 'pages', 'informed-consent-form', 'header'])
    }}
  </h3>
  <mood-renderer
    [moodFrame]="rendererFrame"
    (formUpdated)="onUpdate($event)"
    (nestedFormUpdated)="onNestedUpdate($event)"
  ></mood-renderer>
</div>
