/* Copyright 2023 (Unpublished) Verto Inc. */

import { Injectable } from '@angular/core';

import { MoodRendererUnit, MoodRendererUnitType } from 'engage-common';

import { ExtraFieldConfig, FieldConfig } from './configuration/field-config';
import { TranslationService } from './translation.service';
import { FieldTypeEnum } from './configuration/field-type.enum';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MoodFactoryService {
  // The irony of an adapter between Mood and another declarative format is not lost on me
  // We should try to avoid reproducing the complexity this is trying to abstract away
  constructor(private _translationService: TranslationService) {}

  buildMoodUnit(
    field: FieldConfig,
    hideCondition?: () => boolean,
    validatorFuncs?: any[],
    extraConfig?: ExtraFieldConfig
  ): MoodRendererUnit {
    let hide = () => false;
    if (field.hidden) {
      hide = hideCondition;
    }

    switch (field.controlType) {
      case FieldTypeEnum.paragraph:
        return this.generateParagraph(field, hide);
      case FieldTypeEnum.input:
        return this.generateInput(field, hide, validatorFuncs);
      case FieldTypeEnum.textarea:
        return this.generateTextArea(field, hide);
      case FieldTypeEnum.dropdown:
        return this.generateDropdown(field, hide);
      case FieldTypeEnum.datepicker:
        return this.generateDatePicker(field, hide);
      case FieldTypeEnum.accordion:
        return this.generateAccordion(field, hide);
      case FieldTypeEnum.header:
        return this.generateTitle(field, extraConfig.questions_starting_page, hide);
      case FieldTypeEnum.radiogroup:
        return this.generateRadioGroup(field, hide);
      case FieldTypeEnum.multi_checkbox:
        return this.generateMultiCheckBox(field);
      case FieldTypeEnum.checkbox:
        return this.generateCheckbox(field, hide);
      case FieldTypeEnum.multi_select:
        return this.generateMultiSelect(field, hide);
    }
  }

  generateCheckbox(field: FieldConfig, hideCondition?: () => boolean): MoodRendererUnit {
    return {
      type: MoodRendererUnitType.CHECKBOX,
      margin: field.margin,
      padding: field.padding,
      formData: {
        controlName: field.key,
        controlType: 'checkbox',
        options: [
          {
            label: () => this._translationService.translate(['fields', field.key]).label,
            value: 'true',
          },
        ],
        validators: { required: field.required },
      },
    };
  }

  generateRadioGroup(field: FieldConfig, hideCondition?: () => boolean): MoodRendererUnit {
    return {
      type: MoodRendererUnitType.RADIO_GROUP,
      margin: field.margin,
      padding: field.padding,
      hidden: hideCondition,
      formData: {
        controlName: field.key,
        controlType: 'checkbox',
        options: field.options || [
          {
            label: () => this._translationService.translate(['fields', field.key]).label,
            value: 'true',
          },
        ],
        validators: {},
      },
    };
  }

  generateMultiCheckBox(field: FieldConfig): MoodRendererUnit {
    return {
      type: MoodRendererUnitType.MULTI_CHECKBOX,
      margin: field.margin,
      padding: field.padding,
      formData: {
        controlName: field.key,
        controlType: 'checkbox',
        options: field.options || [
          {
            label: () => this._translationService.translate(['fields', field.key]).label,
            value: 'true',
          },
        ],
        validators: {},
      },
    };
  }

  generateTitle(
    field: FieldConfig,
    questions_starting_page: number = 0,
    hideCondition?: () => boolean
  ): MoodRendererUnit {
    function getQuestionNumber(currentPage: number, questions_starting_page: number): string {
      if (currentPage >= questions_starting_page) {
        return currentPage - questions_starting_page + 1 + '. ';
      }
      return '';
    }

    return {
      type: MoodRendererUnitType.HEADER,
      label: () =>
        `${getQuestionNumber(field.page, questions_starting_page)}` +
        this._translationService.translate(['text', field.key]),
    };
  }

  generateParagraph(field: FieldConfig, hideCondition?: () => boolean): MoodRendererUnit {
    return {
      type: MoodRendererUnitType.PARAGRAPH,
      margin: field.margin,
      hidden: hideCondition,
      html: () => this._translationService.translate(['text', field.key]),
    };
  }

  generateAccordion(field: FieldConfig, hideCondition?: () => boolean): MoodRendererUnit {
    return {
      type: MoodRendererUnitType.ACCORDION,
      expanded: false,
      header: () => this._translationService.translate(['text', field.key]).header,
      content: [
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: () => this._translationService.translate(['text', field.key]).content,
        },
      ],
      margin: field.margin,
    };
  }

  generateInput(
    field: FieldConfig,
    hideCondition?: () => boolean,
    validatorFuncs?: any[]
  ): MoodRendererUnit {
    let label = this._translationService.translate(['fields', field.key]);

    return {
      type: MoodRendererUnitType.INPUT,
      margin: field.margin,
      padding: field.padding,
      hidden: hideCondition,
      label: () => (field.required ? label.label + (label.required || '') : label.label),
      gridClass: field.gridClass,
      formData: {
        placeholder: () => this._translationService.translate(['fields', field.key]).placeholder,
        controlName: field.key,
        controlType: field.inputType || 'text',
        valueType: 'text',
        validators: {
          required: field.required,
          validatorFunctions: validatorFuncs ? validatorFuncs : [],
        },
        mask: field.mask,
      },
    };
  }

  generateTextArea(field: FieldConfig, hideCondition?: () => boolean): MoodRendererUnit {
    return {
      type: MoodRendererUnitType.TEXTAREA,
      margin: field.margin,
      padding: field.padding,
      hidden: hideCondition,
      maxLength: field?.maxLength,
      label: () => this._translationService.translate(['fields', field.key]).label,
      gridClass: field.gridClass,
      formData: {
        placeholder: () => this._translationService.translate(['fields', field.key]).placeholder,
        controlName: field.key,
      },
    };
  }

  generateDropdown(field: FieldConfig, hideCondition?: () => boolean): MoodRendererUnit {
    let label = this._translationService.translate(['fields', field.key]);

    return {
      type: MoodRendererUnitType.DROPDOWN,
      margin: field.margin || [],
      padding: field.padding || [],
      hidden: hideCondition,
      gridClass: field.gridClass,
      label: () => (field.required ? label.label + (label.required || '') : label.label),
      formData: {
        controlName: field.key,
        controlType: 'text',
        placeholder: this._translationService.translate(['fields', field.key]).placeholder,
        options: field.options,
        validators: {
          required: field.required,
        },
      },
    };
  }

  generateDatePicker(field: FieldConfig, hideCondition?: () => boolean): MoodRendererUnit {
    return {
      type: MoodRendererUnitType.DATE_PICKER,
      gridClass: field.gridClass,
      margin: field.margin,
      padding: field.padding,
      formData: {
        controlName: 'today',
        validators: {},
      },
    };
  }

  generateMultiSelect(field: FieldConfig, hideCondition?: () => boolean): MoodRendererUnit {
    return {
      type: MoodRendererUnitType.MULTI_SELECT,
      margin: field.margin || [],
      padding: field.padding || [],
      hidden: hideCondition,
      gridClass: field.gridClass,
      label: () => this._translationService.translate(['fields', field.key]).label,
      formData: {
        controlName: field.key,
        controlType: 'text',
        placeholder: this._translationService.translate(['fields', field.key]).placeholder,
        options: field.options,
        validators: {
          required: field.required,
        },
      },
    };
  }
}
