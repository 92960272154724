/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { EngageSerializableStoreService } from 'engage-utils';
import { Router } from '@angular/router';
import { ChlHeaderService } from '../../services/chl-header.service';
import { TranslationService } from '../../services/translation.service';
import { ChlPatientService, Patient } from '../../services/chl-patient.service';
import { ConfigurationService } from '../../services/configuration.service';
import { RouteConfig } from '../../services/configuration/route-config';

interface NavRoute {
  label: string;
  routerLink: string; // path
  queryParamsHandling: 'preserve' | 'merge';
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
  // This class had to be ported over from MoodModule for 2 reasons
  // 1. The version in MoodModule is not configurable and has navigation elements which are specific
  // to MHL
  // 2. The version in MoodModule imports services from the MHL project, which is not compatible with
  // the current project
  // TODO: Menu component should be extracted and made generic for use in future projects

  constructor(
    private _headerService: ChlHeaderService,
    private _patientService: ChlPatientService,
    private _store: EngageSerializableStoreService,
    private _router: Router,
    private _translationService: TranslationService,
    private _configurationService: ConfigurationService
  ) {}

  get welcome(): string {
    return this._translationService.translate(['nav', 'welcome']);
  }

  visible = false;
  title: string;

  _subscriptions: Subscription[] = [];

  routes: NavRoute[] = [];

  routeToIconMap = {
    'test-kits': 'lab',
    'informed-consent': 'consent',
    'test-kits-overview': 'home-page',
    'study-links': 'link',
  };

  patient: Patient;

  rightButton = {
    buttonClasses: ['mood-default'],
    disabled: false,
    text: {
      label: () => this._translationService.translate(['nav', 'logout']),
      style: {
        'font-size': '15px',
        color: '#000000',
      },
    },
    icon: {
      name: 'logout',
      style: {
        'margin-right': '0.45rem',
        'font-size': '12px',
      },
    },
    click: () => this.logout(),
  };

  loadNavRoutes() {
    this._configurationService.getConfig().subscribe((app) => {
      this.routes = app.config.content.routes.map((route) => this._createRoute(route));
    });
  }

  private _createRoute(routeConfig: RouteConfig): NavRoute {
    return {
      label: this._translationService.translate(routeConfig.labelPath),
      routerLink: routeConfig.routerLink,
      queryParamsHandling: routeConfig?.queryParamsHandling,
    };
  }

  private _updateNavState() {
    if (this.patient) {
      this.title = `${this.welcome}: ${this.patient?.first_name} ${this.patient?.last_name}`;
    } else {
      this.visible = false;
    }
  }

  ngOnInit(): void {
    this.loadNavRoutes();
    this.patient = this._store.retrieve('patient');

    this.title = `${this.welcome}: ${this.patient?.first_name} ${this.patient?.last_name}`;
    this._store.onChange('patient', (patient: Patient) => {
      this.patient = patient;
      this._updateNavState();
    });

    this._subscriptions = [
      this._headerService.visible.subscribe((visible: boolean) => {
        this.visible = visible;
      }),
      this._translationService.langObservable.subscribe((_) => {
        this.loadNavRoutes();
        this._updateNavState();
      }),
    ];
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  logout(): void {
    this._patientService.logout();
    this._store.store('t', undefined).store('patient', undefined);
    this._router.navigate(['landing']);
  }
}
