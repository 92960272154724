/* Copyright 2023 (Unpublished) Verto Inc. */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { FooterButtonGroup } from '../utility-components/chl-footer/chl-footer.component';

@Injectable({
  providedIn: 'root',
})
export class ChlFooterService {
  visible = new BehaviorSubject<boolean>(false);
  buttons = new BehaviorSubject<Omit<FooterButtonGroup, 'type'>[]>([]);

  chlBrandingMode = new BehaviorSubject<boolean>(false);
}
