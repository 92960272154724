/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { TranslationService } from '../services/translation.service';
import { MoodRendererUnitType, MoodRenderFrame } from 'engage-common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './study-links.component.html',
  styleUrls: ['./study-links.component.scss'],
})
export class StudyLinksComponent {
  @ViewChildren('studyLink') studyLinks: QueryList<ElementRef>;

  constructor(private _router: Router, public translationService: TranslationService) {}

  rendererFrame: MoodRenderFrame = this.translationService
    .translate(['pages', 'studyLinks'])
    .map(
      (
        link: { type: 'download' | 'intenral'; header: string; label: string; value: string },
        index: number
      ) => {
        return [
          {
            type: MoodRendererUnitType.HEADER,
            label: `${index + 1}. ${link.header}`,
          },
          {
            type: MoodRendererUnitType.BUTTON,
            buttonClasses: ['mood-primary', 'w-25'],
            text: link.label,
            margin: ['1em', '2.5em', '1em', '2.5em'],
            click: () => this.handleStudyLinkClick(link.type, link.value, index),
          },
        ];
      }
    )
    .flat();

  private handleStudyLinkClick(linkType: string, linkValue: string, index: number): void {
    const link: ElementRef = this.studyLinks.toArray()[index];
    switch (linkType) {
      case 'download':
        link.nativeElement.click();
        break;
      case 'internal':
        this._router.navigate([linkValue]);
        break;
    }
  }
}
