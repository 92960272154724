/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component } from '@angular/core';

@Component({
  selector: 'mood-screen',
  templateUrl: './mood-screen.component.html',
  styleUrls: ['./mood-screen.component.scss'],
})
export class MoodScreenComponent {}
