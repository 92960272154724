/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChlPatientService } from '../services/chl-patient.service';
import { ConfigurationService } from '../services/configuration.service';

@Component({
  selector: 'app-result',
  templateUrl: './result-document.component.html',
  styleUrls: ['./result-document.component.scss'],
})
export class ResultDocumentComponent implements OnInit {
  loadingDocument = false;
  reportExportName = 'test-result';
  message = '';
  documentBlob: Blob;

  constructor(
    private patientService: ChlPatientService,
    private _configurationService: ConfigurationService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.getConfig();
    this.getDocument();
  }

  getDocument() {
    this.loadingDocument = true;
    this._route.queryParams.subscribe((params) => {
      this.patientService.getDocument(params.id).subscribe(
        (documentBlob) => {
          this.loadingDocument = false;
          this.documentBlob = documentBlob;
          this.message = 'Certificate successfully loaded.';
        },
        (err) => {
          this.loadingDocument = false;
          if (err.status === 404) {
            this.message = 'Unable to find the COVID-19 Testing Certificate.';
          } else {
            this.message = 'An error occurred while retrieving the COVID-19 Testing Certificate.';
          }
        }
      );
    });
  }

  getConfig() {
    this._configurationService.getConfig().subscribe((res) => {
      this.reportExportName = res.config.content.test_kit_report_name;
    });
  }
}
