/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component } from '@angular/core';
import { TranslationService } from '../services/translation.service';

@Component({
  selector: 'app-link-sent-view',
  template: `<app-link-sent
    [header]="translationService.translate(['pages', 'linkSent', 'header'])"
    [confirmation]="translationService.translate(['pages', 'linkSent', 'confirmation'])"
    [issue]="translationService.translate(['pages', 'linkSent', 'issue'])"
  >
  </app-link-sent>`,
})
export class LinkSentViewComponent {
  constructor(public translationService: TranslationService) {}
}
