<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="user-preferences-container">
  <div *ngIf="!loading; else spinner">
    <mood-renderer
      [moodFrame]="rendererFrame"
      [wrapperClasses]="['row']"
      (formUpdated)="onUpdate($event)"
    ></mood-renderer>
    <div *ngIf="originalEmail && (!signInType || signInType == 'email')" class="row mah-flex-row-container">
      <div>
        {{ emailProps.altLabel }}
        <div class="current-user-pref">{{ originalEmail }}</div>
      </div>
    </div>
    <div class="row mah-container" *ngIf="!signInType || signInType == 'email'">
      <div class="mah-row">
        {{ emailProps.label }}
        <input type="text" [formControl]="email" [placeholder]="emailProps.placeholder" type="email" />
      </div>
      <div class="mah-row">
        <button
          class="btn mah-primary"
          (click)="sendValidationCode('email')"
          [disabled]="email.invalid || email.value === '' || sendingEmailCode"
        >
          {{ buttonProps }}
        </button>
      </div>
    </div>
    <div *ngIf="originalCellPhoneNumber && (!signInType || signInType == 'sms')" class="row mah-flex-row-container">
      <div>
        {{ cellPhoneNumberProps.altLabel }}
        <div class="current-user-pref">{{ originalCellPhoneNumber }}</div>
      </div>
    </div>
    <div class="row mah-flex-row-container" *ngIf="!signInType || signInType == 'sms'">
      <div class="mah-row-dropdown">
        Country Code:
        <generic-dropdown
          [options]="countries"
          placeholder="Select Country"
          required="true"
          [(value)]="country_code_selected"
        >
        </generic-dropdown>
      </div>
      <div class="mah-row-input">
        {{ cellPhoneNumberProps.label }}
        <input type="text" [formControl]="cellPhoneNumber" [placeholder]="cellPhoneNumberProps.placeholder" />
      </div>
      <div class="mah-row">
        <button
          class="btn mah-primary"
          (click)="sendValidationCode('cell_phone_number')"
          [disabled]="cellPhoneNumber.invalid || cellPhoneNumber.value === '' || sendingPhoneCode"
        >
          {{ buttonProps }}
        </button>
      </div>
    </div>
  </div>
  <ng-template #spinner>
    <mood-spinner></mood-spinner>
  </ng-template>
</div>
