/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component } from '@angular/core';

@Component({
  selector: 'mood-screen-content',
  templateUrl: './mood-screen-content.component.html',
  styleUrls: ['./mood-screen-content.component.scss'],
})
export class MoodScreenContentComponent {}
