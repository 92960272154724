/* Copyright 2023 (Unpublished) Verto Inc. */

// Angular
import { Component, Input } from '@angular/core';

// Models & Enums
import { MoodTableSettingsModel } from './mood-table-settings.models';
import { MoodTableCellType } from './mood-table-cell-type.enum';

@Component({
  selector: 'mood-table',
  templateUrl: './mood-table.component.html',
  styleUrls: ['./mood-table.component.scss'],
})
export class MoodTableComponent {
  @Input() tableSettings: MoodTableSettingsModel;
  @Input() data: any[] = [];
  cellTypes = MoodTableCellType;
}
