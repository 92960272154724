/* Copyright 2023 (Unpublished) Verto Inc. */

import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  MoodRegexValidator,
  MoodRendererComponent,
  MoodRendererUnit,
  MoodRendererUnitType,
  MoodRenderFrame,
} from 'engage-common';
import { ChlFooterService } from '../services/chl-footer.service';
import { Router } from '@angular/router';
import { EngageSerializableStoreService } from 'engage-utils';
import {
  ChlPatientService,
  DEMOGRAPHICS_KEY,
  DEPENDENT_KEY,
} from '../services/chl-patient.service';
import { TranslationService } from '../services/translation.service';
import { MessageService } from 'primeng/api';
import { MoodCaptchaComponent } from '../../../../engage-common/src/lib/mood/mood-captcha/mood-captcha.component';
import { ConfigurationService } from '../services/configuration.service';
import { FieldConfig } from '../services/configuration/field-config';
import { MoodFactoryService } from '../services/mood-factory.service';
import { environment } from '../../environments/environment';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-demographics',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit, OnDestroy, AfterViewInit {
  initialButtons = [
    {
      text: () => this._translationService.translate(['buttons', 'back']),
      click: () => this._return(),
      buttonClasses: ['mood-default'],
    },
    {
      text: () => this._translationService.translate(['buttons', 'continue']),
      click: () => this._submit(),
      disabled: () => !this.validForm,
      buttonClasses: ['mood-primary'],
    },
  ];

  prevUrl: string;

  demoConfig: FieldConfig[] = [];
  dependConfig: FieldConfig[] = [];
  quarantineConfig: FieldConfig[] = [];
  arrivalConfig: FieldConfig[] = [];
  dependentsEnabled: boolean;
  defaultFields: { province?: string } = {};

  loading = true;
  primaryContactHeader = '';
  arrivalInfoHeader = '';
  quarantineLocationHeader = '';
  formFields = {};
  validForm = false;

  constructor(
    public _translationService: TranslationService,
    private _footerService: ChlFooterService,
    private _router: Router,
    private _store: EngageSerializableStoreService,
    private _patientService: ChlPatientService,
    private _primeNgMessageService: MessageService,
    private _configurationService: ConfigurationService,
    private _moodFactoryService: MoodFactoryService
  ) {}

  @ViewChildren(MoodRendererComponent) renderers: QueryList<MoodRendererComponent>;

  @ViewChild(MoodCaptchaComponent) captcha: MoodCaptchaComponent;

  ngOnInit(): void {
    this.loading = true;
    this.formFields = {};
    this._translationService.langObservable.subscribe(() => {
      this.primaryContactHeader = this._translationService.translate([
        'customization',
        environment.client,
        'text',
        'primaryContactHeader',
      ]);
      this.quarantineLocationHeader = this._translationService.translate([
        'customization',
        environment.client,
        'text',
        'quarantineHeader',
      ]);
      this.arrivalInfoHeader = this._translationService.translate([
        'customization',
        environment.client,
        'text',
        'arrivalInfoHeader',
      ]);
    });
    this._footerService.visible.next(true);
    this._showButtons();
    this._configurationService.getConfig().subscribe((res) => {
      const signup_preamble = res.config.content.signup_preamble;
      const index = signup_preamble.indexOf('sign-up');
      if (index > 0) {
        this.prevUrl = signup_preamble[index - 1];
      } else {
        this.prevUrl = 'landing';
      }
      this.demoConfig = res.config.content.demographics_fields;
      this.defaultFields = res.config.content.default_fields;
      this.dependConfig = res.config.content.dependent_fields || [];
      this.arrivalConfig = res.config.content.arrival_fields || [];
      this.quarantineConfig = res.config.content.quarantine_location_fields || [];
      this.dependentsEnabled = !res.config.content.dependents_disabled;
      this.configureView();
      this.rendererFrameDemo = this.frames;
      this.rendererFrameArrival = this.framesArrival;
      this.rendererFrameQuarantine = this.framesQuarantine;
      this._footerService.visible.next(true);
      this.loading = false;
    });

    let captchaIframe;
    this._translationService.langObservable.subscribe((lang) => {
      captchaIframe =
        captchaIframe || document.querySelector('mood-captcha re-captcha div div iframe');
      if (captchaIframe) {
        captchaIframe.src = captchaIframe.src.replace(/hl=(.*?)&/, 'hl=' + lang + '&');
      }
    });
  }

  ngOnDestroy(): void {
    this._footerService.visible.next(false);
  }

  rendererForms: MoodRendererComponent[];

  ngAfterViewInit(): void {
    const demographicFields = this._store.retrieve(DEMOGRAPHICS_KEY);
    setTimeout(() => {
      this.rendererForms = this.renderers.toArray();
      this.fillForm(demographicFields);
    });
    this.renderers.changes.subscribe((c) => {
      this.rendererForms = this.renderers.toArray();
      setTimeout(() => {
        this.fillForm(demographicFields);
      });
    });
  }

  fillForm(demographicFields): void {
    if (!demographicFields) return;
    const currDatePipe = new DatePipe('en-US');
    const currArrivalDate = currDatePipe.transform(new Date(), 'yyyyMMdd');

    // preset arrival date if new form
    const ARRIVAL_FORM_IDX = this.rendererForms.length - 1;
    if (
      !this._store.retrieve(DEMOGRAPHICS_KEY) ||
      !this._store.retrieve(DEMOGRAPHICS_KEY).arrivalDate
    ) {
      this.rendererForms[ARRIVAL_FORM_IDX].form.patchValue({
        arrivalDate: currArrivalDate,
      });
    } else {
      this.rendererForms[ARRIVAL_FORM_IDX].form.patchValue(demographicFields);
    }

    // Fill in primary contact fields if they exist
    const PRIMARY_CONTACT_FORM_IDX = 1;
    if (this._store.retrieve(DEMOGRAPHICS_KEY) && this.rendererForms[PRIMARY_CONTACT_FORM_IDX]) {
      this.rendererForms[PRIMARY_CONTACT_FORM_IDX].form.patchValue(demographicFields);
    }

    // Fill in quarantine location form fields if they exist
    const QUARANTINE_LOCATION_FORM_IDX = this.rendererForms.length - 2;
    if (
      this.quarantineConfig.length > 0 &&
      this._store.retrieve(DEMOGRAPHICS_KEY) &&
      this.rendererForms[QUARANTINE_LOCATION_FORM_IDX]
    ) {
      this.rendererForms[QUARANTINE_LOCATION_FORM_IDX].form.patchValue(demographicFields);
    }

    // Quarantine province always set to Ontario
    if (this.quarantineConfig.length > 0 && this.defaultFields.province) {
      this.rendererForms[QUARANTINE_LOCATION_FORM_IDX].form.patchValue({
        quarantineProvince: this.defaultFields.province,
      });
    }
  }

  private _showButtons(): void {
    this._footerService.buttons.next([
      {
        buttons: this.initialButtons,
      },
    ]);
  }

  private _submit(): void {
    if (this.validateHealthInsurance()) {
      this._router.navigate(['sign-up-contact']);
    }
  }

  private _return(): void {
    this._store.clear();
    this._router.navigate([this.prevUrl]);
  }

  onUpdate(updateValues, formId = undefined): void {
    if (formId !== undefined) {
      this._store.store(`${DEPENDENT_KEY}_${formId}`, updateValues);
    } else {
      this.formFields = { ...this.formFields, ...updateValues };
      this._store.store(DEMOGRAPHICS_KEY, this.formFields);
    }
    // enable submit if all forms are valid
    this.validForm = this.rendererForms.every((renderer) => renderer.form.valid);
  }

  configureView(): void {
    this.demoConfig.forEach((field) => {
      this.frames.push(
        this._moodFactoryService.buildMoodUnit(
          field,
          undefined,
          this.configureValidatorFunctions(field)
        )
      );
    });

    this.quarantineConfig.forEach((field) => {
      this.framesQuarantine.push(
        this._moodFactoryService.buildMoodUnit(
          field,
          undefined,
          this.configureValidatorFunctions(field)
        )
      );
    });

    this.arrivalConfig.forEach((field) => {
      this.framesArrival.push(
        this._moodFactoryService.buildMoodUnit(
          field,
          undefined,
          this.configureValidatorFunctions(field)
        )
      );
    });
  }

  dateValidator(control) {
    if (control.value === '') {
      return null;
    }

    if (control.value.length !== 8) {
      return { errors: 'Invalid date format.' };
    }

    const year = parseInt(control.value.substring(0, 4));
    // Date object takes month from 0 - 11
    const month = parseInt(control.value.substring(4, 6)) - 1;
    const day = parseInt(control.value.substring(6));

    if (1800 < year && 0 <= month && month < 12 && 0 < day && day <= 31) {
      const dob = new Date(year, month, day);
      const datePipe = new DatePipe('en-US').transform(dob, 'yyyyMMdd');
      // Comparing the datepipe to the input value checks if its a 'real' date
      if (dob instanceof Date && control.value === datePipe) {
        return null;
      } else {
        return { errors: 'Invalid date format.' };
      }
    } else {
      return { errors: 'Invalid date format.' };
    }
  }

  phoneValidator(control) {
    // As we accept international phone numbers, which may be in a different format, only check that
    // the user entered digits
    const phoneRegex = /^\d+$/;
    const value = control.value;

    if (!value || !value.match(phoneRegex)) {
      return { errors: 'Invalid phone number' };
    }

    return null;
  }

  emailValidator(control) {
    const emailRegex = MoodRegexValidator.email();
    const value = control.value;

    if (!value || !value.match(emailRegex)) {
      return { errors: 'Invalid email' };
    }

    return null;
  }

  ontarioPostalValidator(control) {
    const ontarioPostal = MoodRegexValidator.ontarioPostal();
    const value = control.value;

    if (!value || !value.match(ontarioPostal)) {
      return { errors: 'Invalid postal code. Must be located in Ontario' };
    }

    return null;
  }

  healthcardValidator(control) {
    const healthcard = MoodRegexValidator.healthcard();
    const value = control.value;

    if (value && !value.match(healthcard)) {
      return { errors: 'Invalid healthcard' };
    }

    return null;
  }

  private configureValidatorFunctions(field: FieldConfig) {
    const validators = [];
    if (field.date) {
      validators.push(this.dateValidator);
    }
    if (field.phone) {
      validators.push(this.phoneValidator);
    }
    if (field.email) {
      validators.push(this.emailValidator);
    }
    if (field.ontarioPostal) {
      validators.push(this.ontarioPostalValidator);
    }
    if (field.healthcard) {
      validators.push(this.healthcardValidator);
    }
    return validators;
  }

  additionalFrames: MoodRenderFrame[] = [];
  dependFramesHideFields: boolean[] = [];
  addPatient(): void {
    let additionalRender = [];
    this.dependFramesHideFields.push(true);
    this.dependConfig.forEach((field) => {
      additionalRender.push(
        this._moodFactoryService.buildMoodUnit(
          field,
          () => this.dependFramesHideFields[this.dependFramesHideFields.length - 1],
          field.date ? [this.dateValidator] : []
        )
      );
    });

    this.additionalFrames.push(additionalRender);
  }

  validateHealthInsurance(): boolean {
    const PRIMARY_CONTACT_FORM_IDX = 1;
    const NUM_FORMS = 4;
    const NUM_DEPENDENT_FORMS = this.rendererForms.length - NUM_FORMS;

    const contactForms = this.rendererForms.slice(
      PRIMARY_CONTACT_FORM_IDX,
      PRIMARY_CONTACT_FORM_IDX + NUM_DEPENDENT_FORMS + 1
    );

    for (const renderer of contactForms) {
      const validHealthCard =
        renderer.form.controls.province.value !== '' &&
        renderer.form.controls.healthcard.value !== '' &&
        renderer.form.controls.healthcard.valid &&
        this.isValidHealthcard(
          renderer.form.controls.healthcard.value,
          renderer.form.controls.province.value
        );
      const cannotProvideHealthcard =
        renderer.form.controls.cannotProvideHealthcard.value === 'true';

      const valid = validHealthCard || cannotProvideHealthcard;

      const msg = this._translationService.translate([
        'customization',
        environment.client,
        'text',
        'invalidHealthcard',
      ]);

      if (!valid) {
        this._primeNgMessageService.add({
          severity: 'error',
          summary: '',
          detail: msg,
        });
        return false;
      }
    }
    return true;
  }

  private isValidHealthcard(healthcard: string, province: string): boolean {
    // Extension point, since we may want to validate other healthcards in the future
    switch (province) {
      case 'ON': {
        return this.isValidOntarioHC(healthcard);
      }
      default: {
        return true;
      }
    }
  }

  private isValidOntarioHC(healthcard: string): boolean {
    // Assumption: the version code, if present, is provided at the end of the string
    // (eg: 123456789AB)
    const healthCardRegex = /^(\d{10})[A-z]{0,2}$/g;
    const groups = healthcard.match(healthCardRegex);
    if (!groups) {
      return false;
    }

    // Steps: Double 1st, 3rd, 5th, 7th and 9th positions, sum up the digits of the resulting
    // healthcard and subtract the unit position from 10. This should match the last digit of the healthcard.
    // http://health.gov.on.ca/english/providers/pub/ohip/tech_specific/pdf/5_13.pdf
    const hc = groups[0].trim();
    const checkDigit = parseInt(hc.slice(hc.length - 1), 10);

    let sumOfDigits = hc
      .split('')
      .map(Number)
      .map((value, i) => {
        if (i % 2 === 0) {
          // This is fine, since value is at most 9, so double is at most 18
          return (value * 2)
            .toString()
            .split('')
            .map(Number)
            .reduce((a, b) => a + b, 0);
        } else {
          return value;
        }
      })
      .reduce((acc, val) => acc + val);
    sumOfDigits -= checkDigit;

    return 10 - (sumOfDigits % 10) === checkDigit;
  }

  frames: MoodRendererUnit[] = [];
  rendererFrameDemo: MoodRenderFrame = [];
  framesQuarantine: MoodRendererUnit[] = [];
  rendererFrameQuarantine: MoodRenderFrame = [];
  framesArrival: MoodRendererUnit[] = [];
  rendererFrameArrival: MoodRenderFrame = [];
  rendererHeader: MoodRenderFrame = [
    {
      type: MoodRendererUnitType.HEADER,
      label: () => this._translationService.translate(['pages', 'sign-up', 'demographicHeader']),
      margin: ['0', '0', '12px', '0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () => this._translationService.translate(['pages', 'sign-up', 'demographicParagraph']),
      margin: ['1rem', '0', '0', '0'],
    },
  ];
}
