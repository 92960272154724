/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  MoodMask,
  MoodRendererComponent,
  MoodRendererUnitType,
  MoodRenderFrame,
  MoodRegexValidator,
} from 'engage-common';
import { ChlFooterService } from '../services/chl-footer.service';
import { EngageSerializableStoreService } from 'engage-utils';
import { Router } from '@angular/router';
import { ChlPatientService } from '../services/chl-patient.service';
import { TranslationService } from '../services/translation.service';
import { MessageService } from 'primeng/api';
import { environment } from '../../environments/environment';
import { FieldConfig } from '../services/configuration/field-config';
import { ConfigurationService } from '../services/configuration.service';
import { FieldTypeEnum } from '../services/configuration/field-type.enum';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
  signUpType: 'sms' | 'email';
  allowedSignInType?: 'sms' | 'email';
  hideBackButton = false;
  STORE_KEY = 'sign-in';
  linkSentRoute = 'link-sent';

  recaptcha_key: string;
  alreadyClicked = false;

  countryCodes: FieldConfig = {
    controlType: FieldTypeEnum.dropdown,
    placeholder: '',
    required: false,
    options: [],
    key: 'country_code',
  };

  @ViewChild(MoodRendererComponent) renderer: MoodRendererComponent;

  constructor(
    public translationService: TranslationService,
    private _footerService: ChlFooterService,
    private _store: EngageSerializableStoreService,
    private _router: Router,
    private _patientService: ChlPatientService,
    private _primeNgMessageService: MessageService,
    private _configurationService: ConfigurationService
  ) {}

  ngOnInit() {
    this._configurationService.getConfig().subscribe((res) => {
      this.countryCodes.required = res.config.content.country_codes.required;
      this.allowedSignInType = res.config.content.allowed_sign_in_type;
      this.linkSentRoute = res.config.content.link_sent_route ?? 'link-sent';
      if (this.allowedSignInType) {
        this.signUpType = this.allowedSignInType;
      }

      if (res.config.content.hasOwnProperty('hide_back_button_on_sign_in')) {
        this.hideBackButton = res.config.content.hide_back_button_on_sign_in;
      }

      res.config.content.country_codes.options.forEach((country) => {
        this.countryCodes.options.push(country);
      });

      if (!this.hideBackButton) {
        this._footerService.visible.next(true);
        this._footerService.chlBrandingMode.next(false);
        this._footerService.buttons.next([
          {
            buttons: [
              {
                text: () => this.translationService.translate(['buttons', 'back']),
                click: () => this._router.navigate(['landing']),
                buttonClasses: ['mood-default'],
              },
            ],
          },
        ]);
      }
    });
  }

  ngOnDestroy(): void {
    this._footerService.visible.next(false);
  }

  onUpdate(updatedValues) {
    if (this.renderer.form && this.renderer.form.controls['signInWith']) {
      this.signUpType = this.renderer.form.controls['signInWith'].value || this.allowedSignInType;
    }

    if (updatedValues.country_code === '') {
      this.renderer.form.patchValue({ country_code: this.countryCodes.options[0].value });
    }

    this._store.store(this.STORE_KEY, updatedValues);
  }

  private _requestToken(): void {
    this.alreadyClicked = true;
    const { signInWith, cellPhone, email, country_code } = this._store.retrieve(this.STORE_KEY);

    let contactInfo;
    if (signInWith === 'sms' || this.allowedSignInType == 'sms') {
      contactInfo = { cell_phone_number: cellPhone, country_code: country_code }; // country code + cell phone input
    } else if (signInWith === 'email' || this.allowedSignInType == 'email') {
      contactInfo = { email };
    }

    if (this.recaptcha_key) {
      contactInfo.recaptcha_key = this.recaptcha_key;
    }

    this._patientService.requestToken(contactInfo).subscribe(
      ({ success }) => {
        this.alreadyClicked = false;
        if (success) {
          this._router.navigate([this.linkSentRoute]);
        }
      },
      (error) => {
        SignInComponent._resetCaptcha();
        this.alreadyClicked = false;
        let errMsg: string;
        if (error.status === 500) {
          errMsg = 'engageTokenError';
        } else if (error.error.error_code === 'not-verified') {
          errMsg = 'patientNotVerifiedError';
        } else if (error.error.error_code === 'not-found') {
          errMsg = 'patientNotFoundError';
        } else if (error.error.error_code === 'deleted') {
          errMsg = 'patientDeletedError';
        } else if (error.error.error_code === 'invalid-phone-number') {
          errMsg = 'phoneError';
        } else if (error.error.error_code === 'fields-not-entered') {
          errMsg = 'fieldsNotEntered';
        } else {
          errMsg = 'otherError';
        }
        this._primeNgMessageService.add({
          severity: 'error',
          summary: '',
          detail: this.translationService.translate(['pages', 'sign-in', 'errors', errMsg]),
        });
      }
    );
  }

  private _captchaResolved(captcha: string): void {
    this.recaptcha_key = captcha;
  }

  private static _resetCaptcha(): void {
    const captchaIframe = document.querySelector('mood-captcha re-captcha div div iframe') as any;
    if (captchaIframe) {
      captchaIframe.src = captchaIframe.src;
    }
  }

  rendererFrame: MoodRenderFrame = [
    {
      type: MoodRendererUnitType.HEADER,
      label: () => this.translationService.translate(['pages', 'sign-in', 'header']),
      margin: ['0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () => this.translationService.translate(['pages', 'sign-in', 'confirmation']),
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () => this.translationService.translate(['pages', 'sign-in', 'signInMethod']),
    },
    {
      type: MoodRendererUnitType.RADIO_GROUP,
      margin: ['0'],
      hidden: () => this.allowedSignInType == 'sms' || this.allowedSignInType == 'email',
      formData: {
        controlName: 'signInWith',
        controlType: 'checkbox',
        options: [
          {
            label: () =>
              this.translationService.translate(['pages', 'sign-in', 'fields'])[0].radioLabel,
            value: 'sms',
          },
          {
            label: () =>
              this.translationService.translate(['pages', 'sign-in', 'fields'])[1].radioLabel,
            value: 'email',
          },
        ],
        validators: {},
      },
    },
    {
      type: MoodRendererUnitType.INPUT,
      label: () => this.translationService.translate(['pages', 'sign-in', 'fields'])[1].label,
      hidden: () => this.signUpType !== 'email',
      formData: {
        placeholder: () =>
          this.translationService.translate(['pages', 'sign-in', 'fields'])[1].placeholder,
        controlName: 'email',
        controlType: 'email',
        valueType: 'text',
        validators: {
          required: true,
        },
      },
    },
    {
      type: MoodRendererUnitType.INPUT,
      label: () => this.translationService.translate(['pages', 'sign-in', 'fields'])[0].label,
      hidden: () => this.signUpType !== 'sms',
      formData: {
        placeholder: () =>
          this.translationService.translate(['pages', 'sign-in', 'fields'])[0].placeholder,
        controlName: 'cellPhone',
        controlType: 'tel',
        valueType: 'text',
        validators: {
          required: true,
          pattern: MoodRegexValidator.phoneNumber(),
        },
      },
    },
    {
      type: MoodRendererUnitType.DROPDOWN,
      hidden: () => this.signUpType !== 'sms',
      formData: {
        placeholder: this.translationService.translate([
          'pages',
          'sign-up',
          'fields',
          this.countryCodes.key,
        ]).placeholder,
        controlName: this.countryCodes.key,
        controlType: this.countryCodes.controlType,
        options: this.countryCodes.options,
        validators: {
          required: this.countryCodes.required,
        },
      },
    },
    {
      type: MoodRendererUnitType.CAPTCHA,
      siteKey: environment.recaptchaSiteKey,
      hidden: () => !(this.signUpType === 'sms' || this.signUpType === 'email'),
      margin: ['0', '0', '1rem', '0'],
      onResolved: ($event: string) => this._captchaResolved($event),
      gridClass: 'col-12 custom-captcha',
    },
    {
      type: MoodRendererUnitType.BUTTON,
      disabled: () => this.alreadyClicked,
      hidden: () => !(this.signUpType === 'sms' || this.signUpType === 'email'),
      buttonClasses: ['mood-primary', 'w-100'],
      text: () => this.translationService.translate(['pages', 'sign-in', 'confirmButton']),
      click: () => this._requestToken(),
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () => this.translationService.translate(['pages', 'sign-in', 'notice']),
      margin: ['1.5rem', '0', '0', '0'],
    },
  ];
}
