<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<h3 class="header">COVID-19 Testing Certificate</h3>

<div class="results-container">
  <div *ngIf="!kit">
    <mood-spinner></mood-spinner>
  </div>
  <div *ngIf="kit">
    <div class="grid patient">
      <div *ngFor="let field of patientInfo" [ngClass]="{ 'full-width': field?.fullWidth }">
        <div class="label">{{ field.label }}</div>
        <div class="value">{{ field.value }}</div>
      </div>
    </div>
    <div class="grid resource">
      <div
        *ngFor="let field of resourceInfo"
        [ngClass]="{ 'full-width': field?.fullWidth }"
        [ngClass]="{ 'full-width': field?.fullWidth }"
      >
        <div class="label">{{ field.label }}</div>
        <div class="value">{{ field.value }}</div>
      </div>
    </div>

    <div>
      <div class="label">The result of your test was:</div>
      <div class="status">
        <img *ngIf="kit?.status_icon" src="{{ kit?.status_icon }}" />
        <span>{{ kit?.status | titlecase }}</span>
      </div>
    </div>
  </div>
</div>
