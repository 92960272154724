<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="card-container">
  <div class="card-header" (click)="toggleExpand()">
    <div class="card-icon" [ngClass]="{ expanded: expanded }">
      <img engage-asset src="/assets/icons/arrow-down.svg" />
    </div>
    <div class="card-text">
      <span class="title">{{ headerTitle }}</span>
      <span class="subtitle">{{ headerSubtitle }}</span>
    </div>
  </div>
  <div [@openClose]="expanded ? 'opened' : 'closed'" class="card-content" [ngClass]="{ expanded: expanded }">
    <ng-content></ng-content>
  </div>
</div>
