/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'mood-screen-footer',
  templateUrl: './mood-screen-footer.component.html',
  styleUrls: ['./mood-screen-footer.component.scss'],
})
export class MoodScreenFooterComponent {
  @Input() layoutDirection: 'row' | 'col' = 'row';
}
