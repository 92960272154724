/* Copyright 2023 (Unpublished) Verto Inc. */

// @ts-nocheck

import { Component } from '@angular/core';
import { SignUpComponent } from '../../sign-up/sign-up.component';

@Component({
  selector: 'app-ad-hoc-sign-up',
  templateUrl: '../../sign-up/sign-up.component.html',
  styleUrls: ['../../sign-up/sign-up.component.scss'],
})
export class AdHocSignUpComponent extends SignUpComponent {
  set prevUrl(_: string) {
    /* noop */
  }

  get prevUrl(): string {
    return 'create-an-account';
  }
}
