/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, OnInit } from '@angular/core';
import { MoodRendererUnitType, MoodRenderFrame } from 'engage-common';
import { TranslationService } from '../../../services/translation.service';
import { Router } from '@angular/router';
import { EngageSerializableStoreService } from 'engage-utils';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-gtaa-landing',
  templateUrl: './gtaa-landing.component.html',
  styleUrls: ['./gtaa-landing.component.scss'],
})
export class GtaaLandingComponent implements OnInit {
  ELIGIBILITY_KEY = 'eligibility';
  ANSWER_MAP = {
    permittedEntry: true,
    remainingInOntario: true,
    signsOfCovid: false,
    contactConfirmed: false,
    exemptFromQuarantine: false,
    quarantinePlan: true,
    pharmacyDropOff: true,
  };

  notEligible = false;

  constructor(
    public translationService: TranslationService,
    private _router: Router,
    private _store: EngageSerializableStoreService,
    private _primeNgMessageService: MessageService
  ) {}

  private static stringToBool(value: string): boolean {
    // This is a very brittle method, but it is internal
    return value === '1';
  }

  ngOnInit(): void {
    this._store.store(this.ELIGIBILITY_KEY, {});
  }

  onClick() {
    const responses = this._store.retrieve(this.ELIGIBILITY_KEY);
    const responseKeys = Object.keys(responses);

    // Check that responses is not empty (no questions answered), and no value was left blank
    if (
      (responseKeys.length === 0 && responses.constructor === Object) ||
      responseKeys.some((key) => responses[key] === '')
    ) {
      const msg = this.translationService.translate([
        'customization',
        'gtaa-chl',
        'pages',
        'landing',
        'answerAllQuestions',
      ]);
      this._primeNgMessageService.add({
        severity: 'error',
        summary: '',
        detail: msg,
      });
      return;
    }

    const eligible = responseKeys.every(
      (key, index) => GtaaLandingComponent.stringToBool(responses[key]) === this.ANSWER_MAP[key]
    );

    if (eligible) {
      this._router.navigate(['sign-up']);
    } else {
      this.notEligible = true;
    }
  }

  onUpdate(updateValues) {
    this._store.store(this.ELIGIBILITY_KEY, updateValues);
  }

  rendererFrame: MoodRenderFrame = [
    {
      type: MoodRendererUnitType.ACCORDION,
      hidden: () => this.notEligible,
      expanded: false,
      header: this.translationService.translate([
        'customization',
        'gtaa-chl',
        'pages',
        'landing',
        'overviewHeader',
      ]),
      content: [
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: () =>
            this.translationService.translate([
              'customization',
              'gtaa-chl',
              'pages',
              'landing',
              'overviewParagraph',
            ]),
          padding: ['1rem', '0.5rem'],
        },
      ],
      margin: ['1rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.ACCORDION,
      hidden: () => this.notEligible,
      expanded: false,
      header: this.translationService.translate([
        'customization',
        'gtaa-chl',
        'pages',
        'landing',
        'loginHeader',
      ]),
      content: [
        {
          type: MoodRendererUnitType.PARAGRAPH,
          html: () =>
            this.translationService.translate([
              'customization',
              'gtaa-chl',
              'pages',
              'landing',
              'loginParagraph',
            ]),
          padding: ['1rem', '0.5rem'],
        },
        {
          type: MoodRendererUnitType.BUTTON,
          buttonClasses: ['mood-primary', 'w-100'],
          padding: ['1rem', '0.5rem'],
          text: () => this.translationService.translate(['pages', 'landing', 'loginButton']),
          click: () => this._router.navigate(['sign-in']),
        },
      ],
      margin: ['1rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () =>
        this.translationService.translate([
          'customization',
          'gtaa-chl',
          'pages',
          'landing',
          'notEligible',
        ]),
      hidden: () => !this.notEligible,
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.HEADER,
      label: () =>
        this.translationService.translate([
          'customization',
          'gtaa-chl',
          'pages',
          'landing',
          'signUpHeader',
        ]),
      hidden: () => this.notEligible,
      margin: ['2em', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () =>
        this.translationService.translate([
          'customization',
          'gtaa-chl',
          'pages',
          'landing',
          'permittedEntry',
        ]),
      hidden: () => this.notEligible,
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.RADIO_GROUP,
      hidden: () => this.notEligible,
      formData: {
        controlName: 'permittedEntry',
        controlType: 'checkbox',
        options: [
          {
            label: this.translationService.translate(['options', 'yes']),
            value: '1',
          },
          {
            label: this.translationService.translate(['options', 'no']),
            value: '0',
          },
        ],
        validators: {
          required: true,
          minlength: 1,
        },
      },
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () =>
        this.translationService.translate([
          'customization',
          'gtaa-chl',
          'pages',
          'landing',
          'signsOfCovid',
        ]),
      hidden: () => this.notEligible,
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.RADIO_GROUP,
      hidden: () => this.notEligible,
      formData: {
        controlName: 'signsOfCovid',
        controlType: 'checkbox',
        options: [
          {
            label: this.translationService.translate(['options', 'yes']),
            value: '1',
          },
          {
            label: this.translationService.translate(['options', 'no']),
            value: '0',
          },
        ],
        validators: {
          required: true,
          minlength: 1,
        },
      },
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () =>
        this.translationService.translate([
          'customization',
          'gtaa-chl',
          'pages',
          'landing',
          'contactConfirmed',
        ]),
      hidden: () => this.notEligible,
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.RADIO_GROUP,
      hidden: () => this.notEligible,
      formData: {
        controlName: 'contactConfirmed',
        controlType: 'checkbox',
        options: [
          {
            label: this.translationService.translate(['options', 'yes']),
            value: '1',
          },
          {
            label: this.translationService.translate(['options', 'no']),
            value: '0',
          },
        ],
        validators: {
          required: true,
          minlength: 1,
        },
      },
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () =>
        this.translationService.translate([
          'customization',
          'gtaa-chl',
          'pages',
          'landing',
          'remainingInOntario',
        ]),
      hidden: () => this.notEligible,
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.RADIO_GROUP,
      hidden: () => this.notEligible,
      formData: {
        controlName: 'remainingInOntario',
        controlType: 'checkbox',
        options: [
          {
            label: this.translationService.translate(['options', 'yes']),
            value: '1',
          },
          {
            label: this.translationService.translate(['options', 'no']),
            value: '0',
          },
        ],
        validators: {
          required: true,
          minlength: 1,
        },
      },
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () =>
        this.translationService.translate([
          'customization',
          'gtaa-chl',
          'pages',
          'landing',
          'exemptFromQuarantine',
        ]),
      hidden: () => this.notEligible,
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.RADIO_GROUP,
      hidden: () => this.notEligible,
      formData: {
        controlName: 'exemptFromQuarantine',
        controlType: 'checkbox',
        options: [
          {
            label: this.translationService.translate(['options', 'yes']),
            value: '1',
          },
          {
            label: this.translationService.translate(['options', 'no']),
            value: '0',
          },
        ],
        validators: {
          required: true,
          minlength: 1,
        },
      },
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () =>
        this.translationService.translate([
          'customization',
          'gtaa-chl',
          'pages',
          'landing',
          'quarantinePlan',
        ]),
      hidden: () => this.notEligible,
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.RADIO_GROUP,
      hidden: () => this.notEligible,
      formData: {
        controlName: 'quarantinePlan',
        controlType: 'checkbox',
        options: [
          {
            label: this.translationService.translate(['options', 'yes']),
            value: '1',
          },
          {
            label: this.translationService.translate(['options', 'no']),
            value: '0',
          },
        ],
        validators: {
          required: true,
          minlength: 1,
        },
      },
    },
    {
      type: MoodRendererUnitType.PARAGRAPH,
      html: () =>
        this.translationService.translate([
          'customization',
          'gtaa-chl',
          'pages',
          'landing',
          'pharmacyDropOff',
        ]),
      hidden: () => this.notEligible,
      margin: ['1.5rem', '0', '0', '0'],
    },
    {
      type: MoodRendererUnitType.RADIO_GROUP,
      hidden: () => this.notEligible,
      formData: {
        controlName: 'pharmacyDropOff',
        controlType: 'checkbox',
        options: [
          {
            label: this.translationService.translate(['options', 'yes']),
            value: '1',
          },
          {
            label: this.translationService.translate(['options', 'no']),
            value: '0',
          },
        ],
        validators: {
          required: true,
          minlength: 1,
        },
      },
    },
    {
      type: MoodRendererUnitType.BUTTON,
      hidden: () => this.notEligible,
      buttonClasses: ['mood-primary', 'w-100'],
      text: () => this.translationService.translate(['buttons', 'continue']),
      click: () => this.onClick(),
    },
  ];
}
