/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'mood-spinner',
  templateUrl: './mood-spinner.component.html',
  styleUrls: ['./mood-spinner.component.scss'],
})
export class MoodSpinnerComponent {
  @Input() styles = {};
  @Input() light: boolean;
}
