/* Copyright 2023 (Unpublished) Verto Inc. */

import { AdHocCovidTestComponent } from '../brown-labs/ad-hoc-covid-test/ad-hoc-covid-test.component';
import { AdHocSignUpComponent } from '../brown-labs/ad-hoc-covid-test/ad-hoc-sign-up.component';
import { CreateAnAccountComponent } from '../brown-labs/create-an-account.component';
import { EnrollInResearchStudyComponent } from '../brown-labs/enroll-in-research-study.component';
import { LinkSentComponent } from '../brown-labs/link-sent.component';
import { RegisteredWithVertoComponent } from '../brown-labs/registered-with-verto.component';
import { RegistrationInfoComponent } from '../brown-labs/registration-info.component';
import { RegistrationIntroductionComponent } from '../brown-labs/registration-introduction.component';

export const brownLabsRoutes = [
  {
    path: 'register-with-verto',
    component: RegisteredWithVertoComponent,
  },
  {
    path: 'registration-info',
    component: RegistrationInfoComponent,
  },
  {
    path: 'enrollment',
    component: EnrollInResearchStudyComponent,
  },
  {
    path: 'ad-hoc',
    component: AdHocCovidTestComponent,
  },
  {
    path: 'ad-hoc-signup',
    component: AdHocSignUpComponent,
  },
  {
    path: 'registration-introduction',
    component: RegistrationIntroductionComponent,
  },
  {
    path: 'create-an-account',
    component: CreateAnAccountComponent,
  },
  {
    path: 'brown-labs-link-sent',
    component: LinkSentComponent,
  },
];
