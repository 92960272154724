<!-- Copyright 2023 (Unpublished) Verto Inc. -->

<div class="link-sent-container">
  <div class="row">
    <div class="col" [innerHtml]="header"></div>
  </div>

  <div class="row mt-4">
    <div class="col" [innerHtml]="confirmation"></div>
  </div>

  <div class="row">
    <div class="col" [innerHtml]="issue"></div>
  </div>
</div>
